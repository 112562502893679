import {MatPaginatorIntl} from '@angular/material/paginator';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {

  private rangeLabelIntl: string;

  constructor(public translate: TranslateService) {
    super();
    this.getTranslations();
  }

  getTranslations() {
    this.translate.stream('matPaginatorIntl')
      .subscribe(translation => {
        this.itemsPerPageLabel = translation.itemsPerPageLabel;
        this.nextPageLabel = translation.nextPageLabel;
        this.previousPageLabel = translation.previousPageLabel;
        this.lastPageLabel = translation.lastPageLabel;
        this.firstPageLabel = translation.firstPageLabel;
        this.rangeLabelIntl = translation.of;
        this.changes.next();
      });
  }

  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0' + this.rangeLabelIntl + length;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return startIndex + 1 + ' - ' + endIndex + this.rangeLabelIntl + length;
  }.bind(this);
}
