<mat-card style="text-align-last: center;" fxFlex class="animated-card" [class.mat-elevation-z12]="true">
  <!--  <div mat-card-avatar class="example-header-image"></div>-->
  <mat-card-header unselectable="on" class="no-text-selection">
    <mat-card-title><b>{{title}}</b></mat-card-title>
    <mat-card-subtitle>{{subTitle}}</mat-card-subtitle>
  </mat-card-header>
  <img id="step2" mat-card-image [src]="imgUrl">
  <!--  <mat-card-content >-->
  <!--    <p>-->
  <!--      {{content}}-->
  <!--    </p>-->
  <!--  </mat-card-content>-->
  <mat-card-actions>
    <button
      id="step1"
      mat-raised-button
      color="primary"
      (click)="onButtonClick()">
      {{apiText}}
    </button>
  </mat-card-actions>
</mat-card>
