import {Component, Inject, OnInit} from '@angular/core';
import {InvitationService, ErreursAcces} from '../../service/invitation.service';
import {Router, ActivatedRoute} from '@angular/router';
import {Invitation} from '../../models/invitation';
import {InvitationRoutes} from 'app/constants/InvitationRoutes';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-fin-test',
  templateUrl: './fin-test.component.html',
  styleUrls: ['./fin-test.component.css']
})
export class FinTestComponent implements OnInit {
  public invitation: Invitation;

  private mode: string;

  constructor(
    @Inject(InvitationService) public invitationService: InvitationService,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(Router) private router: Router,
    public translate: TranslateService) {
    this.mode = this.router.url.startsWith('/' + InvitationRoutes.simulation.fin) ? 'simulation' : 'candidat';
  }

  ngOnInit() {
    if (this.invitationService.isInvitationEnTest()) {
      this.invitationService.finTest().subscribe(invitation => {
        // this.router.navigate(['/' + InvitationRoutes[this.mode].home]);
      });
    } else {
      console.error('Impossible de conclure le questionnaire de ce candidat : il n\'est pas en cours de test');
      this.router.navigate(['/' + InvitationRoutes[this.mode].home], {queryParams: {erreur: ErreursAcces.CantResubmitTest}});
    }
  }
}
