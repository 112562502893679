import { MembreAptea } from '../../models/membreAptea';
import { MembreService } from '../../service';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { AuthService, UserService } from '../../service';
import { Router } from '@angular/router';
import { AuthenticationServiceSubscription } from '../../models/authenticationservice-subscription';
import { InvitationService } from '../../service/invitation.service';
import { BuildService } from '../../service/build.service';
import { environment } from '../../../environments/environment';
import { InvitationRoutes } from 'app/constants/InvitationRoutes';
import { ToastrService } from 'ngx-toastr';
import { AppTheme } from '../../shared/utilities/AppTheme';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../shared/utilities/Utils';
import { AppRoles } from '../../shared/utilities/AppRoles';
import { AppLanguage } from '../../shared/utilities/AppLanguage';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isRedacteur: boolean;
  isRh: boolean;
  isAdmin: boolean;
  isInvitation: boolean;
  isToutAptea: boolean;
  afficheBuildFront: string;
  afficheBuild: string;

  @Input() data: string;

  authMembre: MembreAptea;
  authenticatedMember: MembreAptea;
  whoIs: string;
  serviceSubscription: any;
  routLink: any;

  window = window;
  appTheme = AppTheme;
  utils = Utils;

  navBarItems: any;
  logoutBtnTitle: string;

  constructor(
    @Inject(MembreService) private membreService: MembreService,
    @Inject(InvitationService) private invitationService: InvitationService,
    @Inject(AuthService) private authService: AuthService,
    @Inject(Router) private router: Router,
    private buildService: BuildService,
    public userService: UserService,
    private toastr: ToastrService,
    public appLanguage: AppLanguage,
    public translate: TranslateService) {
    this.serviceSubscription = this.authService.onChange.subscribe({
      next: (event: AuthenticationServiceSubscription) => {
        this.onSubscription(event);
      }
    });
  }

  ngOnInit() {

    this.constructShowBuildApp();

    // les utilisateurs non candidats doivent s'identifier
    if (this.invitationService.isInvitationConnecte()) {
      this.authService.getCurrentMembreAptea().subscribe(authenticatedMember => {
        this.authenticatedMember = authenticatedMember;
      }, error => {
        this.toastr.error(this.translate.instant('toastr.error.connexionError'));
      });
    }

    this.authenticatedMember = this.userService.currentUser;

    this.translate.stream('component.header.navBarItems').subscribe(value => {

      this.navBarItems = [
        {
          routerLink: '/invitations',
          authorisedRoles: [AppRoles.ADMIN, AppRoles.RH],
          activeUrls: ['invitation', 'review-test'],
          title: value.candidatures
        },
        {
          routerLink: '/questionnaire',
          authorisedRoles: [AppRoles.ADMIN, AppRoles.RH],
          activeUrls: ['questionnaire'],
          title: value.questionnaire
        },
        {
          routerLink: '/questions',
          authorisedRoles: [AppRoles.ADMIN, AppRoles.RH, AppRoles.REDACTEUR],
          activeUrls: ['questions', 'new-question', 'updateTexte', 'updateChoixMultiple', 'updateCode', 'updateOuverte'],
          title: value.questions
        }
      ];

      this.logoutBtnTitle = value.logout;
    });
  }

  private constructShowBuildApp() {
    // Traitement affichage build Front + Back
    this.afficheBuild = '';
    this.afficheBuild += 'Client : v' + environment.buildVersion + '\n';
    this.afficheBuild += '(' + environment.buildTimestamp + ')';

    this.buildService.getVersion().toPromise().then((versionBackEnd) => {
      this.afficheBuild += '\n' + this.translate.instant('component.header.showBuild') + ' : v' + versionBackEnd;
    })
    .catch((ex) => {
      this.afficheBuild += '\n' + this.translate.instant('component.header.showBuild') + ' : getVersion() exception';
      console.log(ex);
    })
    .finally(() => {
      this.buildService.getDateBuild().toPromise().then((dateBuildBackEnd) => {
        this.afficheBuild += '\n' + '(' + dateBuildBackEnd.toString() + ')';
      })
      .catch((ex) => {
        this.afficheBuild += '\n' + this.translate.instant('component.header.showBuild') + ' : getDateBuild() exception';
        console.log(ex);
      });
    });
  }

  public getIconClass(): string {
    switch (AppTheme.globalTheme) {
      case AppTheme.dark:
        return 'darkIcon';
      case AppTheme.mix:
        return 'greyIcon';
      default:
        return 'lightIcon';
    }
  }

  public getProfileOfAuthenticatedMember(authenticatedMember: MembreAptea) {
    this.isAdmin = false;
    this.isInvitation = false;
    this.isRedacteur = false;
    this.isRh = false;
    this.isToutAptea = false;

    if (this.invitationService.isInvitationConnecte() && authenticatedMember === undefined) {
      this.isInvitation = true;
      return;
    }

    if (authenticatedMember !== undefined) {
      if (authenticatedMember.profile === AppRoles.REDACTEUR) {
        this.isRedacteur = true;
      }

      if (authenticatedMember.profile === AppRoles.RH) {
        this.isRh = true;
      }

      if (authenticatedMember.profile === AppRoles.ADMIN) {
        this.isAdmin = true;
      }

      if (authenticatedMember.profile === AppRoles.CANDIDAT) {
        this.isInvitation = true;
      }

      if (authenticatedMember.profile === AppRoles.TOUT_APTEA) {
        this.isToutAptea = true;
      }
    }
  }

  userHasAccessToMenuItem(authorisedRoles: string[]): boolean {
    for (const role of authorisedRoles) {
      if (this.authenticatedMember.profile === role) {
        return true;
      }
    }

    return false;
  }

  onSubscription(event: AuthenticationServiceSubscription) {
    this.authenticatedMember = event.authMember;
    this.whoIs = event.message;
  }

  logout() {
    this.authService.logout().subscribe(result => {
      this.router.navigate(['/']);
      // this.membreService.deleteMembreAptea().subscribe(authMembre => this.authMembre = authMembre);
      this.authenticatedMember = undefined;
      this.invitationService.invitation = null;
    },
      error => {
        this.toastr.error(this.translate.instant('toastr.error.logoutError'));
      });

  }

  hasSignedIn(): boolean {
    this.getProfileOfAuthenticatedMember(this.authenticatedMember);
    return ((this.authenticatedMember && this.authenticatedMember.profile !== null) || // mode connecté comme utilisateur
      this.invitationService.isInvitationConnecte()); // mode connecté comme candidat
  }

  userName() {
    if (this.invitationService.isInvitationConnecte()) {
      return this.invitationService.getUserName();
    }

    return this.authenticatedMember.username;
  }

  onRoutLink() {
    if (this.authenticatedMember && this.authenticatedMember.profile !== AppRoles.CANDIDAT) {
      this.router.navigate(['/home']);
    } else if (this.invitationService.isInvitationConnecte()) {
      this.router.navigate(['/' + InvitationRoutes.candidat.home]);
    } else {
      this.router.navigate(['/']);
    }
  }

  changeTheme(theme: string) {
    AppTheme.changeAppTheme(theme);
  }

  disableThemeChoice(theme: string): boolean {
    return this.appTheme.globalTheme === theme;
  }

  disableLanguageChoice(language: string): boolean {
    return this.appLanguage.globalAppLanguage === language;
  }

  getStringUpperCase(value: string): string {
    return value ? value.toUpperCase() : undefined;
  }

  changeAppLanguage(language: string) {
    this.appLanguage.changeAppLanguage(language);
    this.constructShowBuildApp();
  }
}
