import { MatChipInputEvent } from '@angular/material/chips';
import { QuestionLibre } from '../../models/question.libre';
import { QuestionnaireStats } from '../../models/QuestionnaireStats';
import { QuestionStats } from '../../models/QuestionStats';
import { AppRoles } from './AppRoles';
import { TranslateService } from '@ngx-translate/core';

export class Utils {
  public static readonly authorizedRoles: string[] = [
    AppRoles.ADMIN,
    AppRoles.RH,
    AppRoles.REDACTEUR,
    AppRoles.CANDIDAT,
  ];

  public static removeSelectRowStyle() {
    const elements = document.querySelectorAll('.data-table-selected-row');
    [].forEach.call(elements, function (el) {
      el.className = el.className.replace(
        /\bbg-success|bg-warning|text-white|data-table-selected-row\b/gi,
        ''
      );
    });
  }

  public static getSort(event: any): string {
    const sortProperties = event.sorts[0];
    if (sortProperties) {
      return sortProperties.dir + ' ' + sortProperties.prop;
    }

    return '';
  }

  public static addResponseForQuestion(
    event: MatChipInputEvent,
    responseLabels: Array<string>,
    questAEnvoyer: QuestionLibre
  ) {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      const response = value.trim();
      responseLabels.push(response);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    questAEnvoyer.resultat = Utils.updateQuestionResult(responseLabels);
  }

  public static removeResponseFromQuestion(
    response: string,
    responseLabels: Array<string>,
    questAEnvoyer: QuestionLibre
  ) {
    let index = -1;
    for (let i = 0; i < responseLabels.length; i += 1) {
      if (responseLabels[i] === response) {
        index = i;
      }
    }

    if (index >= 0) {
      responseLabels.splice(index, 1);
    }

    questAEnvoyer.resultat = this.updateQuestionResult(responseLabels);
  }

  private static updateQuestionResult(responseLabels: Array<string>): string {
    let result = '';

    for (let i = 0; i < responseLabels.length; i++) {
      result = result.concat(responseLabels[i]);
      if (i !== responseLabels.length - 1) {
        result = result.concat('\n');
      }
    }

    return result;
  }

  public static buildAdvancedPieChartData(
    questionnaireStats: QuestionnaireStats
  ) {
    return [
      {
        name: 'Tests en attente',
        value:
          questionnaireStats.totalTests -
          questionnaireStats.questionnaireCompletedStats.totalCompletedTests,
      },
      {
        name: 'Tests terminés',
        value:
          questionnaireStats.questionnaireCompletedStats.totalCompletedTests,
      },
    ];
  }

  public static buildBarVerticalChartData(
    questionnaireStats: QuestionnaireStats
  ) {
    const testNegatif = questionnaireStats.testsScoreNegatif;
    const testInf20 = questionnaireStats.testsScoreInf20;
    const testInf40 = questionnaireStats.testsScoreInf40;
    const testInf60 = questionnaireStats.testsScoreInf60;
    const testInf80 = questionnaireStats.testsScoreInf80;
    const testInf100 = questionnaireStats.testsScoreInf100;

    return [
      {
        name: 'negatif',
        value: testNegatif ? testNegatif : '',
      },
      {
        name: '0-19',
        value: testInf20 ? testInf20 : '',
      },
      {
        name: '20-39',
        value: testInf40 ? testInf40 : '',
      },
      {
        name: '40-59',
        value: testInf60 ? testInf60 : '',
      },
      {
        name: '60-79',
        value: testInf80 ? testInf80 : '',
      },
      {
        name: '80-100',
        value: testInf100 ? testInf100 : '',
      },
    ];
  }

  public static buildVerticalChartData(questionnaireStats: QuestionnaireStats) {
    const maxNote = questionnaireStats.questionnaireCompletedStats.maxNote;
    const noteAverage =
      questionnaireStats.questionnaireCompletedStats.noteAverage;
    const minNote = questionnaireStats.questionnaireCompletedStats.minNote;

    return [
      {
        name: 'Note Minimale',
        value: minNote ? minNote : '',
      },
      {
        name: 'Note Moyenne',
        value: noteAverage ? noteAverage : '',
      },
      {
        name: 'Note Maximale',
        value: maxNote ? maxNote : '',
      },
    ];
  }

  public static getTextFirstLine(text: string): string {
    if (text) {
      return text
        .split('\n')[0]
        .split('<br/>')[0]
        .split('<br>')[0]
        .split('</p>')[0];
    }
    return null;
  }

  public static camelCaseNameToPoint(value: string): string {
    if (value) {
      return value.replace(/([A-Z])/g, '.$1').toLowerCase();
    } else {
      return null;
    }
  }

  public static getQuestionCodeChosenLanguage(
    questionCodeTechnology: string,
    forClass: boolean,
    forAceEditor: boolean
  ): string {
    if (questionCodeTechnology === 'c' || questionCodeTechnology === 'c++') {
      if (forClass || forAceEditor) {
        return 'c_cpp';
      } else {
        switch (questionCodeTechnology) {
          case 'c':
            return 'c';
          case 'c++':
            return 'cpp';
        }
      }
    }

    switch (questionCodeTechnology) {
      case 'java':
        return 'java';
      case 'php':
        return 'php';
      case 'c#':
        return 'csharp';
      case 'python':
        return 'python';
      case 'MySQL':
        return 'mysql';
      case 'NodeJS':
        return 'javascript';
      default:
        return 'java';
    }
  }

  public static stringIncludesAtLeast(
    text: string,
    subTexts: string[]
  ): boolean {
    if (!text) {
      return false;
    }

    for (const searchText of subTexts) {
      if (text.includes(searchText)) {
        return true;
      }
    }

    return false;
  }

  
  public static buildBarVerticalChartDataForQuestion(
    questionStats: QuestionStats,
    translate : TranslateService
  ) {
    const avgTimeAnswers = questionStats.avgTimeAnswers;
    const avgTimeCorrectAnswers = questionStats.avgTimeCorrectAnswers;
    const duration = questionStats.duration;
    return [
      {
        name: translate.instant('component.questionStats.duration'),
        value: duration ? duration : '',
      },
      {
        name: translate.instant('component.questionStats.avgCorrect'),
        value: avgTimeCorrectAnswers ? avgTimeCorrectAnswers : '',
      },
      {
        name: translate.instant('component.questionStats.avgTime'),
        value: avgTimeAnswers ? avgTimeAnswers : '',
      }
    ];
  }

  public static buildAdvancedPieChartDataForQuestion(
    questionStats: QuestionStats,
    translate : TranslateService
  ) {
    return [
      {
        name: translate.instant('component.questionStats.false'),
        value: Math.round(questionStats.passes*(1-questionStats.correctAnswersPercentage))
      },
      {
        name: translate.instant('component.questionStats.correct'),
        value: Math.round(questionStats.passes*questionStats.correctAnswersPercentage)
        
      },
    ];
  }

  public static buildBarVerticalChartDataForAnswersCardinality(
    questionStats: QuestionStats
  ) {
      let res=[]
      for(let i=0; i<questionStats.mcquestionAnswersCardinality.length;i++){
        var arr=
        {
          name: questionStats.mcquestionAnswersCardinality[i][0],
          value: questionStats.mcquestionAnswersCardinality[i][1]
        };
        res.push(arr)

      }         
      return res;
  }

}
