import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../service';
import {Utils} from '../../shared/utilities/Utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent implements OnInit {

  constructor(@Inject(Router) private router: Router,
              @Inject(AuthService) private authService: AuthService,
              public translate: TranslateService) {
  }

  ngOnInit() {
    this.authService.getCurrentMembreAptea().subscribe(authenticatedMember => {
      if (Utils.authorizedRoles.includes(authenticatedMember.profile)) {
        this.router.navigate(['/home']);
      }
    });
  }

}
