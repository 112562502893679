<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  {{translate.instant('component.questionStats.title')}}
  <data-toggle mat-button
  tooltip="{{translate.instant('component.questionStats.info')}}">
    <mat-icon>info</mat-icon>
</data-toggle>
<span class="close margin-left-20px" aria-label="Close" mat-dialog-close>
  <span aria-hidden="true">&times;</span>
</span>
</h2>

<div mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane">

  <app-data-loading-spinner [loadingData]="loadingInitData"></app-data-loading-spinner>

  <div *ngIf="!loadingInitData && questionStats" class="row">
    
    <mat-card class="col-sm-12 col-md-6">
      <mat-card-content>
        <form>
        <h3>{{translate.instant('component.questionStats.enonce')}}</h3>
          <br/>
          <br/>
          <span [innerHTML]="this.data.questionEnonce"></span>
    </form>
      </mat-card-content>
    </mat-card>


    <mat-card class="col-sm-12 col-md-6">
      <mat-card-content>
        <h3>{{translate.instant('component.questionStats.title1')}}</h3>
        <ngx-charts-advanced-pie-chart
          [view]="view"
          [scheme]="advancedPieChartColorScheme"
          [results]="advancedPieChartData">
        </ngx-charts-advanced-pie-chart>
      </mat-card-content>
    </mat-card>

    <mat-card class="col-sm-12 col-md-6">
      <mat-card-content>
        <h3>{{translate.instant('component.questionStats.title2')}}</h3>
        <ngx-charts-bar-vertical
          [view]="view"
          [scheme]="barVerticalChartColorScheme"
          [results]="barVerticalChartData"
          [xAxis]="true"
          [yAxis]="true">
        </ngx-charts-bar-vertical>
      </mat-card-content>
    </mat-card>

    <mat-card class="col-sm-12 col-md-6" 
    *ngIf="this.data.questionType==='choix multiple'">
      <mat-card-content>
        <h3>{{translate.instant('component.questionStats.title3')}}</h3>
        <ngx-charts-bar-vertical
          [view]="view"
          [scheme]="barVerticalChartColorScheme"
          [results]="barVerticalChartDataCM"
          [xAxis]="true"
          [yAxis]="true">
        </ngx-charts-bar-vertical>
      </mat-card-content>
    </mat-card>
  </div>

</div>
