<form [formGroup]="form">
  <div class="content" fxLayout="row" fxLayoutAlign="center">
    <mat-card>
      <mat-card-title>
        <h2 align="center">{{(isUpdate ? translate.instant('question.questionDialogs.code.title.update') :
          translate.instant('question.questionDialogs.code.title.add')) +
        translate.instant('question.questionDialogs.code.title.message')}}</h2>
      </mat-card-title>
      <br/>

      <app-data-loading-spinner [loadingData]="loadingInitData"></app-data-loading-spinner>

      <mat-card-content [hidden]="loadingInitData">
        <div class="row">
          <div class="col-lg-6">
            <div align="center">
              <mat-form-field class="taille-form">
                <input matInput [(ngModel)]="questionCode.titre" formControlName="newTitre" matTextareaAutosize
                       matAutosizeMinRows=1 matAutosizeMAxRows=6
                       placeholder="{{translate.instant('fields.questionTitle')}}">
                <mat-icon matSuffix>title</mat-icon>
              </mat-form-field>
            </div>

            <mat-card-title>
              <h5>{{translate.instant('fields.categories')}}</h5>
            </mat-card-title>
            <div align="center">
              <div class="taille-form">
                <ng-multiselect-dropdown
                  formControlName="formCategories"
                  [placeholder]="translate.instant('actions.choseCategories')"
                  [settings]="categorieService.dropdownCategoriesSettings"
                  [data]="categorieService.allCategories"
                  [(ngModel)]="categorieService.categoriesActuelle">
                </ng-multiselect-dropdown>
              </div>
            </div>
            <div align="center">
              <mat-form-field class="taille-form">
                <mat-chip-list #cat>
                  <mat-chip *ngFor="let qc of this.categorieService.nouvellesCategories" [selectable]="selectable"
                            [removable]="removable" (removed)="removeCategorie(qc)">
                    {{qc.nom}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
                <input type="hidden" [(ngModel)]="categorieService.nouvellesCategories" name="nouvellesCategories"
                       formControlName="nouvellesCategories">
                <input placeholder="{{translate.instant('actions.addCategory')}}"
                       [matChipInputFor]="cat"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="addOnBlur"
                       (matChipInputTokenEnd)="addCategorie($event)">
              </mat-form-field>
            </div>
          </div>

          <div class="col-lg-6">
            <mat-form-field class="taille-form">
              <input type="number" [min]="1" appPositiveNumber matInput [(ngModel)]="questionCode.duration"
                     formControlName="duree" matTextareaAutosize matAutosizeMinRows=1 matAutosizeMAxRows=6
                     placeholder="{{translate.instant('fields.durationMsg')}}"/>
              <mat-icon matSuffix>timer</mat-icon>
            </mat-form-field>

            <mat-form-field class="taille-form">
              <mat-select [(ngModel)]="questionCode.difficulty" formControlName="difficulte"
                          placeholder="{{translate.instant('fields.difficultyMsg')}}">
                <mat-option value="{{ questionCode.difficulty }}"></mat-option>
                <mat-option [value]=1>1</mat-option>
                <mat-option [value]=2>2</mat-option>
                <mat-option [value]=3>3</mat-option>
                <mat-option [value]=4>4</mat-option>
                <mat-option [value]=5>5</mat-option>
              </mat-select>
              <mat-icon matSuffix>school</mat-icon>
            </mat-form-field>

            <mat-form-field class="taille-form">
              <mat-select [(ngModel)]="questionCode.technologie" formControlName="technologie"
                          placeholder="{{translate.instant('fields.technology')}}">
                <mat-option [value]="questionCode.technologie"> {{ questionCode.technologie }}</mat-option>
                <mat-option value="java">java</mat-option>
                <mat-option value="php">php</mat-option>
                <mat-option value="c#">c#</mat-option>
                <mat-option value="python">python</mat-option>
                <mat-option value="c">c</mat-option>
                <mat-option value="c++">c++</mat-option>
                <mat-option value="MySQL">MySQL</mat-option>
                <mat-option value="NodeJS">NodeJS</mat-option>
              </mat-select>
              <mat-icon matSuffix>android</mat-icon>
            </mat-form-field>
          </div>

          <div class="col-lg-12">
            <mat-form-field class="taille-form">
              <label>{{translate.instant('fields.questionContent')}}</label>
              <textarea class="d-none" matInput [(ngModel)]="questionCode.enonce"
                        formControlName="enonce" matTextareaAutosize></textarea>
              <ejs-richtexteditor id='defaultRTE3'
                                  [(value)]="questionCode.enonce"
                                  [toolbarSettings]="constants.RICH_TEXT_EDITOR_TOOLS"
                                  [quickToolbarSettings]="constants.RICH_TEXT_EDITOR_QUICK_TOOLS">
              </ejs-richtexteditor>

            </mat-form-field>

            <mat-form-field class="taille-form">
                <textarea matInput [(ngModel)]="questionCode.codePrerempli" formControlName="codePrerempli"
                          matTextareaAutosize
                          matAutosizeMinRows=1 matAutosizeMaxRows=6
                          placeholder="{{translate.instant('question.questionDialogs.code.fields.codePlaceholder')}}"
                          class="fenetre_code"></textarea>
            </mat-form-field>

            <mat-form-field class="taille-form">
                <textarea matInput [(ngModel)]="questionCode.resultat" formControlName="newResultat" matTextareaAutosize
                          matAutosizeMinRows=1 matAutosizeMAxRows=6
                          placeholder="{{translate.instant('fields.waitingResult')}}"></textarea>
              <mat-icon matSuffix color="warning">question_answer</mat-icon>
            </mat-form-field>

            <mat-card-title>
              <div class="d-flex justify-content-between">
                <h5>{{translate.instant('fields.testingZone')}}</h5>
                <h6>
                  <mat-slide-toggle color="primary" (change)="useMonacoEditor=!useMonacoEditor">
                    {{translate.instant('fields.aceEditor')}}
                  </mat-slide-toggle>
                </h6>
              </div>
            </mat-card-title>
            <ace-editor
              *ngIf="!useMonacoEditor"
              [(text)]="questionCode.codeComplet"
              [mode]="utils.getQuestionCodeChosenLanguage(questionCode.technologie, false, true)"
              theme="eclipse"
              [options]="options"
              (textChanged)="onTextChanged($event)"
              class="ace-editor">
            </ace-editor>

            <ngx-monaco-editor
              *ngIf="useMonacoEditor"
              [options]="{theme: appTheme.globalTheme === appTheme.light? 'vs' : 'vs-dark',
              language: utils.getQuestionCodeChosenLanguage(questionCode.technologie, false, false)}"
              [(ngModel)]="questionCode.codeComplet"
              formControlName="codeComplet">
            </ngx-monaco-editor>

            <div class="divider"></div>
            <button type="button" (click)="onExecute()" mat-raised-button color="primary"
                    [disabled]="questionCode.technologie == '' || executingCode">
            <span *ngIf="executingCode" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
            </span>
              {{executingCode ? translate.instant('actions.executing') : translate.instant('actions.execute')}}
            </button>
          </div>
        </div>
      </mat-card-content>

      <mat-action-list>
        <div class="row d-flex flex-row-reverse px-5">
          <div fxLayout="row">
            <button type="button" *ngIf="questionCode" (click)="onSubmit()" mat-raised-button color="primary"
                    [disabled]="form.invalid || submitted || executingCode" class="min-width-100-px"
                    [ngClass]="{'min-width-120-px': submitted}">
          <span *ngIf="submitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
            </span>
              {{submitted ? translate.instant('actions.loading') : (isUpdate ? translate.instant('actions.update') :
              translate.instant('actions.add'))}}
            </button>

            <div class="divider"></div>

            <button type="button" mat-raised-button color="warn" class="btn btn-primary-outline pull-right" (click)="btnClick();">
              {{translate.instant('actions.cancel')}} 
            </button>
          </div>
        </div>
      </mat-action-list>
    </mat-card>
  </div>
</form>

