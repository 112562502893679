import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { QuestionService } from '../../../service/question.service';
import { QuestionLibre } from '../../../models/question.libre';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CategorieService } from '../../../service/categorie.service';
import { Categorie } from '../../../models/categorie';
import { MatChipInputEvent } from '@angular/material/chips';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { NotyfToast } from '../../../shared/notify-toast/notify-toast.component';
import { cloneDeep } from 'lodash';
import { Utils } from '../../../shared/utilities/Utils';
import { Constants } from '../../../shared/utilities/Constants';
import { TranslateService } from '@ngx-translate/core';
import { relaceImagesWithBlobByDataURL } from '../fixForBlobImage';

import { Location } from '@angular/common';

/// édition de question ouverte
@Component({
  selector: 'app-question-ouverte-dialog',
  templateUrl: './question-ouverte-dialog.component.html',
  styleUrls: ['./question-ouverte-dialog.component.css'],
})
export class QuestionOuverteDialogComponent implements OnInit {
  idQuestion: number;
  questionLibre: QuestionLibre = new QuestionLibre();

  form: FormGroup;

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  submitted = false;
  loadingInitData: boolean;
  isUpdate: boolean;

  responseLabels: Array<string> = [];

  constants = Constants;

  constructor(
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    @Inject(QuestionService) private questionService: QuestionService,
    @Inject(CategorieService) public categorieService: CategorieService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private location: Location
  ) {}

  async ngOnInit() {
    this.loadingInitData = true;

    this.idQuestion = this.route.snapshot.params['id'];

    if (this.idQuestion) {
      this.isUpdate = true;
    }

    this.form = this.formBuilder.group({
      newQuestion: ['', [Validators.required]],
      newTitre: ['', [Validators.required]],
      newResponse: ['', [Validators.required]],
      formCategories: [''],
      nouvellesCategories: [''],
      duration: ['', [Validators.required]],
      difficulte: ['', [Validators.required]],
      referenceAnswer: ['', [Validators.required]],
    });

    if (this.isUpdate) {
      await this.categorieService
        .resetDataPourQuestion(this.idQuestion)
        .toPromise()
        .then();

      await this.questionService
        .getQuestionLibre(this.idQuestion)
        .toPromise()
        .then(
          (data) => {
            this.questionLibre = data;
            this.responseLabels = this.getResponseLabelsFromResponseText(
              this.questionLibre.resultat
            );
            this.form.patchValue({
              referenceAnswer: this.questionLibre.referenceAnswer,
            });
          },
          (err) => {
            this.toastr.error(this.translate.instant('toastr.error.error'));
          }
        );
    } else {
      await this.categorieService.resetData();
    }

    this.loadingInitData = false;
  }

  getResponseLabelsFromResponseText(responseText: string): Array<string> {
    return responseText.split('\n');
  }

  onSubmit() {
    this.submitted = true;

    // récupération des catégories sélectionnées et des nouvelles créées
    this.questionLibre.questionCategories =
      this.form.value.formCategories.concat(
        this.form.value.nouvellesCategories
      );

    this.questionLibre.referenceAnswer = this.form.value.referenceAnswer; // trying to not mix ngModel with reactive form

    (async () => {
      await relaceImagesWithBlobByDataURL(this.questionLibre);
      this.questionService
        .updateQuestionLibre(this.questionLibre)
        .pipe(
          finalize(() => {
            this.submitted = false;
          })
        )
        .subscribe(
          (data) => {
            const translateVar = this.translate.instant(
              'question.questionDialogs.open.submitSuccess'
            );
            this.sendNotifSuccess(
              translateVar.message +
                (this.isUpdate ? translateVar.updated : translateVar.added)
            );
            this.location.back();
          },
          (err) => {
            if (err.status === 412) {
              // precondition failed
              this.toastr.error(
                this.translate.instant('toastr.error.optimisticLocking')
              );
            } else {
              const translateVar = this.translate.instant(
                'question.questionDialogs.open.submitError'
              );
              this.toastr.error(
                this.isUpdate ? translateVar.updateError : translateVar.addError
              );
            }
          }
        );
    })();
  }

  private sendNotifSuccess(message: string): void {
    const options = cloneDeep(this.toastr.toastrConfig);
    options.toastComponent = NotyfToast;
    options.toastClass = 'notyf confirm';
    this.toastr.success(message, null, options);
  }

  addCategorie(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      const cat = new Categorie();
      cat.nom = value.trim();
      this.categorieService.nouvellesCategories.push(cat);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeCategorie(categorie: Categorie): void {
    let index = -1;
    for (
      let i = 0;
      i < this.categorieService.nouvellesCategories.length;
      i += 1
    ) {
      if (
        this.categorieService.nouvellesCategories[i]['nom'] === categorie.nom
      ) {
        index = i;
      }
    }

    if (index >= 0) {
      this.categorieService.nouvellesCategories.splice(index, 1);
    }
  }

  addResponse(event: MatChipInputEvent) {
    Utils.addResponseForQuestion(
      event,
      this.responseLabels,
      this.questionLibre
    );
  }

  removeResponse(response: string) {
    Utils.removeResponseFromQuestion(
      response,
      this.responseLabels,
      this.questionLibre
    );
  }

  btnClick = function () {
    this.location.back();
  };
}
