import {Question} from '../../models/question';

export async function relaceImagesWithBlobByDataURL(question: Question) {
  return new Promise<void>(async (resolve, reject) => {
    const myImagePattern = new RegExp('<img src="([a-zA-Z:\\/0-9-\\.]*)"', 'g'); // pattern for blob url
    let matches = null;
    while (matches = myImagePattern.exec(question.enonce)) {
      // tslint:disable-next-line:no-shadowed-variable
      function blobToDataURL(blob: Blob) {
        // tslint:disable-next-line:no-shadowed-variable
        return new Promise((resolve, reject) => {
          const a = new FileReader();
          a.onload = function (e) {
            return resolve(e.target.result);
          }
          try {
            a.readAsDataURL(blob);
          } catch (e) {
            return reject(e);
          }
        });
      }

      try {
        const myBlob = await fetch(matches[1]).then(r => r.blob());
        const dataURl = await blobToDataURL(myBlob);
        question.enonce = question.enonce.replace(
          '<img src="' + matches[1] + '"',
          '<img src="' + dataURl + '"');
      } catch (e) {
        return reject(e);
      }
    }
    return resolve();
  });
}

