import {Component, Inject, Input, OnInit} from '@angular/core';
import { InvitationsComponent } from 'app/invitations/invitations.component';
import {DOCUMENT} from '@angular/common';


/// Score badge with value && color
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'colored-score',
  templateUrl: './colored-score.component.html',
  styleUrls: ['./colored-score.component.css']
})
export class ColoredScoreComponent {
  @Input() score: number;
  @Input() noteSansPen: number;

  constructor(@Inject(DOCUMENT) public document: Document,
    @Inject(InvitationsComponent) public invitationsComponent: InvitationsComponent) {
  }

  public isChecked() {
    const get_id = document.getElementById('ToggleswitchId') as HTMLInputElement;
    if (get_id.checked) {
      return (true)
    } else {
      return(false)
    }

  }

}
