import {Component, OnInit, ViewChild, Input, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {AceEditorComponent} from 'ng2-ace-editor';
import {FormGroup, FormBuilder} from '@angular/forms';
import {MonacoStandaloneCodeEditor} from '@materia-ui/ngx-monaco-editor';
import {TranslateService} from '@ngx-translate/core';
import { QuestionCode } from 'app/models/question.code';
import { Utils } from 'app/shared/utilities/Utils';
import { AppTheme } from 'app/shared/utilities/AppTheme';
import { JdoodleDataOutput } from 'app/models/jdoodle.data.output';

@Component({
  selector: 'app-preview-question-code',
  templateUrl: './preview-question-code.component.html',
  styleUrls: ['./preview-question-code.component.css']
})
export class PreviewQuestionCodeComponent implements OnInit, AfterViewInit {

  @ViewChild('aceEditor') aceEditor: AceEditorComponent;

  @Input() question: QuestionCode;

  utils = Utils;
  appTheme = AppTheme;

  useMonacoEditor = true;
  initAceEditor = false;

  isReady = false;
  jdataOutput: JdoodleDataOutput;
  codeExecuting: boolean;

  constructor(private formBuilder: FormBuilder,
              private changeDetector: ChangeDetectorRef,
              public translate: TranslateService) {
  }

  ngOnInit() {
    /*if (!this.formGroup) {
      this.formGroup = this.formBuilder.group({});
    }

    this.formGroup = this.formBuilder.group({
      sourceCodeMonaco: ['']
    });*/
    this.isReady = true;
  }

  ngAfterViewInit(): void {
    this.checkEditors();
  }

  editorInit(editor: MonacoStandaloneCodeEditor) {
    // tslint:disable-next-line:no-bitwise
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_C, () => null);
    // tslint:disable-next-line:no-bitwise
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_V, () => null);
    // tslint:disable-next-line:no-bitwise
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_X, () => null);
    // tslint:disable-next-line:no-bitwise
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KEY_V, () => null);
    // tslint:disable-next-line:no-bitwise
    editor.addCommand(monaco.KeyMod.Shift | monaco.KeyCode.Delete, () => null);
  }





  switchEditor() {
    this.useMonacoEditor = !this.useMonacoEditor;
    this.changeDetector.detectChanges();

    this.checkEditors();
  }

  checkEditors() {
    if (this.useMonacoEditor) {
      this.disableContextMenu('monaco-editor');
    } else {
      this.disableContextMenu('ace-editor');

      const aceEditor = this.aceEditor.getEditor();
      this.disableAceEditorCopyPasteCut(aceEditor);
      this.disableAceEditorDragAndDrop(aceEditor);
    }
  }

  disableContextMenu(htmlElementId: string) {
    // Disable mouse right click menu
    document.getElementById(htmlElementId).addEventListener('contextmenu', function (e) {
      e.preventDefault();
      return false;
    }, false);
  }

  disableAceEditorCopyPasteCut(aceEditor) {
    aceEditor.commands.addCommand({
      name: 'breakTheEditor',
      bindKey: 'ctrl-c|ctrl-v|ctrl-x|ctrl-shift-v|shift-del|cmd-c|cmd-v|cmd-x',
      exec: function () {
      }
    });
  }

  disableAceEditorDragAndDrop(aceEditor) {
    ['dragenter', 'dragover', 'dragend', 'dragstart', 'dragleave', 'drop'].forEach(function (eventName) {
      aceEditor.container.addEventListener(eventName, function (e) {
        e.stopPropagation()
      }, true)
    });
    aceEditor.setOption('dragEnabled', false)
  }
}
