import {HttpClient} from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { ReponseReview } from 'app/models/reponseReview';
import { TestCandidat } from 'app/models/testCandidat';

@Injectable()
export class TestCandidatService {

  constructor(
    @Inject(HttpClient) private httpClient: HttpClient,
    @Inject(ConfigService) private config: ConfigService
  ) { }

  getTestCandidatFull(id: number): Observable<TestCandidat> {
    return this.httpClient.get<TestCandidat>(this.config.testCandidatFullById.replace('{id}', id.toString()));
  }

  putReponseReview(testCandidatId: number, testCandidatEntryId: number, review: ReponseReview): Observable<void> {
    return this.httpClient.put<void>(this.config.testCandidatFullReponseReview
        .replace('{testCandidatId}', testCandidatId.toString())
        .replace('{testCandidatEntryId}', testCandidatEntryId.toString()), review);
  }

}
