import {
  Component,
  Inject,
  OnInit,
  DoCheck,
  SimpleChanges,
  OnChanges,
  Input,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Invitation } from "../../models/invitation";
import { Questionnaire } from "../../models/questionnaire";
import { Router } from "@angular/router";
import { QuestionnaireService } from "../../service/questionnaire.service";
import { InvitationService } from "../../service/invitation.service";
import { SearchService } from "../../service/search.service";
import { Candidat } from "../../models/candidat";
import { TestCandidat } from "../../models/testCandidat";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { NotyfToast } from "../../shared/notify-toast/notify-toast.component";
import { cloneDeep } from "lodash";
import { Constants } from "../../shared/utilities/Constants";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "../../service/config.service";

@Component({
  selector: "app-nouveau-invitation",
  templateUrl: "./nouveau-invitation.component.html",
  styleUrls: ["./nouveau-invitation.component.scss"],
})
export class NouveauInvitationComponent implements OnInit {
  public invitation: Invitation = new Invitation();
  public candidat: Candidat = new Candidat();
  public testcandidat: TestCandidat = new TestCandidat();
  public allQuestionnaires: Array<Questionnaire> = [];
  form: FormGroup;

  dropdownQuestionnairesSettings = {};

  messageErreur = "";

  /**
   * Boolean used in telling the UI
   * that the form has been submitted
   * and is awaiting a response
   */
  submitted = false;
  result = false;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";

  email: string;
  loadingInitData = false;

  constructor(
    @Inject(Router) private router: Router,
    @Inject(QuestionnaireService)
    private questionnaireService: QuestionnaireService,
    @Inject(InvitationService) private invitationService: InvitationService,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    @Inject(SearchService) private searchService: SearchService,
    @Inject(ConfigService) private config: ConfigService,
    private newInvitationDialogRef: MatDialogRef<NouveauInvitationComponent>,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {
    if (!this.newInvitationDialogRef) {
      return;
    }
    this.newInvitationDialogRef.disableClose = true;
  }

  async ngOnInit() {
    this.loadingInitData = true;
    await this.questionnaireService
      .listOfQuestionnaires(0, Constants.INTEGER_MAX_VALUE)
      .toPromise()
      .then(
        (response) => {
          this.allQuestionnaires = response.content;
        },
        (error1) => {
          this.toastr.error(
            this.translate.instant(
              "invitations.newInvitations.questionnairesListError"
            )
          );
        }
      );

    this.dropdownQuestionnairesSettings = {
      singleSelection: false,
      idField: "id",
      textField: "nom",
      selectAllText: "Tous",
      unSelectAllText: "Aucun",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.form = this.formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      questionnaire: ["", [Validators.required]],
      firstname: ["", [Validators.required]],
      name: ["", [Validators.required]],
      xp: ["", [Validators.pattern("^[0-9]*$")]],
      invitationDate: ["", []],
    });

    this.loadingInitData = false;
  }

  private sendNotifSuccess(message: string): void {
    const options = cloneDeep(this.toastr.toastrConfig);
    options.toastComponent = NotyfToast;
    options.toastClass = "notyf confirm";
    this.toastr.success(message, null, options);
  }

  async onSubmit() {
    this.submitted = true;

    // le formulaire renvoie un tableau de questionnaire au lieu d'un questionnaire : réaffectation
    // tslint:disable-next-line:triple-equals
    if (this.form.get("questionnaire").value != undefined) {
      this.candidat.prenom = this.form.get("firstname").value;
      this.candidat.nom = this.form.get("name").value;
      this.candidat.xp = this.form.get("xp").value;

      const questionnaires = this.form.get("questionnaire").value;
      const numberOfQuestionnaires: number = (this.testcandidat.questionnaire =
        questionnaires.length);
      const invitationDate = new Date(this.form.get("invitationDate").value);
      const asyncInvitations = [];
      var invitations = [];
      for (let idx = 0; idx < numberOfQuestionnaires; idx++) {
        var invitation = {
          candidat: this.candidat,
          testcandidat: { questionnaire: questionnaires[idx] },
          dateInvitation: invitationDate,
        };
        invitations.push(invitation);
        /* this.testcandidat.questionnaire = questionnaires[idx];
        this.invitation.testcandidat = this.testcandidat;
        this.invitation.candidat = this.candidat;
        this.invitation.dateInvitation = invitationDate;
        asyncInvitations.push(
          await this.invitationService
            .creerInvitation(this.invitation)
            .toPromise()
        );*/
      }

      var self = this; // Save a reference to the current object

      this.invitationService.creerInvitations(invitations).subscribe(
        function (invitations) {
          if (numberOfQuestionnaires > 1) {
            self.sendNotifSuccess(
              self.translate.instant(
                "invitations.newInvitations.questionnairesSent"
              )
            );
          } else {
            self.sendNotifSuccess(
              self.translate.instant(
                "invitations.newInvitations.questionnaireSent"
              )
            );
          }
          self.newInvitationDialogRef.close({
            invitationCreated: true,
            newInvitations: invitations,
          });
        },
        function (error) {
          console.log("error in sendInvitations: ", error);
          self.toastr.error(
            self.translate.instant(
              "invitations.newInvitations.sendInvitationError"
            )
          );
        }
      );
      /* const listOfInvitations = Promise.all(asyncInvitations);
      await listOfInvitations.then(
        (invitation) => {
          numberOfQuestionnaires > 1
            ? this.sendNotifSuccess(
                this.translate.instant(
                  "invitations.newInvitations.questionnairesSent"
                )
              )
            : this.sendNotifSuccess(
                this.translate.instant(
                  "invitations.newInvitations.questionnaireSent"
                )
              );
          this.newInvitationDialogRef.close({
            invitationCreated: true,
            newInvitation: invitation,
          });
        },
        (error) => {
          console.log("error in sendInvitatations: ", error);
          this.toastr.error(
            this.translate.instant(
              "invitations.newInvitations.sendInvitationError"
            )
          );
        }
      );*/
    }
    this.submitted = false;
  }
  blurEvent() {
    this.result = this.searchService.getEmails(this.form.get("email").value);
  }
}
