import {Component, OnInit, Input, Inject} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { QuestionLibre } from 'app/models/question.libre';

@Component({
  selector: 'app-preview-question-ouverte',
  templateUrl: './preview-question-ouverte.component.html',
  styleUrls: ['./preview-question-ouverte.component.css']
})
export class PreviewQuestionOuverteComponent implements OnInit {

  @Input() question: QuestionLibre;

  isReady = false;

  constructor(@Inject(FormBuilder) private formBuilder: FormBuilder) { }

  ngOnInit() {
    /*if (!this.formGroup) {
      this.formGroup = this.formBuilder.group({});
    }
    this.formGroup.addControl('text', this.formBuilder.control(['']));*/
    this.isReady = true;
  }
}
