import { map } from "rxjs/operators";
import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "./config.service";
import { Invitation, PDFData } from "../models/invitation";
import { CandidatQuestion } from "../models/candidatQuestion";
import { CandidatReponse } from "../models/candidatReponse";
import { JdoodleDataOutput } from "../models/jdoodle.data.output";
import { Utils } from "../shared/utilities/Utils";
import { TranslateService } from "@ngx-translate/core";

/*
export enum PhaseInvitation {
  Invitation = 'En attente de connexion', // le message d'invitation a été envoyé au condidat
  Connexion = 'Test non démarré', // le candidat a reçu l'invitation et a cliqué sur le lien
  Test = 'Test en cours', // le candidat a commencé le test
  FinTest = 'Test terminé', // le candidat a fini le test
  Supprime = 'Invitation supprimee', // l'invitation a été supprimée par un Admin ou RH
  Inconnu = '?'
}
*/

export enum ErreursAcces {
  CantAccessTest = 0,
  CantResubmitTest,
}

@Injectable()
export class InvitationService {
  // candidat actuellement "connecté" c'est à dire identifié par son lien d'accès (contenant un token)
  invitation: Invitation = null;

  constructor(
    @Inject(HttpClient) private httpClient: HttpClient,
    @Inject(ConfigService) private config: ConfigService,
    public translate: TranslateService
  ) {}

  getMessageErreurStr(erreur: ErreursAcces): string {
    // tslint:disable-next-line:triple-equals
    if (erreur == ErreursAcces.CantAccessTest) {
      return "Vous n'avez plus accès au test";
      // tslint:disable-next-line:triple-equals
    } else if (erreur == ErreursAcces.CantResubmitTest) {
      return "Vous ne pouvez pas soumettre le même test plusieurs fois";
    }

    return "Erreur Inconnue";
  }

  isInvitationConnecte(): boolean {
    return this.invitation !== null && this.invitation.id !== "";
  }

  // l'user name d'un candidat évolue selon sa phase courante d'inscription :
  getUserName(): string {
    if (this.invitation == null) {
      return "Invité";
    }
    if (this.invitation.candidat.nom !== "") {
      return (
        this.invitation.candidat.prenom + " " + this.invitation.candidat.nom
      );
    }

    return this.invitation.candidat.email;
  }

  setCandiateProfile(invitation: Invitation) {
    if (this.invitation == null) {
      return;
    }

    const id: string = invitation.id;
    const firstname: string = invitation.nom;
    const name: string = invitation.prenom;
    const xp: string | number = invitation.xp;

    // @ts-ignore
    return this.httpClient
      .put<Invitation>(
        this.config.majFirstNameAndName
          .replace("{id}", id.toString())
          .replace("{firstname}", firstname.toString())
          .replace("{name}", name.toString())
          .replace("{xp}", xp.toString()),
        null
      )
      .pipe(
        map((invitationObject) => {
          return invitationObject;
        })
      );
  }

  /**
   * Crée un candidat "vierge" à partir de son adresse mail, lui envoie un mail d'invitation au test et lui génère un id/token unique
   */
  creerInvitation(invitation: Invitation): Observable<Invitation> {
    return this.httpClient
      .post<Invitation>(this.config.creerInvitation, invitation)
      .pipe(
        // catchError(this.handleError),
        map((invitationObject) => {
          return invitationObject;
        })
      );
  }
  creerInvitations(invitation: Invitation[]): Observable<Invitation> {
    return this.httpClient
      .post<Invitation>(this.config.creerInvitation, invitation)
      .pipe(
        // catchError(this.handleError),
        map((invitationObject) => {
          return invitationObject;
        })
      );
  }
  createSimulationInvitation(questionnaireId: number): Observable<Invitation> {
    const params = new HttpParams().append(
      "questionnaireId",
      questionnaireId.toString()
    );
    return this.httpClient.post<Invitation>(
      this.config.createSimulationInvitation,
      null,
      { params: params }
    );
  }

  startTest(id: string): Observable<void> {
    return this.httpClient.put<void>(
      this.config.candidatTestStart.replace("{token}", id),
      null
    );
  }

  getTestQuestions(id: string): Observable<CandidatQuestion[]> {
    return this.httpClient.get<CandidatQuestion[]>(
      this.config.candidatTestQuestions.replace("{token}", id)
    );
  }

  postTestReponses(
    reponses: CandidatReponse[],
    id: string
  ): Observable<Invitation> {
    return this.httpClient.post<Invitation>(
      this.config.candidatTestReponses.replace("{token}", id),
      reponses
    );
  }

  getCurrentTestQuestion(id: string): Observable<CandidatQuestion> {
    return this.httpClient.get<CandidatQuestion>(
      this.config.candidatTestCurrentQuestion.replace("{token}", id)
    );
  }

  postCurrentTestReponse(
    reponse: CandidatReponse,
    id: string
  ): Observable<Invitation> {
    return this.httpClient.post<Invitation>(
      this.config.candidatTestCurrentReponse.replace("{token}", id),
      reponse
    );
  }

  testExecuteCode(
    code,
    technologie,
    id: string
  ): Observable<JdoodleDataOutput> {
    return this.httpClient.post<JdoodleDataOutput>(
      this.config.candidatTestExecuteCode.replace("{token}", id),
      {
        code: code,
        technologie: technologie,
      }
    );
  }

  finTest(): Observable<Invitation> {
    return this.httpClient
      .post<Invitation>(this.config.finTest, this.invitation)
      .pipe(
        map((invitationObject) => {
          this.invitation = invitationObject;
          return invitationObject;
        })
      );
  }

  supprimerInvitations(ids: string): Observable<Invitation[]> {
    const parametres = new HttpParams().append("ids", ids);

    return this.httpClient.delete<Invitation[]>(this.config.listOfInvitations, {
      params: parametres,
    });
  }

  getInvitationFromId(id: string): Observable<Invitation> {
    const parametres = new HttpParams().append("token", id);
    return this.httpClient.get<Invitation>(this.config.getInvitation, {
      params: parametres,
    });
  }

  getInvitationValideFromId(id: string): Observable<Invitation> {
    return this.httpClient.get<Invitation>(
      this.config.getInvitationValide + id
    );
  }

  getInvitationPDFDataFromId(id: string): Observable<PDFData> {
    return this.httpClient.get<PDFData>(
      this.config.getScorePDFData.replace("{id}", id.toString())
    );
  }

  getListeInvitations(
    page?: number,
    size?: number,
    simulation?: boolean,
    phase?: string,
    email?: string,
    nom?: string,
    prenom?: string,
    questionnaire?: string,
    sort?: string
  ): Observable<any> {
    let parameters = new HttpParams()
      .append("page", String(page))
      .append("size", String(size));

    if (simulation === true || simulation === false) {
      parameters = parameters.append("simulation", String(simulation));
    }

    if (phase) {
      parameters = parameters.append("phase", phase);
    }

    if (email) {
      parameters = parameters.append("email", encodeURIComponent(email));
    }

    if (nom) {
      parameters = parameters.append("nom", encodeURIComponent(nom));
    }

    if (prenom) {
      parameters = parameters.append("prenom", encodeURIComponent(prenom));
    }

    if (questionnaire) {
      parameters = parameters.append("questionnaire", questionnaire);
    }

    if (sort) {
      parameters = parameters.append(
        "sort",
        !sort.includes("dateInvitation")
          ? Utils.camelCaseNameToPoint(sort)
          : sort
      );
    }

    const options = {
      params: parameters,
    };

    return this.httpClient.get<any>(this.config.listOfInvitations, options);
  }

  getPhaseCandidature(pInvitation: Invitation = this.invitation): string {
    if (pInvitation.dateSuppression != null) {
      return this.translate.instant("invitations.phaseStatus.deleted");
    }
    if (pInvitation.testcandidat.dateFinTest != null) {
      return this.translate.instant("invitations.phaseStatus.finished");
    }
    if (pInvitation.testcandidat.dateDebutTest != null) {
      return this.translate.instant("invitations.phaseStatus.inProgress");
    }
    if (pInvitation.dateConnexion != null) {
      return this.translate.instant("invitations.phaseStatus.notStarted");
    }
    if (pInvitation.dateInvitation != null) {
      return this.translate.instant("invitations.phaseStatus.onHold");
    }
    return this.translate.instant("invitations.phaseStatus.unknown");
  }

  isInvitationEnTest(): boolean {
    return (
      this.isInvitationConnecte() &&
      this.getPhaseCandidature() ===
        this.translate.instant("invitations.phaseStatus.inProgress")
    );
  }

  /**
   * return true seulement si le candidat a été invité mais n'a encore pas suivi le lien
   */
  isInvitationEnInvitation(): boolean {
    return (
      this.isInvitationConnecte() &&
      this.getPhaseCandidature() ===
        this.translate.instant("invitations.phaseStatus.onHold")
    );
  }

  isInvitationTestFini(): boolean {
    return (
      this.isInvitationConnecte() &&
      this.getPhaseCandidature() ===
        this.translate.instant("invitations.phaseStatus.finished")
    );
  }

  isInvitationSupprime(): boolean {
    return (
      this.getPhaseCandidature() ===
      this.translate.instant("invitations.phaseStatus.deleted")
    );
  }

  isInvitationAvecAccesAuTest(): boolean {
    return (
      this.isInvitationConnecte() &&
      (this.getPhaseCandidature() ===
        this.translate.instant("invitations.phaseStatus.notStarted") ||
        this.getPhaseCandidature() ===
          this.translate.instant("invitations.phaseStatus.inProgress"))
    );
  }

  isInvitationTestFinished(invitation: Invitation): boolean {
    return (
      this.getPhaseCandidature(invitation) ===
      this.translate.instant("invitations.phaseStatus.finished")
    );
  }

  formatDate(date: Date): string {
    // tslint:disable-next-line: prefer-const
    let dateD = new Date(date);
    const dateAffiche =
      dateD.getFullYear().toString() +
      "-" +
      (dateD.getMonth() + 1).toString() +
      "-" +
      dateD.getDate().toString() +
      " " +
      dateD.getHours().toString() +
      "h" +
      dateD.getMinutes().toString();

    return dateAffiche;
  }

  getCandidatPrenomFromInvitation(invitation: Invitation): string {
    return invitation.candidat
      ? !!invitation.candidat.prenom
        ? invitation.candidat.prenom
        : "non renseigné"
      : "simulation";
  }

  getCandidatNomFromInvitation(invitation: Invitation): string {
    return invitation.candidat
      ? !!invitation.candidat.nom
        ? invitation.candidat.nom
        : "non renseigné"
      : "simulation";
  }

  getCandidatEmailFromInvitation(invitation: Invitation): string {
    return invitation.candidat ? invitation.candidat.email : "simulation";
  }

  getCandidatExperienceFromInvitation(invitation: Invitation): string {
    return invitation.candidat
      ? !!invitation.candidat.xp
        ? invitation.candidat.xp
        : "non renseigné"
      : "0";
  }
}
