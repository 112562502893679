import {of as observableOf, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MembreAptea} from '../models/membreAptea';
import {Injectable, EventEmitter, Inject} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {ConfigService} from './config.service';
import {HttpParams} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';
import {AuthenticationServiceSubscription} from '../models/authenticationservice-subscription';
import {ToastrService} from 'ngx-toastr';


@Injectable()
export class AuthService {


  onChange: EventEmitter<AuthenticationServiceSubscription> = new EventEmitter<AuthenticationServiceSubscription>();

  constructor(
    @Inject(ApiService) private apiService: ApiService,
    @Inject(UserService) private userService: UserService,
    @Inject(ConfigService) private config: ConfigService,
    @Inject(HttpClient) private http: HttpClient,
    private toastr: ToastrService
  ) {

  }

  login(user) {
    const loginHeaders = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true'
    });
    const body = `username=${user.username}&password=${user.password}`;
    return this.apiService.post(this.config.login_url, body, loginHeaders).pipe(map(() => {
      this.getCurrentMembreAptea().subscribe();
    }));
  }

  signup(user) {
    const signupHeaders = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    });
    return this.apiService.post(this.config.signup_url, JSON.stringify(user), signupHeaders).pipe(map(() => {
    }));
  }

  changePassowrd(passwordChanger) {
    return this.apiService.post(this.config.change_password_url, passwordChanger);
  }

  invitationAuthenticate(mail) {
    const loginHeaders = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.apiService.get(this.config.invitation_authenticate + mail.email).pipe(map(invitation => {
      return invitation;
    }));
  }

  loginThroughLdap(login) {
    const params = new HttpParams()
      .set('email', login.username)
      .set('password', login.password);

    return this.http.post<MembreAptea>(this.config.login_url_ldap, params, {withCredentials: true}).pipe(map(membreAptea => {
      this.onChange.emit({authMember: membreAptea, message: 'APTEA'});
      return membreAptea;
    }))
  }

  logout() {
    this.userService.currentUser = undefined;
    return this.http.get(this.config.logout_url);
  }

  getCurrentMembreAptea(): Observable<MembreAptea> {
    if (this.userService.currentUser) {
      return observableOf(this.userService.currentUser);
    }

    return this.http.get<MembreAptea>(this.config.current_member_url)
      .pipe(
        map(membreAptea => {
            this.userService.currentUser = membreAptea;
            this.onChange.emit({authMember: membreAptea, message: 'APTEA'});
            return membreAptea;
          },
          error => {
            this.toastr.error('Impossible de récupérer les informations de membre du serveur !');
          }));
  }
}
