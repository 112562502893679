import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-unauthorized-connection',
  templateUrl: './unauthorized-connection.component.html',
  styleUrls: ['./unauthorized-connection.component.css']
})
export class UnauthorizedConnectionComponent implements OnInit {

  constructor(public translate: TranslateService) {
  }

  ngOnInit() {
  }

}
