import {Component, Inject, Input, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {InvitationService} from '../../service/invitation.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-url-invitation-component[invitationId]',
  templateUrl: './url-invitation-component.component.html',
  styleUrls: ['./url-invitation-component.component.css']
})
export class UrlInvitationComponentComponent implements OnInit {

  @Input() invitationId: string;

  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(InvitationService) public invitationService: InvitationService,
              private toastr: ToastrService,
              public translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  copyText(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  private getInvitationLink(id: string): string {
    return this.document.location.origin + '/#/home-invitation?token=' + id;
  };

  public copyLinkToClipboard(): void {
    const link = this.getInvitationLink(this.invitationId);
    this.copyText(link);
    this.toastr.success(this.translate.instant('urlInvitation.linkCopied'), '', {positionClass: 'toast-top-left'});
  }

}
