import {Router, ActivatedRoute} from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import {QuestionService} from '../../service/question.service';
import {MembreAptea} from '../../models/membreAptea';
import {AuthService} from '../../service';
import {QuestionnaireService} from '../../service/questionnaire.service';
import {Question} from '../../models/question';
import {Categorie} from '../../models/categorie';
import {Questionnaire} from '../../models/questionnaire';
import {CategorieService} from '../../service/categorie.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {NotyfToast} from '../../shared/notify-toast/notify-toast.component';
import {cloneDeep} from 'lodash';
import {AppTheme} from '../../shared/utilities/AppTheme';
import {TranslateService} from '@ngx-translate/core';

export interface DialogData {
  questionnaireId: number;
  success: any;
}

@Component({
  selector: 'app-new-questionnaire',
  templateUrl: './new-questionnaire.component.html',
  styleUrls: ['./new-questionnaire.component.css']
})
export class NewQuestionnaireComponent implements OnInit, OnDestroy {

  idQuestionnaire: number;

  form: FormGroup;
  public questionnaire: Questionnaire = new Questionnaire();

  public columnDefs: any[];
  public rowData: any[];
  public pagination = true;
  public defaultColDef: any;
  public gridApi;

  authenticatedMember: MembreAptea;

  emptyCategories: Array<Categorie> = [];
  submitted = false;
  loadingInitData = false;

  appTheme = AppTheme;

  constructor(
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    @Inject(AuthService) private authService: AuthService,
    @Inject(QuestionnaireService) private questionnaireService: QuestionnaireService,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(QuestionService) public questionService: QuestionService,
    @Inject(CategorieService) public categorieService: CategorieService,
    private newQuestionnaireDialogRef: MatDialogRef<NewQuestionnaireComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private toastr: ToastrService,
    public translate: TranslateService) {
  }

  async ngOnInit() {

    this.loadingInitData = true;

    this.newQuestionnaireDialogRef.disableClose = true;

    const translateVar = this.translate.instant('questionnaire.newQuestionnaire.questionsFields');
    this.columnDefs = [
      {
        headerName: translateVar.title,
        field: 'titre'
      },
      {
        headerName: translateVar.duration,
        field: 'duree'
      },
      {
        headerName: translateVar.difficulty,
        field: 'difficulte'
      },
      {
        headerName: translateVar.type,
        field: 'type'
      },
    ];

    this.defaultColDef = {resizable: false};

    this.idQuestionnaire = this.data.questionnaireId;

    this.form = this.formBuilder.group({
      nom: ['', [Validators.required]],
      formCategories: ['', [Validators.required]],
      formQuestions: ['', [Validators.required]]
    });

    await this.getCurrentUser();

    await this.categorieService.resetData(true);
    await this.questionService.resetData(0, 0, true);

    if (this.idQuestionnaire) {
      await this.initEntityToEdit();
    }

    this.loadingInitData = false;
  }

  ngOnDestroy() {
  }

  async initEntityToEdit() {
    await this.questionnaireService.getQuestionnaire(this.idQuestionnaire).toPromise().then(questionnaire => {
      this.questionnaire = questionnaire;
      this.categorieService.categoriesActuelle = questionnaire.questionnaireCategories;
      this.updateEmptyCategories();
    });

    await this.questionService.getQuestionsByQuestionnaire(this.idQuestionnaire).toPromise().then(questions => {
      this.questionService.questionsActuelle = questions;
      this.form.patchValue({formQuestions: questions});
    });
  }

  async getCurrentUser() {
    await this.authService.getCurrentMembreAptea().subscribe(authenticatedMember => {
      this.authenticatedMember = authenticatedMember;
    }, error => {
      this.router.navigate(['/401']);
    });
  }

  onCategoriesDeSelectAll(params: any) {
    const questions: Question[] = [];
    this.gridApi.showLoadingOverlay();
    this.form.patchValue({formQuestions: questions});
    this.rowData = this.buildRows(questions);
    this.updateEmptyCategories();
  }

  onCategoriesSelect(params: any) {
    this.getListeQuestions(params);
    this.updateEmptyCategories();
  }

  public onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();

    if (this.data.questionnaireId) {
      this.rowData = this.buildRows(this.questionService.questionsActuelle);
    } else {
      const questions: Question[] = [];
      this.rowData = this.buildRows(questions);
    }
  }

  getListeQuestions(params: any) {

    const categoriesId = [];

    for (let i = 0; i < params.length; i++) {
      categoriesId.push(params[i].id);
    }

    const categories: Categorie[] = this.categorieService.categoriesActuelle;

    categories.forEach(categorie => {
      if (!categoriesId.includes(categorie.id)) {
        categoriesId.push(categorie.id);
      }
    });

    this.gridApi.showLoadingOverlay();
    if (categoriesId.length > 0) {

      this.questionService.listOfQuestionFromCategories(categoriesId, true).subscribe(
        questions => {
          this.form.patchValue({formQuestions: questions});
          this.rowData = this.buildRows(questions);
        }, error => {
          this.toastr.error(this.translate.instant('invitations.invitationsListError'));
        });
    } else {
      const questions: Question[] = [];
      this.rowData = this.buildRows(questions);
    }
  }

  private buildRows(questions: Question[]): any[] {
    this.questionService.questionsActuelle = questions;
    const rows: any[] = [];
    for (const question of questions) {
      rows.push({
        id: question.id,
        titre: question.titre,
        duree: question.duration,
        difficulte: question.difficulty,
        type: question.type,
      });
    }
    return rows;
  }

  onSubmit() {

    this.submitted = true;

    // remettre à jour l'objet questionnaire avant de l'envoyer en BDD :
    this.questionnaire.questionnaireCategories = [];
    for (const category of this.categorieService.categoriesActuelle) {
      this.questionnaire.questionnaireCategories.push(category);
    }

    this.questionnaireService.addQuestionnaire(this.questionnaire).toPromise().then(questionnaire => {
      const translateVar = this.translate.instant('questionnaire.newQuestionnaire.successMsg');
      this.sendNotifSuccess(translateVar.questionnaire + (this.data.questionnaireId ?
        translateVar.edited : translateVar.created));
      this.newQuestionnaireDialogRef.close({success: true, questionnaire: questionnaire});
    }, error => {
      if (error.status === 412) { // precondition failed
        this.toastr.error(this.translate.instant('toastr.error.optimisticLocking'));
      } else {
        const translateVar = this.translate.instant('questionnaire.newQuestionnaire.errorMsg');
        this.toastr.error(translateVar.errorOf + (this.data.questionnaireId ?
          translateVar.modification : translateVar.creation));
      }
    });

    this.submitted = false;
  }


  private sendNotifSuccess(message: string): void {
    const options = cloneDeep(this.toastr.toastrConfig);
    options.toastComponent = NotyfToast;
    options.toastClass = 'notyf confirm';
    this.toastr.success(message, null, options);
  }

  // since categorieService.allCategories has non-empty categories (relative to validated questions)
  // we know that any categorie not is that list must be empty (unless an update is registered in-between...)
  updateEmptyCategories(): void {
    const allCategoriesIds = this.categorieService.allCategories.map(cat => cat.id);
    this.emptyCategories = this.categorieService.categoriesActuelle.filter(
      cat => allCategoriesIds.indexOf(cat.id) < 0);
  }

  hasEmptyCategories(): boolean {
    return this.emptyCategories.length > 0;
  }

  emptyCategoriesAsString(): string {
    return this.emptyCategories.map(cat => cat.nom).join(', ');
  }
}
