import {Component, OnInit} from '@angular/core';
import {routeTransitionAnimations} from './route-transitions-animations';
import {RouterOutlet} from '@angular/router';
import {AppTheme} from './shared/utilities/AppTheme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations]
})

export class AppComponent implements OnInit {

  public headerData = 0;

  constructor() {
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation'];
  }

  ngOnInit() {
    AppTheme.changeAppTheme(AppTheme.globalTheme);
  }
}
