import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { QuestionService } from '../../../service/question.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CategorieService } from '../../../service/categorie.service';
import { Categorie } from '../../../models/categorie';
import { MatChipInputEvent } from '@angular/material/chips';
import { Question } from '../../../models/question';
import { relaceImagesWithBlobByDataURL } from '../fixForBlobImage';

import {
  HtmlEditorService,
  ImageService,
  LinkService,
  QuickToolbarService,
  TableService,
  ToolbarService,
} from '@syncfusion/ej2-angular-richtexteditor';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { NotyfToast } from '../../../shared/notify-toast/notify-toast.component';
import { cloneDeep } from 'lodash';
import { Constants } from '../../../shared/utilities/Constants';
import { Utils } from '../../../shared/utilities/Utils';
import { TranslateService } from '@ngx-translate/core';

import { Location } from '@angular/common';

/// édition de question ouverte
@Component({
  selector: 'app-question-texte-dialog',
  templateUrl: './question-texte-dialog.component.html',
  styleUrls: ['./question-texte-dialog.component.css'],
  providers: [
    ToolbarService,
    LinkService,
    ImageService,
    HtmlEditorService,
    TableService,
    QuickToolbarService,
  ],
})
export class QuestionTexteDialogComponent implements OnInit {
  public idQuestion: number;
  public question: Question = new Question();

  public form: FormGroup;

  // mat-chip interface config
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  submitted = false;
  loadingInitData: boolean;
  isUpdate: boolean;

  constants = Constants;
  utils = Utils;

  constructor(
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    @Inject(QuestionService) private questionService: QuestionService,
    @Inject(CategorieService) public categorieService: CategorieService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private location: Location
  ) {}

  async ngOnInit() {
    this.loadingInitData = true;

    this.idQuestion = this.route.snapshot.params['id'];

    if (this.idQuestion) {
      this.isUpdate = true;
    }

    this.form = this.formBuilder.group({
      newQuestion: ['', [Validators.required]],
      newTitre: ['', [Validators.required]],
      newResponse: [''],
      formCategories: [''],
      nouvellesCategories: [''],
      duration: ['', [Validators.required]],
      difficulte: ['', [Validators.required]],
    });

    if (this.isUpdate) {
      await this.categorieService
        .resetDataPourQuestion(this.idQuestion)
        .toPromise()
        .then();

      await this.questionService
        .getQuestionTexte(this.idQuestion)
        .toPromise()
        .then(
          (data) => {
            this.question = data;
          },
          (err) => {
            this.toastr.error(this.translate.instant('toastr.error.error'));
          }
        );
    } else {
      await this.categorieService.resetData();
    }

    this.loadingInitData = false;
  }

  onSubmit() {
    this.submitted = true;

    // récupération des catégories sélectionnées et des nouvelles créées
    this.question.questionCategories = this.form.value.formCategories.concat(
      this.form.value.nouvellesCategories
    );

    (async () => {
      await relaceImagesWithBlobByDataURL(this.question);
      this.questionService
        .updateQuestionTexte(this.question)
        .pipe(
          finalize(() => {
            this.submitted = false;
          })
        )
        .subscribe(
          (data) => {
            const translateVar = this.translate.instant(
              'question.questionDialogs.text.submitSuccess'
            );
            this.sendNotifSuccess(
              translateVar.message +
                (this.isUpdate ? translateVar.updated : translateVar.added)
            );
            this.location.back();
          },
          (err) => {
            if (err.status === 412) {
              // precondition failed
              this.toastr.error(
                this.translate.instant('toastr.error.optimisticLocking')
              );
            } else {
              const translateVar = this.translate.instant(
                'question.questionDialogs.text.submitError'
              );
              this.toastr.error(
                this.isUpdate ? translateVar.updateError : translateVar.addError
              );
            }
          }
        );
    })();
  }

  private sendNotifSuccess(message: string): void {
    const options = cloneDeep(this.toastr.toastrConfig);
    options.toastComponent = NotyfToast;
    options.toastClass = 'notyf confirm';
    this.toastr.success(message, null, options);
  }

  addCategorie(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      const cat = new Categorie();
      cat.nom = value.trim();
      this.categorieService.nouvellesCategories.push(cat);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeCategorie(categorie: Categorie): void {
    let index = -1;
    for (
      let i = 0;
      i < this.categorieService.nouvellesCategories.length;
      i += 1
    ) {
      if (
        this.categorieService.nouvellesCategories[i]['nom'] === categorie.nom
      ) {
        index = i;
      }
    }

    if (index >= 0) {
      this.categorieService.nouvellesCategories.splice(index, 1);
    }
  }

  btnClick = function () {
    this.location.back();
  };
}
