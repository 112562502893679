<div class="content" fxLayout="row" fxLayoutAlign="center">

  <mat-card [ngClass]="{'inline-items': testCandidat}">
    <mat-card-title>
      <app-data-loading-spinner [loadingData]="!(testCandidat || errorMessage)"></app-data-loading-spinner>

      <h4 *ngIf="testCandidat">
        <span>
          {{translate.instant('testReview.fields.candidate') + testCandidat.simulation ? 'simulation' : candidat.email}}
        </span>
        <br>
        <span>{{ translate.instant('testReview.fields.questionnaires') + testCandidat.questionnaire.nom }}</span>
        <br>
        <span>{{ translate.instant('testReview.fields.globalNote') + (testCandidat.note | number:'0.0-2') }}%</span>
      </h4>
    </mat-card-title>
    <br/>

    <p *ngIf="errorMessage">{{errorMessage}}</p>

    <mat-card-content *ngIf="testCandidat">
      <mat-vertical-stepper [linear]="false" #stepper>
        <div *ngFor="let entry of entries">
          <mat-step *ngIf="entry.data.question.type!=='texte'">
            <ng-template matStepLabel><span>{{entry.data.question.titre}}</span></ng-template>
            <span [innerHTML]="entry.data.question.enonce"></span>
            <app-review-question-choix-multiple
              *ngIf="entry.data.question.type==='choix multiple'"
              [testCandidatId]="testCandidat.id"
              [entry]="entry.data"></app-review-question-choix-multiple>
            <app-review-question-ouverte
              *ngIf="entry.data.question.type==='libre'"
              [testCandidatId]="testCandidat.id"
              [entry]="entry.data"></app-review-question-ouverte>
            <app-review-question-code
              *ngIf="entry.data.question.type==='code'"
              [testCandidatId]="testCandidat.id"
              [entry]="entry.data"></app-review-question-code>
            <div>
              <div style="padding: 15px; margin: 5px" *ngIf="entry.data.reponse">
                {{translate.instant('testReview.fields.note')}}
                <span *ngIf="entry.data.review && entry.data.review.score != null; else normalScore"
                      class="modified-by-reviewer">{{entry.data.review.score | number:'0.0-2'}}</span>
                <ng-template #normalScore>
                  <span>{{(entry.data.score != null ? entry.data.score : 0) | number:'0.0-2'}}</span>
                </ng-template>
                / {{entry.data.maxScore != null ? entry.data.maxScore : 0}}
                <span class="time-penalty" *ngIf="entry.data.scoreMultiplier != 1">&nbsp;
                  <small>
                    {{translate.instant('testReview.fields.timePenalty')}} x{{entry.data.scoreMultiplier|number:'0.2'}}
                  </small>
                </span>
                <br/>
                {{translate.instant('testReview.fields.responseTime')}}
                <span
                  [ngClass]="{'time-penalty': entry.data.scoreMultiplier != 1}">
                  {{getResponseTime(entry.data) | date:"H:mm''ss\"":"+0000"}}
                </span>
                / {{entry.data.question.duration * 1000 | date:"H:mm''ss\"":"+0000"}}
                <mat-icon color="warn" class="align-middle" [matTooltip]="suspiciouslyFastTooltip"
                          *ngIf="suspiciouslyFast(entry.data)">warning
                </mat-icon>
              </div>
              <app-reponse-annotation [testCandidatId]="testCandidat.id"
                                      [entryId]="entry.data.id"
                                      [maxScore]="entry.data.maxScore != null ? entry.data.maxScore : 0"
                                      [review]="entry.data.review"
                                      (newReview)="onNewReview($event, entry.data)"></app-reponse-annotation>
            </div>
          </mat-step>
        </div>
      </mat-vertical-stepper>
    </mat-card-content>

    <mat-card-actions class="d-flex justify-content-center" style="padding: 20px">
      <button
        type="button"
        mat-raised-button
        color="warn"
        class="btn btn-primary-outline pull-right" 
        (click)="btnClick();">
        {{translate.instant('actions.return')}}
      </button>
    </mat-card-actions>
  </mat-card>
</div>
