<div [formGroup]="formGroup">
  <div *ngIf="isReady" formArrayName="propositions">
    <ul>
      <li *ngFor="let prop of propositionsFormArray.controls; let i = index">
        <mat-checkbox (change)="toggleProposition(i)" color="primary">
          <span [innerHTML]="qcmPropositions[i].proposition.description"></span>
        </mat-checkbox>
      </li>
    </ul>
  </div>
</div>
