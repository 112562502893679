import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuestionService } from '../../service/question.service';
import { Question } from '../../models/question';
import { finalize } from 'rxjs/operators';
import { QuestionStats } from '../../models/QuestionStats';
import { Utils } from '../../shared/utilities/Utils';
import { TranslateService } from '@ngx-translate/core';

export interface QuestionStatsDialogData {
  questionId: number;
  questionType: string;
  questionEnonce: string;
}

@Component({
  selector: 'app-question-stats-dialog',
  templateUrl: './question-stats-dialog.component.html',
  styleUrls: ['./question-stats-dialog.component.scss'],
})
export class QuestionStatsDialogComponent implements OnInit {
  questionStats: QuestionStats;
  loadingInitData = false;

  view: any[] = [400, 200];

  advancedPieChartData: any[];
  advancedPieChartColorScheme = {
    domain: ['#C7B42C', '#5AA454'],
  };

  barVerticalChartData: any[];
  barVerticalChartColorScheme = {
    domain: ['#F69E05', '#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
  };

  verticalChartData: any[];
  verticalChartColorScheme = {
    domain: ['#A10A28', '#F69E05', '#5AA454'],
  };

  barVerticalChartDataCM: any[];

  constructor(
    @Inject(QuestionService) private questionService: QuestionService,
    @Inject(MatDialogRef) private questionStatsDialog: MatDialogRef<QuestionStatsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QuestionStatsDialogData,
    public translate: TranslateService,
  ) {
    questionStatsDialog.disableClose = true;
  }

  ngOnInit(): void {
    this.loadingInitData = true;
    this.questionService
      .getQuestionStats(this.data.questionId)
      .pipe(
        finalize(() => {
          this.loadingInitData = false;
        })
      )
      .toPromise()
      .then((questionStats) => {
        this.questionStats = questionStats;

        this.advancedPieChartData =
          Utils.buildAdvancedPieChartDataForQuestion(questionStats, this.translate);

        this.barVerticalChartData =
          Utils.buildBarVerticalChartDataForQuestion(questionStats, this.translate);

        if(this.data.questionType === 'choix multiple'){
          this.barVerticalChartDataCM =
            Utils.buildBarVerticalChartDataForAnswersCardinality(questionStats);
        }

      });
  }
}
