import {Component, Inject, Input, OnInit} from '@angular/core';
import {Invitation} from '../../models/invitation';
import {InvitationService} from '../../service/invitation.service';
import {Router} from '@angular/router';
import {Candidat} from '../../models/candidat';
import {InvitationRoutes} from '../../constants/InvitationRoutes';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-button-score-component[invitation]',
  templateUrl: './button-score-component.component.html',
  styleUrls: ['./button-score-component.component.css']
})
export class ButtonScoreComponentComponent implements OnInit {

  @Input() invitation: Invitation;

  constructor(@Inject(InvitationService) public invitationService: InvitationService,
              @Inject(Router) private router: Router,
              public translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  reviewTest() {
    this.router.navigate(['/review-test', this.invitation.id]);
  }

  continueSimulation() {
    this.invitationService.invitation = this.invitation;
    const candidat = new Candidat();
    candidat.email = 'simulation';
    this.invitation.candidat = candidat;
    this.router.navigate(['/' + InvitationRoutes.simulation.home], {queryParams: {token: this.invitation.id}});
  }
}
