
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { ApiService } from './api.service';
import {Inject, Injectable} from '@angular/core';
import { MembreAptea } from '../../app/models/membreAptea';

@Injectable()
export class MembreService {
    currentMembre ;

    constructor(
      @Inject(ApiService) private apiService: ApiService,
      @Inject(ConfigService) private config: ConfigService,
      @Inject(HttpClient) private httpClient: HttpClient
    ) { }

    listMembreAptea(): Observable<MembreAptea[]> {
      return this.httpClient.get<MembreAptea[]>(this.config.listMembreAptea);
    }
}
