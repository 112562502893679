<div appBlockCopyPaste class="content" fxLayout="row" fxLayoutAlign="center">
  <mat-card>
    <mat-card-title *ngIf="invitationService.invitation && !invitationService.isInvitationTestFini()">
      <h4>
        {{translate.instant('home.homeInvitation.hello') + ' ' + invitationService.invitation.candidat?.prenom }}
      </h4>
    </mat-card-title>
    <br/>

    <mat-card-content *ngIf="invitationService.invitation">
      <div *ngIf="invitationService.isInvitationTestFini()">

        <ol class="snow-bros">
          <li class="t"></li>
          <li class="h"></li>
          <li class="e"></li>
          <li>&nbsp;</li>
          <li class="e"></li>
          <li class="n"></li>
          <li class="d"></li>
        </ol>

        <p style="text-align: center">
          {{ translate.instant('home.homeInvitation.testEndMsg.first') +
        invitationService.invitation.testcandidat.questionnaire.nom +
        translate.instant('home.homeInvitation.testEndMsg.second')}}
          <br/>
          {{translate.instant('home.homeInvitation.testEndMsg.end')}}
        </p>
      </div>

      <div
        *ngIf="invitationService.isInvitationAvecAccesAuTest() && !invitationService.isInvitationEnTest()">
        <p>
          {{translate.instant('home.homeInvitation.testStartMsg.first') +
        invitationService.invitation.testcandidat.questionnaire.nom +
        translate.instant('home.homeInvitation.testStartMsg.second')}}
          <span [innerHTML]="translate.instant('home.homeInvitation.testStartMsg.third')"></span>
        </p>
        <form [formGroup]="form">
          <div class="text-justify">
            <p class="border-dark border-bottom text-center">
              {{translate.instant('home.homeInvitation.rgpd.title')}}
            </p>
            <div [innerHTML]="translate.instant('home.homeInvitation.rgpd.message')"></div>
          </div>

          <div>
            <mat-checkbox id="checkRgpd" formControlName="checkRgpd" color="primary">
              <span>{{translate.instant('home.homeInvitation.acceptRgpd')}}</span>
            </mat-checkbox>
          </div>

          <div align="center" fxLayout="row">
            <br>
            <br>
            <button [routerLink]="repondreTestRoute()" [queryParams]="{ token: token }"
                    autofocus mat-raised-button color="primary"
                    [disabled]="form.invalid">
              <mat-icon>play_circle_outline</mat-icon>
              {{translate.instant('home.homeInvitation.startTest')}}
            </button>
          </div>
        </form>
      </div>
    </mat-card-content>
    <mat-error *ngIf="!invitationService.invitation">
      <p class="font-weight-bold">
        {{ messageErreur }}
      </p>
    </mat-error>
  </mat-card>
</div>
