import {QuestionOuverteDialogComponent} from './questions/question-dialogs/question-ouverte-dialog/question-ouverte-dialog.component';
import {QuestionTexteDialogComponent} from './questions/question-dialogs/question-texte-dialog/question-texte-dialog.component';
import {QuestionChoixMultipleDialogComponent} from './questions/question-dialogs/question-choix-multiple-dialog/question-choix-multiple-dialog.component';
import {QuestionCodeDialogComponent} from './questions/question-dialogs/question-code-dialog/question-code-dialog.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home';
import {LoginComponent} from './login';
import {AuthGuard, TokenGuard} from './guard';
import {NotFoundComponent} from './pages/not-found';
import {ChangePasswordComponent} from './change-password';
import {ForbiddenComponent} from './pages/forbidden';
import {SearchResultComponent} from './search-result';
import {NewQuestionComponent} from './questions/new-question';
import {SweetHomeComponent} from './home/sweet-home/sweet-home.component';
import {LoginInvitationComponent} from './login/login-invitation/login-invitation.component';
import {HomeInvitationComponent} from './home/home-invitation/home-invitation.component';
import {GateawayTimeoutComponent} from './pages/gateaway-timeout/gateaway-timeout.component';
import {UnauthorizedConnectionComponent} from './pages/unauthorized-connection/unauthorized-connection.component';
import {AdminDashboardComponent} from './admin/admin-dashboard/admin-dashboard.component';
import {QuestionnaireComponent} from './questionnaire';
import {QuestionsComponent} from './questions/questions.component';
import {InvitationsComponent} from './invitations/invitations.component';
import {RepondreTestComponent} from './repondre-test/repondre-test.component';
import {FinTestComponent} from './repondre-test/fin-test/fin-test.component';
import {ReviewTestComponent} from './review-test/review-test.component';
import {InvitationRoutes} from './constants/InvitationRoutes';
import {AppRoles} from './shared/utilities/AppRoles';

export const routes: Routes = [
  {
    path: '',
    component: SweetHomeComponent,
    pathMatch: 'full',
    data: {animation: 'HomePage'},
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
    data: {animation: 'LoginPage'},
  },
  {
    path: 'login-invitation',
    component: LoginInvitationComponent,
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: InvitationRoutes.candidat.home, // Page accueil pour passer le test
    component: HomeInvitationComponent,
    canActivate: [TokenGuard],
    data: {roles: [AppRoles.CANDIDAT]},
    pathMatch: 'full'
  },
  {
    path: InvitationRoutes.simulation.home, // Page accueil pour passer le test - simulation
    component: HomeInvitationComponent,
    canActivate: [AuthGuard],
    data: {roles: [AppRoles.ADMIN, AppRoles.RH, AppRoles.REDACTEUR]},
    pathMatch: 'full'
  },
  {
    path: 'token-invalide',
    component: HomeInvitationComponent,
    pathMatch: 'full'
  },
  {
    path: InvitationRoutes.candidat.repondre,
    component: RepondreTestComponent,
    canActivate: [TokenGuard],
    data: {roles: [AppRoles.CANDIDAT]},
    pathMatch: 'full'
  },
  {
    path: InvitationRoutes.simulation.repondre,
    component: RepondreTestComponent,
    canActivate: [AuthGuard],
    data: {roles: [AppRoles.ADMIN, AppRoles.RH, AppRoles.REDACTEUR]},
    pathMatch: 'full'
  },
  {
    path: InvitationRoutes.candidat.fin,
    component: FinTestComponent,
    canActivate: [TokenGuard],
    data: {roles: [AppRoles.CANDIDAT]},
    pathMatch: 'full'
  },
  {
    path: InvitationRoutes.simulation.fin,
    component: FinTestComponent,
    canActivate: [AuthGuard],
    data: {roles: [AppRoles.ADMIN, AppRoles.RH, AppRoles.REDACTEUR]},
    pathMatch: 'full'
  },
  {
    path: 'search-result',
    component: SearchResultComponent,
    canActivate: [AuthGuard],
    data: {roles: [AppRoles.ADMIN, AppRoles.RH]},
    pathMatch: 'full'
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: {roles: [AppRoles.ADMIN, AppRoles.RH, AppRoles.REDACTEUR]}
  },
  {
    path: 'admin',
    component: AdminDashboardComponent,
    canActivate: [AuthGuard],
    data: {roles: [AppRoles.ADMIN]},
    pathMatch: 'full'
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'new-question',
    component: NewQuestionComponent,
    canActivate: [AuthGuard],
    data: {roles: [AppRoles.ADMIN, AppRoles.RH, AppRoles.REDACTEUR]},
    pathMatch: 'full'
  },
  {
    path: 'updateCode/:id',
    component: QuestionCodeDialogComponent,
    canActivate: [AuthGuard],
    data: {roles: [AppRoles.ADMIN, AppRoles.RH, AppRoles.REDACTEUR]},
    pathMatch: 'full'
  },
  {
    path: 'updateChoixMultiple/:id',
    component: QuestionChoixMultipleDialogComponent,
    canActivate: [AuthGuard],
    data: {roles: [AppRoles.ADMIN, AppRoles.RH, AppRoles.REDACTEUR]},
    pathMatch: 'full'
  },
  {
    path: 'updateOuverte/:id',
    component: QuestionOuverteDialogComponent,
    canActivate: [AuthGuard],
    data: {roles: [AppRoles.ADMIN, AppRoles.RH, AppRoles.REDACTEUR]},
    pathMatch: 'full'
  },
  {
    path: 'updateTexte/:id',
    component: QuestionTexteDialogComponent,
    canActivate: [AuthGuard],
    data: {roles: [AppRoles.ADMIN, AppRoles.RH, AppRoles.REDACTEUR]},
    pathMatch: 'full'
  },
  {
    path: 'invitations',
    component: InvitationsComponent,
    canActivate: [AuthGuard],
    data: {animation: 'InvitationPage', roles: [AppRoles.ADMIN, AppRoles.RH]},
    pathMatch: 'full'
  },
  {
    path: 'questionnaire',
    component: QuestionnaireComponent,
    canActivate: [AuthGuard],
    data: {animation: 'QuestionnairePage', roles: [AppRoles.ADMIN, AppRoles.RH]},
    pathMatch: 'full'
  },
  {
    path: 'questions',
    component: QuestionsComponent,
    canActivate: [AuthGuard],
    data: {animation: 'QuestionPage', roles: [AppRoles.ADMIN, AppRoles.RH, AppRoles.REDACTEUR]},
    pathMatch: 'full'
  },
  {
    path: 'review-test/:id',
    component: ReviewTestComponent,
    canActivate: [AuthGuard],
    data: {animation: 'ReviewPage', roles: [AppRoles.ADMIN, AppRoles.RH]},
    pathMatch: 'full'
  },
  {
    path: '403',
    component: ForbiddenComponent
  },
  {
    path: '504',
    component: GateawayTimeoutComponent
  },
  {
    path: '401',
    component: UnauthorizedConnectionComponent
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
