import { Component, Inject, OnInit, ViewChild, Input } from '@angular/core';
import {
  InvitationService,
} from '../service/invitation.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NouveauInvitationComponent } from './nouveau-invitation/nouveau-invitation.component';
import { ConfirmActionDialogComponent } from '../component/confirm-action-dialog/confirm-action-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../shared/utilities/Utils';
import { NotyfToast } from '../shared/notify-toast/notify-toast.component';
import { cloneDeep } from 'lodash';
import { DOCUMENT } from '@angular/common';
import { SelectionType, ColumnMode } from '@swimlane/ngx-datatable';
import { finalize } from 'rxjs/operators';
import { Invitation } from '../models/invitation';
import { MatPaginator } from '@angular/material/paginator';
import { Questionnaire } from '../models/questionnaire';
import { QuestionnaireService } from '../service/questionnaire.service';
import { Constants } from '../shared/utilities/Constants';
import { AppTheme } from '../shared/utilities/AppTheme';
import { TranslateService } from '@ngx-translate/core';
import { TestCandidatService } from 'app/service/testcandidat.service';
import { TestCandidat } from 'app/models/testCandidat';
import { TestCandidatEntry } from 'app/models/testCandidatEntry';
import { ActivatedRoute } from '@angular/router';

interface Result {
  content: string;
  isVisible: boolean;
  invitation: Invitation;
}

/**
 * page principale de la gestion des invitations par le RH ou l'Admin
 */
@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.css'],
})
export class InvitationsComponent implements OnInit {
  public rowClassRules: any;
  public rowData: Invitation[];
  columnMode = ColumnMode;
  dataSource: any;

  private createdInvitation = undefined;
  appTheme = AppTheme;
  testCandidat: TestCandidat;

  showTable = true;
  loadingListOfInvitation = false;

  currentPage = 0;
  pageSize = 5;
  totalElements: number;

  phaseFilter: string;
  emailFilter: string;
  nomFilter: string;
  simulationFilter: boolean = undefined;
  prenomFilter: string;
  questionnaireFilter: string;
  sort: string;

  selectedRows = [];
  SelectionType = SelectionType;

  phasesList = [
    this.translate.instant('invitations.phaseStatus.onHold'),
    this.translate.instant('invitations.phaseStatus.notStarted'),
    this.translate.instant('invitations.phaseStatus.inProgress'),
    this.translate.instant('invitations.phaseStatus.finished'),
  ];
  isSimulationList = [
    { name: 'Invitation', value: false },
    { name: 'Simulation', value: true },
  ];

  questionnairesList: Questionnaire[];
  public results: Result[];
  private activatedRoute: ActivatedRoute;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() score: number;
  @Input() scoreSansPen: number;
  @Input() noteSansPen: number;

  constructor(
    @Inject(InvitationService) public invitationService: InvitationService,
    @Inject(QuestionnaireService) private questionnairesService: QuestionnaireService,
    @Inject(Router) private router: Router,
    @Inject(DOCUMENT) private document: Document,
    public matDialog: MatDialog,
    private toastr: ToastrService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute = activatedRoute;
    this.router = router;
    this.questionnairesService = questionnairesService;
    this.translate = translate;

    this.results = [];

    this.rowClassRules = {
      // row style function
      'bg-success text-white data-table-selected-row': function (params) {
        return (
          this.createdInvitation && params.data.id === this.createdInvitation.id
        );
      }.bind(this),
    };
  }

  ngAfterViewInit() {
    this.phaseFilter = this.activatedRoute.snapshot.params.filter || '';
    this.emailFilter= this.activatedRoute.snapshot.params.filter1 || '';
    this.nomFilter= this.activatedRoute.snapshot.params.filter2 || '';
    this.simulationFilter= this.activatedRoute.snapshot.params.filter3 || '';
    this.prenomFilter= this.activatedRoute.snapshot.params.filter4 || '';
    this.questionnaireFilter= this.activatedRoute.snapshot.params.filter5 || '';
    this.pageSize= this.activatedRoute.snapshot.params.filter6 || '';
  }

  async ngOnInit() {
    await this.questionnairesService
      .listOfQuestionnaires(0, Constants.INTEGER_MAX_VALUE)
      .toPromise()
      .then((response) => {
        this.questionnairesList = <Questionnaire[]>response.content;
      });
    await  this.paginator.firstPage();
    await this.getInvitationsList();
  }

  getRowClass = (row) => {
    return {
      'bg-success text-white data-table-selected-row':
        this.createdInvitation && row.id === this.createdInvitation.id,
    };
  };

  async getInvitationsList() {
    this.loadingListOfInvitation = true;

    await this.invitationService
      .getListeInvitations(
        this.currentPage,
        this.pageSize,
        this.simulationFilter,
        this.phaseFilter,
        this.emailFilter,
        this.nomFilter,
        this.prenomFilter,
        this.questionnaireFilter,
        this.sort
      )
      .toPromise()
      .then(
        (response) => {
          this.rowData = <Invitation[]>response.content;
          this.totalElements = response.totalElements;
          this.dataSource = new MatTableDataSource(this.rowData);

        },
        (error) => {
          this.toastr.warning(
            this.translate.instant('invitations.invitationsListError')
          );
        }
      );

    this.loadingListOfInvitation = false;
  }

  public onClick() {
    const createInvitationDialog = this.matDialog.open(
      NouveauInvitationComponent,
      { position: { top: '100px' } }
    );
    createInvitationDialog.afterClosed().subscribe((result) => {
      if (result && result.invitationCreated) {
        this.getInvitationsList();
        this.createdInvitation = result.newInvitation;

        setTimeout(() => {
          this.createdInvitation = undefined;

          Utils.removeSelectRowStyle();
        }, 40000);
      }
    });
  }

  /*
  public clickCheckbox() {
    const get_id = document.getElementById('ToggleswitchId') as HTMLInputElement;
    if (get_id.checked) {
      console.log('check')
    } else {
      console.log('uncheck');
    }
  }
*/

  public onDeleteRow() {
    const selectedData = this.selectedRows;

    let translateVars: any = {};
    translateVars = this.translate.instant('invitations.onDeleteInvitations');

    if (selectedData.length > 0) {
      let listids = '';

      for (let i = 0; i < selectedData.length; i++) {
        // tslint:disable-next-line: curly
        if (i > 0) {
          listids += ',';
        }

        listids += selectedData[i].id;
      }

      const deleteInvitationsDialog = this.matDialog.open(
        ConfirmActionDialogComponent,
        {
          data: {
            dialogTitle: translateVars.confirmationTitle,
            dialogMessage: translateVars.confirmationMsg,
          },
        }
      );

      deleteInvitationsDialog.afterClosed().subscribe((actionConfirmed) => {
        if (actionConfirmed) {
          this.loadingListOfInvitation = true;
          this.invitationService
            .supprimerInvitations(listids)
            .pipe(
              finalize(() => {
                this.loadingListOfInvitation = false;
              })
            )
            .toPromise()
            .then(
              (result) => {
                this.sendNotifSuccess(translateVars.successMsg);
                this.selectedRows = [];
                this.getInvitationsList();
              },
              (error) => {
                this.toastr.error(translateVars.errorMsg);
              }
            );
        }
      });
    } else {
      this.toastr.warning(translateVars.warningMsg);
    }
  }

  private sendNotifSuccess(message: string): void {
    const options = cloneDeep(this.toastr.toastrConfig);
    options.toastComponent = NotyfToast;
    options.toastClass = 'notyf confirm';
    this.toastr.success(message, null, options);
  }

  onSort(event: any) {
    this.sort = Utils.getSort(event);

    this.getInvitationsList();
  }

  setPage(pageInfo) {
    this.currentPage = pageInfo.pageIndex;
    this.pageSize = pageInfo.pageSize;
    this.applyFilterToResults();
    this.applyFilterToRoute();
    this.getInvitationsList();
  }

  onSelect({ selected }) {
    this.selectedRows.splice(0, this.selectedRows.length);
    this.selectedRows.push(...selected);
  }

  applyFilters() {
    this.paginator.pageIndex = 0;
    this.currentPage = 0;
    this.applyFilterToResults();
    this.applyFilterToRoute();
    this.getInvitationsList();
  }

  private applyFilterToResults(): void {
    const filter = this.emailFilter.toLowerCase();
    const filter1 = this.nomFilter.toLowerCase();
    const filter2 = this.prenomFilter.toLowerCase();

    for (const result of this.results) {
      result.isVisible = filter ? result.content.includes(filter) : true;
    }

    for (const result of this.results) {
      result.isVisible = filter1 ? result.content.includes(filter1) : true;
    }

    for (const result of this.results) {
      result.isVisible = filter2 ? result.content.includes(filter2) : true;
    }
  }

  private applyFilterToRoute(): void {
    this.router.navigate(
      [
        {
          filter: this.phaseFilter,
          filter1: this.emailFilter,
          filter2: this.nomFilter,
          filter3: this.simulationFilter,
          filter4: this.prenomFilter,
          filter5: this.questionnaireFilter,
          filter6: this.pageSize,
        },
      ],
      {
        relativeTo: this.activatedRoute,
        replaceUrl: true,
      }
    );
  }
  
}
