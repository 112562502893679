import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuestionnaireService } from '../../service/questionnaire.service';
import { finalize } from 'rxjs/operators';
import { QuestionnaireStats } from '../../models/QuestionnaireStats';
import { Utils } from '../../shared/utilities/Utils';
import { TranslateService } from '@ngx-translate/core';

export interface QuestionnaireStatsDialogData {
  questionnaireId: number;
}

@Component({
  selector: 'app-questionnaire-stats-dialog',
  templateUrl: './questionnaire-stats-dialog.component.html',
  styleUrls: ['./questionnaire-stats-dialog.component.scss'],
})
export class QuestionnaireStatsDialogComponent implements OnInit {
  questionnaireStats: QuestionnaireStats;
  loadingInitData = false;

  view: any[] = [400, 200];

  advancedPieChartData: any[];
  advancedPieChartColorScheme = {
    domain: ['#C7B42C', '#5AA454'],
  };

  barVerticalChartData: any[];
  barVerticalChartColorScheme = {
    domain: ['#F69E05', '#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
  };

  verticalChartData: any[];
  verticalChartColorScheme = {
    domain: ['#A10A28', '#F69E05', '#5AA454'],
  };

  constructor(
    @Inject(QuestionnaireService)
    private questionnaireService: QuestionnaireService,
    @Inject(MatDialogRef)
    private questionnaireStatsDialog: MatDialogRef<QuestionnaireStatsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QuestionnaireStatsDialogData,
    public translate: TranslateService
  ) {
    questionnaireStatsDialog.disableClose = true;
  }

  ngOnInit(): void {
    this.loadingInitData = true;
    this.questionnaireService
      .getQuestionnaireStats(this.data.questionnaireId)
      .pipe(
        finalize(() => {
          this.loadingInitData = false;
        })
      )
      .toPromise()
      .then((questionnaireStats) => {
        this.questionnaireStats = questionnaireStats;
        this.advancedPieChartData =
          Utils.buildAdvancedPieChartData(questionnaireStats);
        this.barVerticalChartData =
          Utils.buildBarVerticalChartData(questionnaireStats);
        this.verticalChartData =
          Utils.buildVerticalChartData(questionnaireStats);
      });
  }
}
