<div style="padding: 15px; margin: 5px; border-style: dashed;border-color: lightgrey; border-radius: 1em">


  <div style="padding: 15px; margin: 5px" *ngIf="entry.reponse">
    <div>
      <span>{{translate.instant('testReview.reviewCode.fields.sourceCode')}}</span>
      <div class="source-code">{{entry.reponse.sourceCode}}</div>
    </div>
    <div>
      <span>{{translate.instant('testReview.reviewCode.fields.executionResult')}}</span>
      <div class="result">{{entry.reponse.resultat}}</div>
    </div>
  </div>
  <div>
    <span>{{translate.instant('testReview.reviewCode.fields.waitingResult')}}</span>
    <div class="result">{{expectedResult}}</div>
  </div>
</div>
