export class CandidatReponse {
  questionId: number;

  // for QuestionLibre (QuestionOuverte)
  text?: string;
  // for QuestionChoixMultiple
  propositions?: number[]; // the ids of the propositions
  // for QuestionCode
  sourceCode?: string;
}
