<h2 mat-dialog-title>Execution du code</h2>
<mat-dialog-content>
  <p> {{ data.resultat }} </p>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-center">
  <button id="closeCodeResultAlert" class="mat-raised-button" (click)="close()" mat-raised-button color="warn">
    {{translate.instant('actions.close')}}
  </button>
</mat-dialog-actions>
