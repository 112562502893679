import {Component, OnInit, Input, Inject} from '@angular/core';
import { CandidatQuestion } from '../../models/candidatQuestion';
import { CandidatReponse } from '../../models/candidatReponse';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-repondre-question-ouverte',
  templateUrl: './repondre-question-ouverte.component.html',
  styleUrls: ['./repondre-question-ouverte.component.css']
})
export class RepondreQuestionOuverteComponent implements OnInit {

  @Input() question: CandidatQuestion;
  @Input() reponse?: CandidatReponse;
  @Input() formGroup?: FormGroup;

  isReady = false;

  constructor(@Inject(FormBuilder) private formBuilder: FormBuilder) { }

  ngOnInit() {
    if (!this.formGroup) {
      this.formGroup = this.formBuilder.group({});
    }
    this.formGroup.addControl('text', this.formBuilder.control(['']));
    if (!this.reponse) {
      this.reponse = new CandidatReponse();
      this.reponse.questionId = this.question.id;
    }
    this.isReady = true;
  }

  onChange($event) {
    this.reponse.text = $event.target.value;
  }
}
