<ngx-datatable class="material homeDataTable" [columnMode]="columnMode.force" [headerHeight]="50" [footerHeight]="50"
               [scrollbarH]="false" [rowHeight]="'auto'" [limit]="10" [rows]='rows'>

  <ngx-datatable-column [minWidth]="100" [maxWidth]="150" [sortable]="true">
    <ng-template ngx-datatable-header-template>Ref. {{translate.instant('fields.member')}}</ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>{{row.username}}</ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [minWidth]="100" [maxWidth]="250" [sortable]="true">
    <ng-template ngx-datatable-header-template let-sort="sortFn">
      <span (click)="sort()" class="cursor-pointer">{{translate.instant('fields.name')}}</span>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>{{row.name}}</ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [minWidth]="100" [maxWidth]="250" [sortable]="true">
    <ng-template ngx-datatable-header-template let-sort="sortFn">
      <span (click)="sort()" class="cursor-pointer">{{translate.instant('fields.firstName')}}</span>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>{{row.firstname}}</ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [minWidth]="100" [maxWidth]="250" [sortable]="true">
    <ng-template ngx-datatable-header-template let-sort="sortFn">
      <span (click)="sort()" class="cursor-pointer">{{translate.instant('fields.profile')}}</span>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>{{row.profile}}</ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column cellClass='datatable-body-cell-action'>
    <ng-template let-membre="row" ngx-datatable-cell-template>
      <button mat-icon-button class="mat-primary" (click)="editMembre(membre)">
        <mat-icon matTooltip="{{translate.instant('actions.edit')}}"
                  matTooltipPosition="above">{{translate.instant('actions.edit')}}</mat-icon>
      </button>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column cellClass='datatable-body-cell-action'>
    <ng-template let-membre="row" ngx-datatable-cell-template>
      <button mat-icon-button class="mat-primary" (click)="deleteMembre(membre, rows)">
        <mat-icon matTooltip="{{translate.instant('actions.delete')}}"
                  matTooltipPosition="above">{{translate.instant('actions.delete')}}</mat-icon>
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
