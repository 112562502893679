<div appBlockCopyPaste class="content" fxLayout="row" fxLayoutAlign="center">

  <div *ngFor="let question of questions; let i = index">
    <mat-card>
      <mat-card-header>
        <div *ngIf="question.type=='code'" mat-card-avatar
             [class]="utils.getQuestionCodeChosenLanguage(question.technologie, true, false)"></div>
        <mat-card-title>{{ invitationService.invitation.testcandidat.questionnaire.nom }}</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <app-data-loading-spinner [loadingData]="loadingInitData"></app-data-loading-spinner>
        <form id="formTest" #testForm="ngForm">
          <div  *ngIf="!submitted">

            <chrono style="position: fixed; right: 950px; top: 150px; z-index: 10;" [value]="remainingDurations[i]" [baseValue]="question.duration"
                    *ngIf="question.type !== 'texte'"></chrono>

            <div>
              <span>{{question.rank}} ) </span>
              <span [innerHTML]="question.enonce"></span>
            </div>
            <br/>

            <app-repondre-question-choix-multiple *ngIf="question.type=='choix multiple'"
                                                  [question]="question" [reponse]="reponses[i]"
                                                  [formGroup]="questionsFormArray.at(i)"></app-repondre-question-choix-multiple>

            <app-repondre-question-ouverte *ngIf="question.type=='libre'"
                                           [question]="question" [reponse]="reponses[i]"
                                           [formGroup]="questionsFormArray.at(i)"></app-repondre-question-ouverte>

            <app-repondre-question-code *ngIf="question.type=='code'"
                                        [question]="question" [reponse]="reponses[i]"
                                        [formGroup]="questionsFormArray.at(i)"
                                        [token]="token"></app-repondre-question-code>
          </div>
        </form>
      </mat-card-content>

      <mat-action-list class="d-flex justify-content-center">
        <button type="submit" mat-raised-button [disabled]="!testForm.valid || loadingInitData || submitted"
                (click)="onSubmit()"
                color="primary">
            <span *ngIf="submitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
            </span>
          {{submitButtonLabel}}
        </button>
      </mat-action-list>
    </mat-card>
  </div>
</div>
