export interface LabelValue {
  label: string,
  value: string
}

export class Constants {
  public static readonly INTEGER_MAX_VALUE = 2147483647;
  public static readonly QUESTION_TYPES: LabelValue[] = [
    {label: 'Choix multiple', value: 'choix multiple'},
    {label: 'Code', value: 'code'},
    {label: 'Libre', value: 'libre'},
    {label: 'Texte', value: 'texte'}];

  public static readonly RICH_TEXT_EDITOR_TOOLS: object = {
    items: ['Undo', 'Redo', '|',
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'SubScript', 'SuperScript', '|',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink', 'CreateTable',
      'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
  };

  public static readonly RICH_TEXT_EDITOR_QUICK_TOOLS: object = {
    image: [
      'Replace', 'Align', 'Caption', 'Remove', 'InsertLink', '-', 'Display', 'AltText', 'Dimension']
  };
}
