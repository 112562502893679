
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {UserService} from '../../service';


@Injectable()
export class QueriesInterceptor implements HttpInterceptor {
  constructor(private router: Router,
              private userService: UserService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: false,
      setHeaders: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true'
      }
    });

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // Service Response thr Interceptor
      }
    }, (error: any) => {
      if (error instanceof HttpErrorResponse) {
        if ((error.status === 401 && this.userService.currentUser) || error.url.includes('login')) {
          this.userService.currentUser = undefined;
          location.href = '/#/login';
        }
      }
    }));
  }
}
