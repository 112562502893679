import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {InvitationService} from 'app/service/invitation.service';
import {Invitation} from 'app/models/invitation';
import {Candidat} from 'app/models/candidat';
import {TestCandidatService} from 'app/service/testcandidat.service';
import {TestCandidat} from 'app/models/testCandidat';
import {TestCandidatEntry} from 'app/models/testCandidatEntry';
import {mergeMap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import { Location } from '@angular/common';

class NumberedEntries {
  data: TestCandidatEntry;
  displayNumber = 0;

  constructor(data: TestCandidatEntry) {
    this.data = data;
  }
}

@Component({
  selector: 'app-review-test',
  templateUrl: './review-test.component.html',
  styleUrls: ['./review-test.component.css']
})
export class ReviewTestComponent implements OnInit {

  private invitationId: string;
  candidat: Candidat;
  entries: NumberedEntries[];
  testCandidat: TestCandidat;
  errorMessage: string;
  lowTimePercent = 10; // for QuestionCodes: detect response time less that percentage of allowed question time

  constructor(@Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
              @Inject(Router) private router: Router,
              @Inject(InvitationService) private invitationService: InvitationService,
              @Inject(TestCandidatService) private testCandidatService: TestCandidatService,
              public translate: TranslateService,
              private location: Location) {
  }

  ngOnInit() {
    this.invitationId = this.activatedRoute.snapshot.params['id'];
    this.invitationService.getInvitationFromId(this.invitationId).pipe(
      mergeMap((invitation: Invitation) => {
        this.candidat = invitation.candidat;
        return this.testCandidatService.getTestCandidatFull(invitation.testcandidat.id);
      })
    ).subscribe((testcandidat: TestCandidat) => {
        this.testCandidat = testcandidat;
        if (this.testCandidat && this.testCandidat.entries) {
          this.entries = this.orderedEntries(testcandidat.entries);
        }
      },
      err => {
        this.errorMessage = this.translate.instant('testReview.initDataError') + err.status + ')'
      });
  }

  private orderedEntries(testCandidatEntries: TestCandidatEntry[]): NumberedEntries[] {
    const ordered = [];
    for (const testCandidatEntry of testCandidatEntries) {
      ordered.push(new NumberedEntries(testCandidatEntry));
    }
    ordered.sort((e1: NumberedEntries, e2: NumberedEntries) => e1.data.rank - e2.data.rank);
    let displayNumber = 1;
    for (const e of ordered) {
      if (e.data.question.type !== 'texte') {
        e.displayNumber = displayNumber++;
      }
    }
    return ordered;
  }

  onNewReview(evt, entry: TestCandidatEntry): void {
    entry.review = evt;
    // we could reload from the server to have an updated testcandidat, but we'll try to recompute instead
    this.recomputeScore();
  }

  private recomputeScore() {
    if (this.testCandidat && this.testCandidat.entries) {
      // New score
      this.testCandidat.score = this.testCandidat.entries
        .map(entry => entry.review && entry.review.score != null ? entry.review.score
          : entry.score != null ? entry.score : 0)
        .reduce((prev: number, curr: number) => prev + curr);

      // Vew note
      this.testCandidat.note = this.testCandidat.maxScore === 0 ? 0 : this.testCandidat.score / this.testCandidat.maxScore * 100;
      this.testCandidat.noteSansPen = this.testCandidat.maxScore === 0 ? 0 : this.testCandidat.score / this.testCandidat.maxScore * 100;

    }
  }

  getResponseTime(entry: TestCandidatEntry): Date {
    if (entry.displayTime && entry.submitTime) {
      return new Date(new Date(entry.submitTime).valueOf() - new Date(entry.displayTime).valueOf());
    } else {
      return undefined;
    }
  }

  // is the response time less than lowTimePercent% of the estimated duration? (QuestionCode only)
  suspiciouslyFast(entry: TestCandidatEntry): boolean {
    return (entry.question.type === 'code' && entry.displayTime && entry.submitTime && entry.question.duration > 0
      && (new Date(entry.submitTime).valueOf() - new Date(entry.displayTime).valueOf())
      / (entry.question.duration * 1000) < (this.lowTimePercent / 100));
  }

  /*
  btnClick = function () {
    this.location.back();
  };
*/
  btnClick() {
    if (window.history.length > 1) {
      this.location.back()
    } else {
      this.router.navigate(['/invitations'])
    }
  }

  get suspiciouslyFastTooltip(): string {
    const suspiciouslyFastTranslateVar = this.translate.instant('testReview.suspiciouslyFast');
    return suspiciouslyFastTranslateVar.start + this.lowTimePercent + suspiciouslyFastTranslateVar.end;
  }
}
