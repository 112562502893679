import {finalize, map, multicast, switchMap, takeUntil} from 'rxjs/operators';
import {MembreAptea} from '../models/membreAptea';
import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {DisplayMessage} from '../shared/models/display-message';
import {fromEvent, merge, Observable, Observer, Subject, interval, ConnectableObservable} from 'rxjs';
import {
  UserService,
  AuthService
} from '../service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  form: FormGroup;
  authenticatedMember: MembreAptea;

  private currentSelectedInput: string;
  /**
   * Boolean used in telling the UI
   * that the form has been submitted
   * and is awaiting a response
   */
  submitted = false;

  /**
   * Notification message from received
   * form request or router
   */
  notification: DisplayMessage;

  returnUrl: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  // For HTTP authentication progress
  authenticating = false;

  // To hide or show password field
  hide = true;

  constructor(
    private toastr: ToastrService,
    @Inject(UserService) private userService: UserService,
    @Inject(AuthService) private authService: AuthService,
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    public translate: TranslateService) {
  }

  ngOnInit() {
    if (this.userService.currentUser) {
      this.router.navigate(['home']);
    }
    this.activatedRoute.params.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((params: DisplayMessage) => {
        this.notification = params;
      });
    // get return url from activatedRoute parameters or default to '/'
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/home';
    this.form = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(64)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(64)])]
    });
  }


  ngOnDestroy() {

  }


  checkBrowserStatus() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

  checkBrowserStatusPeriodicallyThenMultiCast() {
    return interval(1000).pipe(
      switchMap(_ => {
        return this.checkBrowserStatus()
      }),
      multicast(() => new Subject())
    ) as ConnectableObservable<boolean>;
  }

  setFrozenState(): void {

  }

  onSubmit() {
    /**
     * Innocent until proven guilty
     */
    this.notification = undefined;
    this.submitted = false;
    this.authenticating = true;

    this.authService.loginThroughLdap(this.form.value).pipe(
      finalize(() => {
        this.authenticating = false;
      })
    ).subscribe((data: MembreAptea) => {
        this.userService.currentUser = data;
        this.submitted = true;
        const connexionChecker = this.checkBrowserStatusPeriodicallyThenMultiCast();
        connexionChecker.subscribe(isOnline => {
          if (!this.userService || !this.userService.currentUser) {
            return;
          }
          this.userService.currentUser.isOnline = isOnline
        });
        connexionChecker.connect();

        this.router.navigate([this.returnUrl]);
      },
      error => {
        this.notification = {msgType: 'error', msgBody: this.translate.instant('login.loginError')};
        this.toastr.error(this.notification.msgBody);
      });
  }
}
