import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

export interface MajorMinorUpdateDialogData {
  dialogTitle: string;
  dialogMessage: string;
  action: string;
}

@Component({
  selector: 'app-major-minor-update-action-dialog',
  templateUrl: './major-minor-update-action-dialog.component.html',
  styleUrls: ['./major-minor-update-action-dialog.component.scss']
})
export class MajorMinorUpdateDialogComponent implements OnInit {

  constructor(@Inject(MatDialogRef) private updateActionDialogRef: MatDialogRef<MajorMinorUpdateDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: MajorMinorUpdateDialogData,
              public translate: TranslateService) {
              
              }

  ngOnInit(): void {
  }

  async majorUpdate() {
    this.updateActionDialogRef.close({action: "major"});
  }

  async minorUpdate() {
    this.updateActionDialogRef.close({action: "minor"});
  }

  onClose(): void {
    this.updateActionDialogRef.close();
  }

}
