import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable()
export class ConfigService {

  private _base = environment.baseApiPath;

  private _api_url = this._base + '/api';
  private _api_url_ldap = this._base + '/ldap/api';

  private _login_url = this._api_url + '/login';

  private _build_url = this._api_url + '/build';
  private _version_url = this._build_url + '/version';
  private _datebuild_url = this._build_url + '/date';

  private _current_membre_url = this._api_url + '/membres/current';

  private _login_url_ldap = this._api_url_ldap + '/login';

  private _logout_url = this._api_url_ldap + '/logout';

  private _change_password_url = this._api_url + '/changePassword';

  private _signup_url = this._api_url + '/signup';

  // Membre
  private _list_membre_aptea = this._api_url + '/membres';

  // Question
  private _questions_url = this._api_url + '/questions';

  private _list_of_questions_from_membre = this._questions_url;

  private _question_response_ouverte = this._questions_url + '/ouverte';

  private _question_reponse_choix_multiple = this._questions_url + '/choixMultiple';

  private _question_reponse_choix_multiple_maj = this._questions_url + '/choixMultipleMaj';

  private _question_reponse_code = this._questions_url + '/code';

  private _question_reponse_texte = this._questions_url + '/texte';

  private _question_stats = this._questions_url + '/{id}/stats';

  private _list_of_all_questions = this._questions_url;

  private _delete_question = this._questions_url;

  private _validate_question = this.questions_url + '/{id}/validate';

  private _execute_code = this._questions_url + '/execute/code';

  // Categorie
  private _categories_url = this._api_url + '/categories';

  // Questionnaire
  private _questionnaires_url = this._api_url + '/questionnaires';

  // Invitation
  private _invitations_url = this._api_url + '/invitations';

  private _list_of_invitations = this._invitations_url;

  private _invitation_authenticate_url = this._invitations_url + '/search-by-mail?mailAdresse=';

  private _search_emails = this._invitations_url + '/search-candidates-by-email?mailAdresse=';

  private _creerInvitation = this._invitations_url;

  private _createSimulationInvitation = this._invitations_url + '/simulation';

  private _getInvitationValide = this._invitations_url + '/valide?token=';

  private _getInvitation = this._invitations_url + '/search-by-token';

  private _getScorePDFData = this._invitations_url + '/{id}/scoreReport';

  // TestCandidat
  private _testCandidats_url = this._api_url + '/testCandidats';

  private _candidatTestStart = this._testCandidats_url + '/start?token={token}';

  private _candidatTestQuestions = this._testCandidats_url + '/questions?token={token}';

  private _candidatTestReponses = this._testCandidats_url + '/reponses?token={token}';

  private _candidatTestCurrentQuestion = this._testCandidats_url + '/questions/current?token={token}';

  private _candidatTestCurrentReponse = this._testCandidats_url + '/reponses/current?token={token}';

  private _candidatTestExecuteCode = this._testCandidats_url + '/execute?token={token}';

  private _finTest = this._testCandidats_url + '/fin';

  private _testCandidatFull_url = this._api_url + '/testCandidatReview';

  private _majInvitation = this._testCandidats_url + '/maj/{id}/{firstname}/{name}/{xp}';

  private _testCandidatFullById = this._testCandidatFull_url + '/{id}';

  private _testCandidatFullReponseReview = this._testCandidatFull_url + '/{testCandidatId}/question/{testCandidatEntryId}/review';
  
  get current_member_url(): string {
    return this._current_membre_url;
  }

  get version_url(): string {
    return this._version_url;
  }

  get datebuild_url(): string {
    return this._datebuild_url;
  }

  get login_url(): string {
    return this._login_url;
  }

  get login_url_ldap(): string {
    return this._login_url_ldap;
  }

  get logout_url(): string {
    return this._logout_url;
  }

  get change_password_url(): string {
    return this._change_password_url;
  }

  get signup_url(): string {
    return this._signup_url;
  }

  get search_result_url(): string {
    return this._list_membre_aptea;
  }

  get invitation_authenticate(): string {
    return this._invitation_authenticate_url;
  }

  get questions_url(): string {
    return this._questions_url;
  }

  get question_response_ouverte(): string {
    return this._question_response_ouverte;
  }

  get question_response_choix_multiple(): string {
    return this._question_reponse_choix_multiple;
  }

  get question_response_choix_multiple_maj(): string {
    return this._question_reponse_choix_multiple_maj;
  }

  get question_response_code(): string {
    return this._question_reponse_code;
  }

  get question_response_texte(): string {
    return this._question_reponse_texte;
  }

  get question_stats(): string {
    return this._question_stats;
  }

  get listOfQuestionsFromMembre(): string {
    return this._list_of_questions_from_membre;
  }

  get searchEmails(): string {
    return this._search_emails;
  }

  get listOfAllQuestions(): string {
    return this._list_of_all_questions;
  }

  get deleteQuestion(): string {
    return this._delete_question;
  }

  get validateQuestion(): string {
    return this._validate_question;
  }

  get executeCode(): string {
    return this._execute_code;
  }

  get listMembreAptea(): string {
    return this._list_membre_aptea;
  }

  get creerInvitation(): string {
    return this._creerInvitation;
  }

  get createSimulationInvitation(): string {
    return this._createSimulationInvitation;
  }

  get majFirstNameAndName(): string {
    return this._majInvitation;
  }

  get testCandidatFullById(): string {
    return this._testCandidatFullById;
  }

  get testCandidatFullReponseReview(): string {
    return this._testCandidatFullReponseReview;
  }

  get candidatTestStart(): string {
    return this._candidatTestStart;
  }

  get candidatTestQuestions(): string {
    return this._candidatTestQuestions;
  }

  get candidatTestReponses(): string {
    return this._candidatTestReponses;
  }

  get candidatTestCurrentQuestion(): string {
    return this._candidatTestCurrentQuestion;
  }

  get candidatTestCurrentReponse(): string {
    return this._candidatTestCurrentReponse;
  }

  get candidatTestExecuteCode(): string {
    return this._candidatTestExecuteCode;
  }

  get finTest(): string {
    return this._finTest;
  }

  get getInvitation(): string {
    return this._getInvitation;
  }

  get getInvitationValide(): string {
    return this._getInvitationValide;
  }

  get getScorePDFData(): string {
    return this._getScorePDFData;
  }

  get listOfInvitations(): string {
    return this._list_of_invitations;
  }

  get questionnaires_url(): string {
    return this._questionnaires_url;
  }

  get categories_url(): string {
    return this._categories_url;
  }
}
