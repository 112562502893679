import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';


export interface DialogData {
  resultat: string;
}

@Component({
  selector: 'app-execute-code-dialog',
  templateUrl: './execute-code-dialog.component.html',
  styleUrls: ['./execute-code-dialog.component.css']
})
export class ExecuteCodeDialogComponent implements OnInit {

  constructor(
    @Inject(MatDialogRef) public dialogRef: MatDialogRef<ExecuteCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public translate: TranslateService) {
  }

  ngOnInit() {

  }

  close() {
    this.dialogRef.close();
  }
}
