import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bouton-tool',
  templateUrl: './bouton-tool.component.html',
  styleUrls: ['./bouton-tool.component.css']
})
export class BoutonToolComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
