import { Component, Inject, OnInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuestionService } from 'app/service/question.service';
import { TranslateService } from '@ngx-translate/core';
import { QuestionnaireService } from '../../service/questionnaire.service';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { Question } from 'app/models/question';
import { finalize } from 'rxjs/operators';
import { ReviewTestComponent } from 'app/review-test/review-test.component';
import {CandidatQuestion} from 'app/models/candidatQuestion';
import {CandidatReponse} from 'app/models/candidatReponse';


export interface QuestionPreviewDialogData {
  question: Question;
}

@Component({
  selector: 'app-question-preview-dialog',
  templateUrl: './question-preview-dialog.component.html',
  styleUrls: ['./question-preview-dialog.component.scss'],
})
export class QuestionPreviewDialogComponent implements OnInit {
  /*token = '';
  remainingDurations: number[];
  submitted = false;*/
  form: FormGroup;
  @Input() question: Question;


  view: any[] = [400, 200];

  constructor(
    // @Inject(QuestionService)
    // private questionService: QuestionService,
    @Inject(MatDialogRef)
    private questionPreviewDialog: MatDialogRef<QuestionPreviewDialogComponent>,
    // @Inject(QuestionnaireService)
    // private questionnaireService: QuestionnaireService,
    @Inject(MAT_DIALOG_DATA) public data: QuestionPreviewDialogData,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
  ) {
    questionPreviewDialog.disableClose = true;
  }

  ngOnInit(): void {
    /*this.questionService;
    this.questionDialog;
    this.questionnaireService
      .getQuestionsByQuestionnaire(this.data.questionId)
      .pipe(
        finalize(() => {
        })
      )
      .toPromise()
      .then((questionDialog) => {
        this.questionDialog = questionDialog;
      });*/
      this.question = this.data.question;
  }

  close() {
    this.questionPreviewDialog.close(true);
 }

}
