<div style="padding: 15px; margin: 5px; border-style: dashed;border-color: lightgrey; border-radius: 1em">

  <div style="padding: 15px; margin: 5px" *ngIf="qcmPropositions">
    <div *ngFor="let prop of qcmPropositions; let i = index">
      <label [innerHTML]="prop.proposition.description">
      </label>
      <i style="margin-left: 10px" class="fas fa-user-check"
         [ngClass]="{'red':prop.correct !== prop.selected,'green':prop.correct === prop.selected}"
         *ngIf="prop.selected">
          <mat-icon style="margin-left: 10px" [inline]="true" [ngStyle]="{'color':'black'}" matTooltip="Réponse SELECTIONNEE par le candidat VERT=Réponse CORRECTE & ROUGE=Réponse INCORRECTE">
            info_outline
          </mat-icon>
        &nbsp;
      </i>
      <i style="margin-left: 10px" class="fas fa-user-times"
         [ngClass]="{'red':prop.correct !== prop.selected,'green':prop.correct === prop.selected}"
         *ngIf="!prop.selected">
          <mat-icon style="margin-left: 10px" [inline]="true" [ngStyle]="{'color':'black'}" matTooltip="Réponse NON SELECTIONNEE par le candidat VERT=Réponse CORRECTE & ROUGE=Réponse INCORRECTE">
            info_outline
          </mat-icon>
         &nbsp;
      </i>
    </div>
  </div>
</div>
