import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SearchService} from '../service';
import {Router, ActivatedRoute} from '@angular/router';
import {MembreAptea} from '../models/membreAptea';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent implements OnInit {

  public form: FormGroup;
  public returnUrl: string;
  public membres: MembreAptea[] = [];

  constructor(
    @Inject(SearchService) private searchService: SearchService,
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    public translate: TranslateService) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.searchService.getSearch()
      .subscribe(membres => this.membres = membres);
    /*.subscribe(data => {
      this.router.navigate([this.returnUrl]);
    });*/
  }

}
