import { Categorie } from './categorie';
import { MembreAptea } from './membreAptea';

export class Question {
  id: number;
  version: number; // don't modify. used for optimistic locking.
  titre: string;
  type: string;
  enonce: string;
  membre: MembreAptea;
  passes : number;
  stat : number;
  duration: number;
  difficulty: number;
  validated = false;
  questionCategories: Categorie[];
  currentRevision?: number;
}
