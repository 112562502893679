import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';

@Injectable()
export class AppLanguage {

  private readonly _FR = 'fr';
  private readonly _EN = 'en';

  private readonly supportedLanguages = [this.FR, this.EN];
  private _globalAppLanguage: string;

  constructor(public _translate: TranslateService) {
    this.initAppLanguage();
  }

  get globalAppLanguage(): string {
    return this._globalAppLanguage;
  }

  set globalAppLanguage(value: string) {
    this._globalAppLanguage = value;
  }

  get translate(): TranslateService {
    return this._translate;
  }

  get FR(): string {
    return this._FR;
  }

  get EN(): string {
    return this._EN;
  }

  set translate(value: TranslateService) {
    this._translate = value;
  }

  private initAppLanguage() {
    this._translate.addLangs(this.supportedLanguages);
    this._translate.setDefaultLang(this.FR);

    const browserLang = this.translate.getBrowserLang();
    const storedLanguage = localStorage.getItem('appLanguage');
    this.globalAppLanguage = this.supportedLanguages.includes(storedLanguage) ? storedLanguage :
      (this.supportedLanguages.includes(browserLang) ? browserLang : this.EN);

    this._translate.use(this.globalAppLanguage);
  }

  public changeAppLanguage(language: string) {
    if (this.supportedLanguages.includes(language)) {
      localStorage.setItem('appLanguage', language);
      this.globalAppLanguage = language;
      this._translate.use(this.globalAppLanguage);
    }
  }
}
