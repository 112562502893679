import {map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ConfigService} from './config.service';
import {Question} from '../models/question';
import {QuestionLibre} from '../models/question.libre';
import {QuestionCode} from '../models/question.code';
import {QuestionChoixMultiple} from '../models/question.choix.multiple';
import {JdoodleDataOutput} from '../models/jdoodle.data.output';
import {ToastrService} from 'ngx-toastr';
import {QuestionStats} from '../models/QuestionStats';

@Injectable()
export class QuestionService {

  // les variables suivantes sont utilisées dans tous les components où une liste de questions est sélectionnable
  // (création/édition de questions et de formulaires...)
  public allQuestions: Array<Question> = []; // toutes les questions de la BDD
  public questionsActuelle: Array<Question> = []; // questions actuellement sélectionnées localement pour ce questionnaire
  dropdownQuestionsSettings = {};


  constructor(@Inject(HttpClient) private httpClient: HttpClient, @Inject(ConfigService) private config: ConfigService,
              private toastr: ToastrService) {

    this.dropdownQuestionsSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'titre',
      selectAllText: 'Toutes',
      unSelectAllText: 'Aucune',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

  }

  // ces fonctions de reset adaptent les données du service à la page actuelle.
  // Elles doivent être appelées au chargement d'une page d'édition de questionnaire ou question
  resetDataPourQuestionnaire(id) {
    this.resetData(0, 0, true);

    this.getQuestionsByQuestionnaire(id).toPromise().then(questions => {
      this.questionsActuelle = questions;
    });
  }

  /* set validOnly to limit to questions that have been validated at least once before */
  async resetData(page?: number, size?: number, validOnly?: boolean) {
    this.questionsActuelle = [];
    this.allQuestions = [];

    if (size > 0) {
      await this.getAllQuestions(page, size, validOnly).toPromise().then(questionsList => {
        this.allQuestions = questionsList.content;
      }, error1 => {
        this.toastr.error('Erreur !');
      });
    }
  }

  addQuestionResponseOuverte(question: QuestionLibre) {

    return this.httpClient.post<Question>(this.config.question_response_ouverte, question
    ).pipe(map(questionObject => {
      return questionObject;
    }));
  }

  updateQuestionLibre(questionLibre: QuestionLibre) {

    return this.httpClient.post<Question>(this.config.question_response_ouverte, questionLibre
    ).pipe(map(questionObject => {
      return questionObject;
    }));
  }

  addQuestionResponseTexte(question: Question) {

    return this.httpClient.post<Question>(this.config.question_response_texte, question
    ).pipe(map(questionObject => {
      return questionObject;
    }));
  }

  updateQuestionTexte(question: Question) {
    return this.httpClient.post<Question>(this.config.question_response_texte, question
    ).pipe(map(questionObject => {
      return questionObject;
    }));
  }

  addQuestionResponseChoixMultiple(question: QuestionChoixMultiple) {
    return this.httpClient.post<QuestionChoixMultiple>(this.config.question_response_choix_multiple, question
    ).pipe(map(questionObject => {
      return questionObject;
    }));
  }

  updateQuestionChoixMultiple(questionChoixMutltiple: QuestionChoixMultiple) {

    return this.httpClient.post<QuestionChoixMultiple>(this.config.question_response_choix_multiple, questionChoixMutltiple
    ).pipe(map(questionObject => {
      return questionObject;
    }));
  }

  updateQuestionChoixMultipleMaj(questionChoixMutltiple: QuestionChoixMultiple) {

    return this.httpClient.post<QuestionChoixMultiple>(this.config.question_response_choix_multiple_maj, questionChoixMutltiple
    ).pipe(map(questionObject => {
      return questionObject;
    }));
  }

  addQuestionResponseCode(questionCode: QuestionCode) {

    return this.httpClient.post<QuestionCode>(this.config.question_response_code, questionCode
    ).pipe(map(questionObject => {
      return questionObject;
    }));
  }

  updateQuestionCode(questionCode: QuestionCode) {

    return this.httpClient.post<QuestionCode>(this.config.question_response_code, questionCode
    ).pipe(map(questionObject => {
      return questionObject;
    }));
  }

  listOfQuestionFromMembre(page?: number, size?: number, titre?: string, type?: string, 
    category?: number, sort?: string): Observable<any> {

    let parameters = new HttpParams().append('page', String(page)).append('size', String(size));

    if (titre) {
      parameters = parameters.append('titre', encodeURIComponent(titre));
    }

    if (type) {
      parameters = parameters.append('type', type);
    }

    if (category) {
      parameters = parameters.append('categorie', category.toString());
    }

    if (sort) {
      parameters = parameters.append('sort', sort);
    }

    const options = {
      params: parameters
    };

    return this.httpClient.get<any>(this.config.listOfQuestionsFromMembre, options);
  }

  /* set validOnly to limit to questions that have been validated at least once before */
  listOfQuestionFromCategories(categoriesId: number[], validOnly?: boolean): Observable<Question[]> {
    const options = {
      params: new HttpParams().append('validOnly', validOnly ? 'true' : 'false')
    };
    return this.httpClient.get<Question[]>(this.config.categories_url + '/' + categoriesId + '/questions', options);
  }

  deleteQuestion(question): Observable<Question> {
    const options = {headers: new HttpHeaders().append('If-Match', '' + question.version)};
    return this.httpClient.delete<Question>(this.config.deleteQuestion + '/' + question.id, options);
  }

  /* set validOnly to limit to questions that have been validated at least once before */
  getAllQuestions(page?: number, size?: number, validOnly?: boolean): any {
    const options = {
      params: new HttpParams().append('page', String(page))
        .append('size', String(size))
        .append('validOnly', validOnly ? 'true' : 'false')
    };
    return this.httpClient.get<Question[]>(this.config.listOfAllQuestions, options);
  }

  getQuestionLibre(id: number): Observable<QuestionLibre> {
    return this.httpClient.get<QuestionLibre>(this.config.question_response_ouverte + '/' + id);
  }

  getQuestionTexte(id: number): Observable<Question> {
    return this.httpClient.get<QuestionLibre>(this.config.question_response_texte + '/' + id);
  }

  getQuestionCode(id: number): Observable<QuestionCode> {
    return this.httpClient.get<QuestionCode>(this.config.question_response_code + '/' + id);
  }

  getQuestionChoixMultiple(id: number): Observable<QuestionChoixMultiple> {
    return this.httpClient.get<QuestionChoixMultiple>(this.config.question_response_choix_multiple + '/' + id);
  }

  getQuestionsByQuestionnaire(id: number): Observable<Question[]> {
    return this.httpClient.get<Question[]>(this.config.questionnaires_url + '/' + id + '/questions');
  }

  executeCode(code, technologie): Observable<JdoodleDataOutput> {
    return this.httpClient.post<JdoodleDataOutput>(this.config.executeCode, {
      code: code,
      technologie: technologie.technologie
    });
  }

  validateQuestion(id: number, version: number, validated: boolean) {
    const options = {
      headers: new HttpHeaders().append('If-Match', '' + version),
      params: new HttpParams().append('v', validated ? 'true' : 'false')
    };
    // @ts-ignore
    return this.httpClient.patch<void>(this.config.validateQuestion.replace('{id}', id.toString()), null, options);
  }

  getQuestionStats(id): Observable<QuestionStats> {
    return this.httpClient.get<QuestionStats>(this.config.questions_url + '/' + id + '/stats')
  }

}
