import {Component, OnInit, Input, Output, EventEmitter, Inject, AfterViewInit, ElementRef, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-api-card',
  templateUrl: './api-card.component.html',
  styleUrls: ['./api-card.component.scss']
})
export class ApiCardComponent {

  @Input() title: string;
  @Input() subTitle: string;
  @Input() imgUrl: string;
  @Input() content: string;
  @Input() apiText: string;
  @Input() responseObj: any;
  @Input() url: string;
  expand = false;

  constructor(
    @Inject(Router) private router: Router
  ) {
  }

  onButtonClick() {
    this.expand = true;
    this.router.navigate([this.url]);
  }


}
