import { Component, OnInit, Input } from '@angular/core';
import { QuestionChoixMultiple } from 'app/models/question.choix.multiple';
import { Proposition } from 'app/models/proposition';
import { TestCandidatEntry } from 'app/models/testCandidatEntry';

class QCMProposition {
  proposition: Proposition;
  correct: boolean;
  selected: boolean;

  constructor(proposition: Proposition, correct: boolean, selected: boolean) {
    this.proposition = proposition;
    this.correct = correct;
    this.selected = selected;
  }
}

@Component({
  selector: 'app-review-question-choix-multiple',
  templateUrl: './review-question-choix-multiple.component.html',
  styleUrls: ['./review-question-choix-multiple.component.css']
})
export class ReviewQuestionChoixMultipleComponent implements OnInit {

  @Input() testCandidatId: number;
  @Input() entry: TestCandidatEntry;

  qcmPropositions: QCMProposition[];

  constructor() { }

  ngOnInit(): void {
    this.qcmPropositions = (this.entry.question as QuestionChoixMultiple).propositions
      .map(p => new QCMProposition(p, p.correct,
        !!this.entry.reponse.propositions &&
        !!this.entry.reponse.propositions.find((pId) => pId === p.id)))
      .sort((n1, n2) => n1.proposition.order - n2.proposition.order);
  }
}
