import { Router } from '@angular/router';

import { MembreAptea } from '../models/membreAptea';
import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { UserService } from '../service';
import { QuestionnaireService } from '../service/questionnaire.service';

import { ViewEncapsulation } from '@angular/core';
import { AuthService } from '../service';
import { MatTableDataSource } from '@angular/material/table';
import { Questionnaire } from '../models/questionnaire';
import { NewQuestionnaireComponent } from './new-questionnaire';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { ToastrService } from 'ngx-toastr';
import { ConfirmActionDialogComponent } from '../component/confirm-action-dialog/confirm-action-dialog.component';
import { finalize } from 'rxjs/operators';
import { Utils } from '../shared/utilities/Utils';
import { NotyfToast } from '../shared/notify-toast/notify-toast.component';
import { cloneDeep } from 'lodash';
import { InvitationService } from 'app/service/invitation.service';
import { Candidat } from 'app/models/candidat';
import { InvitationRoutes } from 'app/constants/InvitationRoutes';
import { MatPaginator } from '@angular/material/paginator';
import { QuestionnaireStatsDialogComponent } from '../component/questionnaire-stats-dialog/questionnaire-stats-dialog.component';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AppTheme } from '../shared/utilities/AppTheme';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionnaireComponent implements OnInit {
  authenticatedMember: MembreAptea;
  dataSource: any;
  columnMode = ColumnMode;

  public flip = false;

  @Input() change: number;

  rows: Questionnaire[];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentPage = 0;
  pageSize = 5;
  totalElements: number;

  nameFilter: string;
  sort: string;

  createdOrUpdatedQuestionnaire: Questionnaire;
  questionnaireDialog: Questionnaire;

  loadingListOfQuestionnaire = false;
  loadingCurrentUser = false;

  dialogConfig: MatDialogConfig = { position: { top: '100px' }, width: '70%' };

  appTheme = AppTheme;

  public questionnairesXmlFileUrl: SafeResourceUrl;
  public questionnairesXmlCanBeDownloaded = false;

  constructor(
    @Inject(UserService) private userService: UserService,
    @Inject(QuestionnaireService)
    private questionnaireService: QuestionnaireService,
    @Inject(Router) private router: Router,
    @Inject(AuthService) private authService: AuthService,
    @Inject(InvitationService) private invitationService: InvitationService,
    public matDialog: MatDialog,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    public translate: TranslateService
  ) {}

  async ngAfterViewInit() {
    await this.paginator.firstPage();
  }

  async ngOnInit() {
    await this.detectCurrentMember();
    await this.listOfQuestionnaire();
    await this.createExportXML();
  }

  getRowClass = (row) => {
    return {
      'bg-success text-white data-table-selected-row':
        this.createdOrUpdatedQuestionnaire &&
        row.id === this.createdOrUpdatedQuestionnaire.id,
      'bg-warning text-white data-table-selected-row':
        this.questionnaireDialog && row.id === this.questionnaireDialog.id,
    };
  };

  createExportXML() {
    this.questionnaireService.getQuestionnairesXML().toPromise().then(data => {
      const blob = new Blob([data.content]);
      this.questionnairesXmlFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      this.questionnairesXmlCanBeDownloaded = true;
    })
    .catch((ex) => {
      console.log(ex);
      this.questionnairesXmlCanBeDownloaded = false;
    });
  }

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) { throw new Error('Cannot use multiple files'); }

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {

      const bstr: string = e.target.result;

      this.questionnaireService.importXMLQuestionnaires(bstr).subscribe(() => {
        this.router.navigate(['/login',
          {msgType: 'success', msgBody: this.translate.instant('changePassword.successMsg')}]);
      }, error => {
        console.log('error');
      });

    };
    reader.readAsText(target.files[0], 'UTF-8');
  }

  editQuestionnaire(questionnaire) {
    this.questionnaireDialog = questionnaire;

    const editQuestionnaireDialog = this.matDialog.open(
      NewQuestionnaireComponent,
      {
        ...this.dialogConfig,
        data: { questionnaireId: questionnaire.id },
      }
    );

    editQuestionnaireDialog.afterClosed().subscribe((result) => {
      this.questionnaireDialog = undefined;
      Utils.removeSelectRowStyle();

      this.refreshAfterAddOrEdit(result);
    });
  }

  openQuestionnaireStats(questionnaire) {
    this.questionnaireDialog = questionnaire;

    const questionnaireStatsDialog = this.matDialog.open(
      QuestionnaireStatsDialogComponent,
      {
        ...this.dialogConfig,
        data: { questionnaireId: questionnaire.id },
      }
    );

    questionnaireStatsDialog.afterClosed().subscribe((result) => {
      this.questionnaireDialog = undefined;
    });
  }

  simulateQuestionnaire(questionnaire) {
    this.invitationService
      .createSimulationInvitation(questionnaire.id)
      .subscribe(
        (invitation) => {
          const candidat = new Candidat();
          candidat.email = 'simulation';
          invitation.candidat = candidat;
          this.invitationService.invitation = invitation;
          this.router.navigate(['/' + InvitationRoutes.simulation.home], {
            queryParams: { token: invitation.id },
          });
        },
        (error) => {
          this.toastr.error(
            this.translate.instant('questionnaire.newSimulationError')
          );
        }
      );
  }

  private sendNotifSuccess(message: string): void {
    const options = cloneDeep(this.toastr.toastrConfig);
    options.toastComponent = NotyfToast;
    options.toastClass = 'notyf confirm';
    this.toastr.success(message, null, options);
  }

  deleteQuestionnaire(questionnaire) {
    this.questionnaireDialog = questionnaire;

    const translateVars: any = this.translate.instant(
      'questionnaire.deleteQuestionnaire'
    );

    const deleteQuestionnaireDialog = this.matDialog.open(
      ConfirmActionDialogComponent,
      {
        data: {
          dialogTitle: translateVars.dialogTitle,
          dialogMessage: translateVars.dialogMessage,
        },
      }
    );

    deleteQuestionnaireDialog.afterClosed().subscribe((actionConfirmed) => {
      this.questionnaireDialog = undefined;
      Utils.removeSelectRowStyle();

      if (actionConfirmed) {
        this.loadingListOfQuestionnaire = true;
        this.questionnaireService
          .deleteQuestionnaire(questionnaire)
          .pipe(
            finalize(() => {
              this.loadingListOfQuestionnaire = false;
            })
          )
          .subscribe(
            (request) => {
              this.sendNotifSuccess(translateVars.deleteSuccess);
              this.listOfQuestionnaire();
            },
            (error) => {
              if (error.status === 412) {
                // precondition failed
                this.toastr.error(
                  this.translate.instant('toastr.error.optimisticLocking')
                );
              } else if (error.status === 409) {
                // conflict
                this.toastr.error(
                  this.translate.instant('toastr.error.integrityViolation')
                );
              } else {
                this.toastr.error(translateVars.deleteError);
              }
            }
          );
      }
    });
  }

  async detectCurrentMember() {
    this.loadingCurrentUser = true;
    await this.authService
      .getCurrentMembreAptea()
      .toPromise()
      .then((authenticatedMember) => {
        this.authenticatedMember = authenticatedMember;
      })
      .catch((error) => {
        this.toastr.error(
          this.translate.instant('toastr.error.connexionError')
        );
        this.router.navigate(['/401']);
      });
    this.loadingCurrentUser = false;
  }

  async listOfQuestionnaire() {
    this.loadingListOfQuestionnaire = true;
    await this.questionnaireService
      .listOfQuestionnaires(
        this.currentPage,
        this.pageSize,
        this.nameFilter,
        this.sort
      )
      .toPromise()
      .then((response) => {
        this.rows = <Questionnaire[]>response.content;
        this.totalElements = response.totalElements;

        this.dataSource = new MatTableDataSource(this.rows);
      })
      .catch((error) => {
        // this.router.navigate(['/404']);
      });
    this.loadingListOfQuestionnaire = false;
  }

  newQuestionnaire() {
    const createQuestionnaireDialog = this.matDialog.open(
      NewQuestionnaireComponent,
      {
        ...this.dialogConfig,
        data: { questionnaireId: undefined },
      }
    );
    createQuestionnaireDialog.afterClosed().subscribe((result) => {
      this.refreshAfterAddOrEdit(result);
      this.flip = !this.flip;
    });
  }

  private refreshAfterAddOrEdit(result: any) {
    if (result && result.success) {
      this.createdOrUpdatedQuestionnaire = result.questionnaire;
      this.listOfQuestionnaire();

      setTimeout(() => {
        this.createdOrUpdatedQuestionnaire = undefined;

        Utils.removeSelectRowStyle();
      }, 10000);
    }
  }

  onSort(event: any) {
    this.sort = Utils.getSort(event);

    this.listOfQuestionnaire();
  }

  filterByName() {
    this.paginator.pageIndex = 0;
    this.currentPage = 0;
    this.listOfQuestionnaire();
  }

  setPage(pageInfo) {
    this.currentPage = pageInfo.pageIndex;
    this.pageSize = pageInfo.pageSize;

    this.listOfQuestionnaire();
  }
}
