<div class="content" fxLayout="row" fxLayoutAlign="center">
  <mat-card elevation="5" fxFlex>
    <mat-card-subtitle>{{translate.instant('changePassword.title')}}</mat-card-subtitle>
    <p [class]="notification.msgType" *ngIf="notification">{{notification.msgBody}}</p>
    <mat-card-content>
      <form *ngIf="!submitted" [formGroup]="form" (ngSubmit)="onSubmit()" #changePasswordForm="ngForm">
        <mat-form-field>
          <input matInput formControlName="oldPassword" required type="password"
                 placeholder="{{translate.instant('changePassword.oldPassword')}}">
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="newPassword" required type="password"
                 placeholder="{{translate.instant('changePassword.newPassword')}}">
        </mat-form-field>
        <button type="submit" [disabled]="!changePasswordForm.form.valid" mat-raised-button color="primary">
          {{translate.instant('changePassword.btnMsg')}}
        </button>
      </form>
      <mat-spinner *ngIf="submitted" mode="indeterminate"></mat-spinner>
    </mat-card-content>
  </mat-card>
</div>
