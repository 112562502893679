import {Questionnaire} from './questionnaire';

/**
 * Cette classe représente une candidat qui a reçu au moins une invitation
 *
 */
export class Candidat {
  id = '';
  nom = '';
  xp: '';
  prenom = '';
  email: string;
  numTel: string;
}
