<div class="content" fxLayout="row" fxLayoutAlign="center">

  <mat-card elevation="5" fxFlex>

    <mat-card-subtitle>
      <h2>Code Level</h2>
    </mat-card-subtitle>

    <mat-card-title>
      <h2>{{translate.instant('login.title')}}</h2>
    </mat-card-title>

    <mat-card-content>

      <p [class]="notification.msgType" *ngIf="notification">{{notification.msgBody}}</p>

      <form *ngIf="!submitted" [formGroup]="form" (ngSubmit)="onSubmit()" #loginForm="ngForm">
        <mat-form-field>
          <input id="username" matInput formControlName="username" required
                 [placeholder]="translate.instant('login.invitationLogin.candidate')">
        </mat-form-field>
        <button type="submit" [disabled]="!loginForm.form.valid" mat-raised-button
                color="primary">{{translate.instant('actions.login')}}</button>
      </form>
      <br>
      <div *ngIf="!submitted">
        <button [matTooltip]="translate.instant('login.invitationLogin.resetPasswordMsg')" mat-raised-button
                color="accent">
          {{translate.instant('login.invitationLogin.resetCredentials')}}
        </button>
      </div>

      <mat-spinner *ngIf="submitted" mode="indeterminate"></mat-spinner>
      <br>
      <hr>

    </mat-card-content>

  </mat-card>

</div>
