<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  {{translate.instant('component.questionnaireStats.title')}}
  <span class="close margin-left-20px" aria-label="Close" mat-dialog-close>
    <span aria-hidden="true">&times;</span>
  </span>
</h2>

<div mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane">

  <app-data-loading-spinner [loadingData]="loadingInitData"></app-data-loading-spinner>

  <div *ngIf="!loadingInitData && questionnaireStats" class="row">
    <mat-card class="col-sm-12 col-md-6">
      <mat-card-content>
        <h3>{{translate.instant('component.questionnaireStats.tests')}}</h3>
        <ngx-charts-advanced-pie-chart
          [view]="view"
          [scheme]="advancedPieChartColorScheme"
          [results]="advancedPieChartData">
        </ngx-charts-advanced-pie-chart>
      </mat-card-content>
    </mat-card>

    <mat-card class="col-sm-12 col-md-6">
      <mat-card-content>
        <h3>{{translate.instant('component.questionnaireStats.notes')}}</h3>
        <ngx-charts-bar-vertical
          [view]="view"
          [scheme]="barVerticalChartColorScheme"
          [results]="barVerticalChartData"
          [xAxis]="true"
          [yAxis]="true">
        </ngx-charts-bar-vertical>
      </mat-card-content>
    </mat-card>

    <mat-card class="col-sm-12 col-md-6">
      <mat-card-content>
        <h3>{{translate.instant('component.questionnaireStats.notes')}}</h3>
        <ngx-charts-bar-vertical
          [view]="view"
          [scheme]="verticalChartColorScheme"
          [results]="verticalChartData"
          [xAxis]="true"
          [yAxis]="true">
        </ngx-charts-bar-vertical>
      </mat-card-content>
    </mat-card>
  </div>

</div>
