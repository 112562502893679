import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {Component} from '@angular/core';
import {Toast, ToastrService, ToastPackage} from 'ngx-toastr';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'notyf-toast-component',
  styles: [],
  templateUrl: './notify-toast.component.html',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({opacity: 0})),
      transition(
        'inactive => active',
        animate(
          '300ms ease-out',
          keyframes([
            style({
              opacity: 0,
              bottom: '-15px',
              'max-height': 0,
              'max-width': 0,
              'margin-top': 0,
            }),
            style({
              opacity: 0.8,
              bottom: '-3px',
            }),
            style({
              opacity: 1,
              bottom: '0',
              'max-height': '200px',
              'margin-top': '12px',
              'max-width': '400px',
            }),
          ]),
        ),
      ),
      state(
        'active',
        style({
          bottom: '0',
          'max-height': '200px',
          'margin-top': '12px',
          'max-width': '400px',
        }),
      ),
      transition(
        'active => removed',
        animate(
          '300ms ease-out',
          keyframes([
            style({
              opacity: 1,
              transform: 'translateY(0)'
            }),
            style({
              opacity: 0,
              transform: 'translateY(25%)'
            }),
          ]),
        ),
      ),
    ]),
  ],
})
// tslint:disable-next-line:component-class-suffix
export class NotyfToast extends Toast {
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
    this.options.timeOut = 5000;
  }


  // todo switch case if prefered.
  public getMessageClass() {
    if (this.toastClasses.indexOf('notyf error') !== -1) {
      return 'notyf__icon--error';
    } else if (this.toastClasses.indexOf('notyf warning') !== -1) {
      return 'notyf__icon--warning';
    } else {
      return 'notyf__icon--success';
    }
  }

  public getContainerClass() {
    if (this.toastClasses.indexOf('notyf error') !== -1) {
      return 'notyf__toast notyf__toast notyf__toast--error';
    } else if (this.toastClasses.indexOf('notyf warning') !== -1) {
      return 'notyf__toast notyf__toast notyf__toast--warning';
    } else {
      return 'notyf__toast notyf__toast notyf__toast--success';
    }
  }


  public getRippleClass() {
    if (this.toastClasses.indexOf('notyf error') !== -1) {
      return 'notyf__ripple notyf__ripple-error';
    } else if (this.toastClasses.indexOf('notyf warning') !== -1) {
      return 'notyf__ripple notyf__ripple-warning';
    } else {
      return 'notyf__ripple notyf__ripple-success';
    }
  }


}
