import { Component, Inject, Input, OnInit } from '@angular/core';
import { Invitation } from '../../models/invitation';
import { DOCUMENT } from '@angular/common';
import { InvitationService } from '../../service/invitation.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-button-pdf-component[invitation]',
  templateUrl: './button-pdf-component.component.html',
  styleUrls: ['./button-pdf-component.component.css'],
})
export class ButtonPdfComponentComponent implements OnInit {
  @Input() invitation: Invitation;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(InvitationService) public invitationService: InvitationService,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {}

  goToPDFBuilder(): void {
    this.invitationService
      .getInvitationPDFDataFromId(this.invitation.id)
      .subscribe(
        (data) => {
          const candidateScoreByCategory = data.candidateScoreByCategory;
          const averageByCategory = data.averageByCategory;

          const myArrayOfScores = candidateScoreByCategory.map((u) => {
            const obj = {};
            obj[u.categoryName] = {
              valeur: u.note,
              moyenne: averageByCategory.find(
                (j) => j.categoryId === u.categoryId
              )?.note,
            };
            return obj;
          });
          const dataToEncode = {
            CandidatData: {
              Profil: {
                prenom: this.invitationService.getCandidatPrenomFromInvitation(
                  this.invitation
                ),
                nom: this.invitationService.getCandidatNomFromInvitation(
                  this.invitation
                ),
                xp: this.invitationService.getCandidatExperienceFromInvitation(
                  this.invitation
                ),
                email: this.invitationService.getCandidatEmailFromInvitation(
                  this.invitation
                ),
                date: this.invitation.dateInvitation,
                total: data.candidateTotalScore.note,
              },
              date: this.invitation.dateInvitation,
              score: myArrayOfScores,
              total: [
                data.candidateTotalScore.note,
                data.testsScoreNegatif,
                data.testsScoreInf20,
                data.testsScoreInf40,
                data.testsScoreInf60,
                data.testsScoreInf80,
                data.testsScoreInf100,
              ],
            },
          };
          const stringifiedJSONData = JSON.stringify(dataToEncode);
          const removedForbiddenCharacters = stringifiedJSONData.replace(
            /#/g,
            'Sharp'
          );
          const dataFromBackendToURI = encodeURI(removedForbiddenCharacters);
          window.open(
            this.document.location.origin +
              '/assets/pdf-builder/index.html?candidatData=' +
              dataFromBackendToURI,
            '_blank'
          );
        },
        (error) => {
          this.toastr.error(this.translate.instant('toastr.error.error'));
        }
      );
  }
}
