<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" align="center">
  {{data.dialogTitle}}
  <span class="close margin-left-20px" aria-label="Close"  (click)="onClose()" mat-dialog-close>
    <span aria-hidden="true">&times;</span>
  </span>
</h2>

<mat-dialog mat-dialog-content align="center">
  <mat-card-content>
    {{data.dialogMessage}}
  </mat-card-content>
</mat-dialog>

<mat-dialog-actions align="center">
    <button mat-raised-button (click)="majorUpdate()" class="mat-raised-button1">
      {{translate.instant('question.editQuestion.majorUpdate')}}
    </button>
    &nbsp;
    <div class="space"></div>
  
    <button mat-button (click)="minorUpdate()" 
    class="mat-button1">
    {{translate.instant('question.editQuestion.minorUpdate')}}
    </button>

</mat-dialog-actions>

