import {Router, ActivatedRoute} from '@angular/router';
import {Component, OnInit, Inject} from '@angular/core';
import {QuestionService} from '../../service/question.service';
import {MembreAptea} from '../../models/membreAptea';
import {AuthService} from '../../service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-new-question',
  templateUrl: './new-question.component.html',
  styleUrls: ['./new-question.component.css']
})
export class NewQuestionComponent implements OnInit {


  authenticatedMember: MembreAptea;

  returnUrl: string;

  constructor(
    @Inject(QuestionService) private questionService: QuestionService,
    @Inject(Router) @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(AuthService) private authService: AuthService,
    private toastr: ToastrService,
    public translate: TranslateService) {
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.authService.getCurrentMembreAptea().subscribe(authenticatedMember => {
      this.authenticatedMember = authenticatedMember;
    }, error => {
      this.toastr.error(this.translate.instant('toastr.error.connexionError'));
      this.router.navigate(['/401']);
    });
  }

}
