import {Component, OnInit, Input} from '@angular/core';
import {QuestionLibre} from 'app/models/question.libre';
import {TestCandidatEntry} from 'app/models/testCandidatEntry';
import {Question} from 'app/models/question';
import {removeDiacritics} from 'app/shared/ace-editor-stuff/remove-diacritrics';
import {escape} from 'lodash';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-review-question-ouverte',
  templateUrl: './review-question-ouverte.component.html',
  styleUrls: ['./review-question-ouverte.component.css']
})
export class ReviewQuestionOuverteComponent implements OnInit {

  @Input() testCandidatId: number;
  @Input() entry: TestCandidatEntry;

  keywords: string[];
  highlightedReponseText: string;
  showHighlighted: boolean;

  constructor(public translate: TranslateService) {
  }

  ngOnInit() {
    this.keywords = (this.entry.question as QuestionLibre).resultat.split('\n').map(w => w.trim());
    this.highlightKeywords();
  }

  asQuestionLibre(question: Question): QuestionLibre {
    return question as QuestionLibre;
  }

  // we have to mimic the code executed in the back-end...
  // highlighting may fail for some combination of keywords, with partially overlapping keywords.
  // ex: searching for keywords ["ab", "bc"] in text "abc" will only result in one of them being highlighted.
  highlightKeywords() {
    this.highlightedReponseText = null;
    this.showHighlighted = false;
    if (this.entry.reponse.text != null) {
      // normalization: remove diacritics + lowercase + escape potential html tags
      let highlighted = escape(removeDiacritics(this.entry.reponse.text).toLowerCase());
      let found = false;
      const keywords = this.normalizeAndSortKeywords(this.keywords);
      for (const keyword of keywords) {
        const regexString = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(regexString, 'g');
        if (regexString.length > 0) {
          const replaced = highlighted.replace(regex, `<b>$&</b>`);
          if (replaced !== highlighted) {
            found = true;
          }
          highlighted = replaced;
        }
      }
      this.highlightedReponseText = highlighted;
      this.showHighlighted = found;
    }
  }

  // normalize the keywords, and order them so that keywords that contain other keywords will be processed first.
  normalizeAndSortKeywords(keywords: string[]): string[] {
    const result: string[] = [];
    for (const keyword of keywords) {
      // same normalization as for the candidate answer
      const cleanKeyword = escape(removeDiacritics(keyword).toLowerCase());
      const foundIndex = result.findIndex(text => cleanKeyword.includes(text));
      if (foundIndex === -1) {
        result.push(cleanKeyword);
      } else {
        result.splice(foundIndex, 0, cleanKeyword);
      }
    }
    return result;
  }

  onHighlightClick() {
    this.showHighlighted = !this.showHighlighted;
  }

  highlightTooltip(): string {
    const highlightTranslate = this.translate.instant('testReview.openQuestion.highlight');
    return this.showHighlighted ? highlightTranslate.originalResponse : highlightTranslate.foundKeyWords;
  }

  highlightClass(): string {
    return this.showHighlighted ? 'mat-primary' : '';
  }
}
