import {Component, OnInit, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../app/service';
import {InvitationRoutes} from 'app/constants/InvitationRoutes';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {AppRoles} from '../shared/utilities/AppRoles';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    @Inject(Router) private router: Router,
    @Inject(AuthService) private authService: AuthService,
    private toastr: ToastrService,
    public translate: TranslateService) {
  }

  ngOnInit() {
    this.authService.getCurrentMembreAptea().subscribe(authenticatedMember => {
      // il n'y a pas de page d'accueil à proprement parler : on redirige vers la page principale selon le profil actuel
      let redirectUrl = '/questions';
      switch (authenticatedMember.profile) {
        case AppRoles.ADMIN:
        case AppRoles.RH:
          redirectUrl = '/invitations';
          break;
        case AppRoles.CANDIDAT:
          redirectUrl = '/' + InvitationRoutes.candidat.home;
          break;
      }
      this.router.navigate([redirectUrl]);
    }, error => {
      this.toastr.error(this.translate.instant('toastr.error.connexionError'));
    });

  }
}
