<div [formGroup]="formGroup">
  <div *ngIf="isReady" class="row">
    <mat-form-field class="col-12">
      <label>
        <textarea matInput matTextareaAutosize placeholder="Votre réponse" matAutosizeMinRows=1 matAutosizeMaxRows=6 cols="200"
                  (change)="onChange($event)"></textarea>
      </label>
    </mat-form-field>
  </div>
</div>
