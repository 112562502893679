<mat-toolbar color="accent" class="app-navbar">
  <button mat-button mat-ripple (click)="onRoutLink()" class="no-border-outline">
    <img alt="Code Level" class="app-angular-logo" src="assets/image/logo.png" [matTooltip]="afficheBuild"
         matTooltipClass="mat-tooltip-multiline" style="white-space: pre;">
    <span [fxHide.lt-sm]="true">Code Level</span>
  </button>

  <div class="no-border-outline">
    <button mat-mini-fab [matMenuTriggerFor]="themeMenu">
      <mat-icon matTooltip="{{translate.instant('component.header.theme')}}" matTooltipPosition="above"
                [class]="getIconClass()">wb_incandescent
      </mat-icon>
    </button>
    <mat-menu #themeMenu="matMenu">
      <button mat-menu-item [disabled]="disableThemeChoice(appTheme.dark)"
              (click)="changeTheme(appTheme.dark)">
        Dark
      </button>
      <button mat-menu-item [disabled]="disableThemeChoice(appTheme.light)"
              (click)="changeTheme(appTheme.light)">light
      </button>
      <button mat-menu-item [disabled]="disableThemeChoice(appTheme.mix)"
              (click)="changeTheme(appTheme.mix)">Mix
      </button>
    </mat-menu>
  </div>

  <div class="no-border-outline">
    <button mat-button [matMenuTriggerFor]="languageMenu">
      <span matTooltip="{{translate.instant('component.header.language')}}" matTooltipPosition="above">
        <img class="width-25-px"
             [src]="'assets/image/language-flags/' + appLanguage.globalAppLanguage + '-flag-icon.png'"
             alt="">
        {{getStringUpperCase(appLanguage.globalAppLanguage)}}
      </span>
    </button>
    <mat-menu #languageMenu="matMenu">
      <button mat-menu-item [disabled]="disableLanguageChoice(appLanguage.EN)"
              (click)="changeAppLanguage(appLanguage.EN)">
        <img class="width-20-px"
             [ngClass]="{'img-disabled': disableLanguageChoice(appLanguage.EN)}"
             [src]="'assets/image/language-flags/' + appLanguage.EN + '-flag-icon.png'"
             alt="">
        {{appLanguage.EN}}
      </button>
      <button mat-menu-item [disabled]="disableLanguageChoice(appLanguage.FR)"
              (click)="changeAppLanguage(appLanguage.FR)">
        <img class="width-20-px"
             [ngClass]="{'img-disabled': disableLanguageChoice(appLanguage.FR)}"
             [src]="'assets/image/language-flags/' + appLanguage.FR + '-flag-icon.png'"
             alt="">
        {{appLanguage.FR}}
      </button>
    </mat-menu>
  </div>

  <div *ngIf="userService.currentUser" class="right">

    <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="flex-end center" [fxHide.lt-md]="true">
      <ng-container *ngFor="let item of navBarItems">
        <button mat-button *ngIf="userHasAccessToMenuItem(item.authorisedRoles)" routerLink="{{item.routerLink}}"
                [ngClass]="{'button-border': utils.stringIncludesAtLeast(window.location.href, item.activeUrls)}">
          <span>{{item.title}}</span>
        </button>
      </ng-container>

      <button mat-button *ngIf="hasSignedIn() && !isInvitation" (click)="logout()">
        <span>{{logoutBtnTitle}}</span>
      </button>
    </div>

    <div [fxHide.gt-sm]="true" class="no-border-outline">
      <button mat-icon-button [matMenuTriggerFor]="dropMenu"
              matTooltip="{{translate.instant('component.header.menu')}}" matTooltipPosition="above">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #dropMenu="matMenu">
        <ng-container *ngFor="let item of navBarItems">
          <button mat-menu-item *ngIf="userHasAccessToMenuItem(item.authorisedRoles)" routerLink="{{item.routerLink}}"
                  [disabled]="utils.stringIncludesAtLeast(window.location.href, item.activeUrls)">
            <span>{{item.title}}</span>
          </button>
        </ng-container>

        <button mat-menu-item *ngIf="hasSignedIn() && !isInvitation" (click)="logout()">
          <span>{{logoutBtnTitle}}</span>
        </button>
      </mat-menu>
    </div>

  </div>
</mat-toolbar>


