import {Inject, Injectable} from '@angular/core';
import {ConfigService} from './config.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class BuildService {

  constructor(
    @Inject(HttpClient) private httpClient: HttpClient,
    @Inject(ConfigService) private config: ConfigService
  ) {
  }

  getVersion() {
    return this.httpClient.get<String>(this.config.version_url, {responseType: 'text' as 'json'});
  }

  getDateBuild() {
    return this.httpClient.get<String>(this.config.datebuild_url, {responseType: 'text' as 'json'});
  }

}
