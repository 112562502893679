import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs'
import { QuestionnairesXML } from '../models/questionnairesXML';
import {ConfigService} from './config.service';
import {Questionnaire} from '../models/questionnaire';
import {throwError} from 'rxjs/internal/observable/throwError';
import {Question} from '../models/question';
import {QuestionnaireStats} from '../models/QuestionnaireStats';

@Injectable()
export class QuestionnaireService {

  questionnaire: Questionnaire;

  constructor(@Inject(HttpClient) private httpClient: HttpClient, @Inject(ConfigService) private config: ConfigService) {
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  addQuestionnaire(questionnaire: Questionnaire): Observable<Questionnaire> {
    return this.httpClient.post<Questionnaire>(this.config.questionnaires_url, questionnaire);
  }

  listOfQuestionnaires(page?: number, size?: number, nom?: string, sort?: string): Observable<any> {

    let parameters = new HttpParams().append('page', String(page)).append('size', String(size));

    if (nom) {
      parameters = parameters.append('nom', encodeURIComponent(nom));
    }

    if (sort) {
      parameters = parameters.append('sort', sort);
    }

    const options = {
      params: parameters
    };

    return this.httpClient.get<Questionnaire[]>(this.config.questionnaires_url, options);
  }

  getQuestionnairesXML(): Observable<QuestionnairesXML> {
    return this.httpClient.get<QuestionnairesXML>(this.config.questionnaires_url + '/xml');
  }

  deleteQuestionnaire(questionnaire): Observable<Questionnaire> {
    const options = { headers: new HttpHeaders().append('If-Match', '' + questionnaire.version) }
    return this.httpClient.delete<Questionnaire>(this.config.questionnaires_url + '/' + questionnaire.id, options);
  }

  importXMLQuestionnaires(xml: string) {
    const signupHeaders = new HttpHeaders({
      'Accept': 'text/plain;charset=UTF-8',
      'Content-Type': 'text/plain;charset=UTF-8'
    });
    const options = { headers: signupHeaders }
    const body = new QuestionnairesXML();
    body.content = xml;
    return this.httpClient.post<QuestionnairesXML>(this.config.questionnaires_url + '/' + 'importXML', body);
  }

  getQuestionnaire(id): Observable<Questionnaire> {
    return this.httpClient.get<Questionnaire>(this.config.questionnaires_url + '/' + id)
  }

  getQuestionsByQuestionnaire(id): Observable<Question[]> {
    return this.httpClient.get<Question[]>(this.config.questionnaires_url + '/' + id + '/questions')
  }

  getQuestionnaireStats(id): Observable<QuestionnaireStats> {
    return this.httpClient.get<QuestionnaireStats>(this.config.questionnaires_url + '/' + id + '/stats')
  }

}
