import {delay, takeUntil} from 'rxjs/operators';
import {MembreAptea} from '../../models/membreAptea';
import {Inject} from '@angular/core';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {DisplayMessage} from '../../shared/models/display-message';
import {Subject} from 'rxjs';
import {UserService, AuthService} from '../../service';
import {InvitationRoutes} from 'app/constants/InvitationRoutes';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login-invitation',
  templateUrl: './login-invitation.component.html',
  styleUrls: ['./login-invitation.component.css']
})
export class LoginInvitationComponent implements OnInit, OnDestroy {

  githubLink = 'https://github.com/';
  form: FormGroup;
  authenticatedMember: MembreAptea;

  /**
   * Boolean used in telling the UI
   * that the form has been submitted
   * and is awaiting a response
   */
  submitted = false;

  /**
   * Notification message from received
   * form request or router
   */
  notification: DisplayMessage;

  returnUrl: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    @Inject(UserService) private userService: UserService,
    @Inject(AuthService) private authService: AuthService,
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public translate: TranslateService) {
  }

  ngOnInit() {
    this.route.params.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((params: DisplayMessage) => {
        this.notification = params;
      });
    // get return url from route parameters or default to '/'
    this.returnUrl = '/' + InvitationRoutes.candidat.home;
    this.form = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(64)])]
    });
    this.cancelReturnToLoginAfterLoginSuccess();
  }

  ngOnDestroy() {
    // this.ngUnsubscribe.next();
    // this.ngUnsubscribe.complete();
  }


  repository() {
    window.location.href = this.githubLink;
  }

  onSubmit() {
    /**
     * Innocent until proven guilty
     */
    this.notification = undefined;
    this.submitted = false;

    this.authService.loginThroughLdap(this.form.value).pipe(
      delay(1000))
      .subscribe(data => {
          // this.userService.getMyInfo().subscribe();
          this.submitted = true;
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.notification = {
            msgType: 'error',
            msgBody: this.translate.instant('login.invitationLogin.loginError')
          };
        });
  }

  cancelReturnToLoginAfterLoginSuccess() {
    this.authService.getCurrentMembreAptea().subscribe(authenticatedMember => {
      this.authenticatedMember = authenticatedMember;
      this.router.navigate([this.returnUrl]);
    }, error => {
      this.toastr.error(this.translate.instant('login.invitationLogin.afterLoginError'));
    });
  }

}
