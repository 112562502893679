import {Inject, Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute} from '@angular/router';
import {UserService, AuthService} from '../service';
import {Observable} from 'rxjs';
import {InvitationService} from '../service/invitation.service';
import {DisplayMessage} from '../shared/models/display-message';
import {AppRoles} from '../shared/utilities/AppRoles';

/**
 * Ce guard est conçu pour fonctionner avec un paramètre contenant l'identifiant du rôle qui a les droits d'accès
 * De base, sans ce paramètre, personne ne peut accéder à la page
 * exemple dans le cas où seul les candidats pourront accéder :
 canActivate: [TokenGuard],
 data: {roles: ['ROLE_CANDIDAT']},
 */
@Injectable()
export class TokenGuard implements CanActivate {

  pageAuthorizedRoles: Array<string>;
  notification: DisplayMessage;

  constructor(@Inject(Router) private router: Router,
              @Inject(InvitationService) private invitationService: InvitationService,
              @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
              @Inject(UserService) private userService: UserService,
              @Inject(AuthService) private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // on récupère le paramètre 'roles' pour déterminer qui a le droit d'accéder à la page et autoriser ou pas en conséquence
    this.pageAuthorizedRoles = route.data['roles'] as Array<string>;

    // on teste si l'utilisateur est une  possédant un token
    // (si il y en a un dans les arguments)
    if (!this.invitationService.isInvitationConnecte()) {

      const token = route.queryParamMap.get('token');

      if (token != null && token !== '') {

        const datecontrole = new Date();
        datecontrole.setDate(datecontrole.getDate() - 1);

        return new Promise<boolean>(
          (resolve) => {
            this.invitationService.getInvitationValideFromId(token).subscribe(
              invitation => {
                this.invitationService.invitation = invitation;

                if (this.invitationService.isInvitationSupprime()) {
                  this.router.navigate(['token-invalide'], {queryParams: {erreur: 0}});
                }

                resolve(this.isInvitationPage());
              },
              error => {
                this.router.navigate(['token-invalide'], {queryParams: {erreur: 0}});
              });
          }
        );
      }
// tslint:disable-next-line: one-line
      else {
        const redirectUrl = '/login';
        this.router.navigate([redirectUrl]);
      }
    }
// tslint:disable-next-line: one-line
    else {
      return this.isInvitationPage();
    }
  }

  // les pages invitations sont accessibles à ceux qui ont un token valide
  isInvitationPage(): boolean {
    for (let i = 0; i < this.pageAuthorizedRoles.length; i++) {
      const role = this.pageAuthorizedRoles[i];
      if (AppRoles.CANDIDAT === role) {
        return true;
      }
    }
    return false;
  }
}
