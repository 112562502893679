export class AppTheme {
  static readonly dark = 'global-dark-theme';
  static readonly light = 'global-light-theme';
  static readonly mix = 'global-mix-theme';

  public static readonly appThemes: string[] = [AppTheme.light, AppTheme.dark, AppTheme.mix];

  private static _globalTheme: string = localStorage.getItem('appTheme');

  static get globalTheme(): string {
    return this._globalTheme;
  }

  public static changeAppTheme(value: string) {
    document.body.classList.remove(AppTheme.light, AppTheme.dark, AppTheme.mix);

    if (!AppTheme.appThemes.includes(value)) {
      value = AppTheme.light;
    }

    document.body.classList.add(value);
    this._globalTheme = value;
    localStorage.setItem('appTheme', value);
  }
}
