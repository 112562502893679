import {Component, Inject, OnInit} from '@angular/core';
import {ErreursAcces, InvitationService} from '../service/invitation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {CandidatQuestion} from '../models/candidatQuestion';
import {CandidatReponse} from '../models/candidatReponse';
import {HttpErrorResponse} from '@angular/common/http';
import {finalize} from 'rxjs/operators';
import {InvitationRoutes} from 'app/constants/InvitationRoutes';
import {Utils} from '../shared/utilities/Utils';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-repondre-test',
  templateUrl: './repondre-test.component.html',
  styleUrls: ['./repondre-test.component.css']
})
export class RepondreTestComponent implements OnInit {
  token = '';
  questions: CandidatQuestion[];
  reponses: CandidatReponse[];
  remainingDurations: number[];
  submitted = false;
  form: FormGroup;

  public rawImage: string;

  legacyMode = false; // legacy mode: get and display all questions at once. new mode: repeat (get current question, send current answer)
  loadingInitData = false;

  private mode: string;
  utils = Utils;

  constructor(
    @Inject(InvitationService) public invitationService: InvitationService,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(Router) private router: Router,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    public translate: TranslateService) {
    this.mode = this.router.url.startsWith('/' + InvitationRoutes.simulation.repondre) ? 'simulation' : 'candidat';
  }

  async ngOnInit() {
    this.loadingInitData = true;

    this.activatedRoute.queryParams.subscribe(params => {

      this.token = params ['token'] as string;
    });


    if (this.invitationService.isInvitationAvecAccesAuTest()) {

      await this.invitationService.startTest(this.token).pipe(
        finalize(() => {
          this.loadingInitData = false;
        })
      ).toPromise().then(async resp => {
        if (this.legacyMode) {
          this.invitationService.getTestQuestions(this.token).subscribe(questions => {
            this.initQuestionData(questions);
          })
        } else {
          await this.getCurrentQuestion();
        }
      });
    } else {
      await this.router.navigate(['/' + InvitationRoutes[this.mode].home], {queryParams: {erreur: ErreursAcces.CantAccessTest}});
    }
  }

  initQuestionData(questions: CandidatQuestion[]) {
    this.questions = questions;
    this.reponses = new Array<CandidatReponse>(this.questions.length);
    this.remainingDurations = new Array<number>(this.questions.length);
    this.form = this.formBuilder.group({
      questionsFormArray: this.formBuilder.array([])
    });
    for (let i = 0; i < this.questions.length; i++) {
      this.questionsFormArray.push(this.formBuilder.group([]));
      this.reponses[i] = new CandidatReponse();
      this.reponses[i].questionId = this.questions[i].id;
      this.remainingDurations[i] = this.getRemainingDuration(this.questions[i]);
    }
    this.submitted = false;
  }

  get questionsFormArray(): FormArray {
    return this.form.get('questionsFormArray') as FormArray;
  }

  async getCurrentQuestion() {
    await this.invitationService.getCurrentTestQuestion(this.token).toPromise().then(question => {
        if (question) {
          this.initQuestionData([question]);
        } else {
          // should not happen... assuming the test is finished?
          this.router.navigate(['/' + InvitationRoutes[this.mode].home]);
        }
      },
      (error: HttpErrorResponse) => {
        this.submitted = false;
        if (error.status === 412) {
          // expected status code when there is no more questions.
          this.router.navigate(['/' + InvitationRoutes[this.mode].home]);
        } else {
          alert(this.translate.instant('testResponse.noQuestionError'));
        }
      }
    )
  }

  onSubmit() {
    // todo? look for missing answers and warn the candidate?
    if (this.legacyMode) {
      this.submitted = true;
      this.invitationService.postTestReponses(this.reponses, this.token).subscribe(invitation => {
          this.invitationService.invitation = invitation;

          this.router.navigate(['/' + InvitationRoutes[this.mode].fin], {queryParams: {token: this.token}});
        },
        error => {
          this.submitted = false;
          alert(this.translate.instant('testResponse.testSaveError'));
        }
      );
    } else {
      this.submitted = true;
      this.invitationService.postCurrentTestReponse(this.reponses[0], this.token).subscribe(async invitation => {
          this.invitationService.invitation = invitation;

          await this.getCurrentQuestion();
        },
        error => {
          this.submitted = false;
          alert(this.translate.instant('testResponse.responseSaveError'));
        }
      );
    }
  }

  getRemainingDuration(question: CandidatQuestion): number {
    if (question.displayTime) {
      return Math.trunc((new Date(question.displayTime).valueOf() - Date.now()) / 1000) + question.duration;
    } else {
      return question.duration;
    }
  }

  get submitButtonLabel(): string {
    const submitBtnTranslateVar = this.translate.instant('testResponse.submitButtonLabel');
    if (this.submitted) {
      return submitBtnTranslateVar.loading;
    } else if (this.legacyMode) {
      return submitBtnTranslateVar.finish;
    } else if (this.questions.length > 0 && this.questions[this.questions.length - 1].type === 'texte') {
      return submitBtnTranslateVar.continue;
    } else {
      return submitBtnTranslateVar.sendResponse;
    }
  }
}
