<div class="content" fxLayout="row" fxLayoutAlign="center">
  <mat-card elevation="5" fxFlex>

    <p>
      {{translate.instant('searchResult.membersList')}}
    </p>
    <mat-card-content>
      <mat-list>
        <ng-container *ngFor="let membre of membres">
          <mat-list-item>
            <div matLine>{{membre.username}}</div>
            <div matLine>{{membre.profile}}</div>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </mat-card-content>

  </mat-card>
</div>


