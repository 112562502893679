<div>
  <form [formGroup]="form" *ngIf="!submitted" (ngSubmit)="onSubmit()">
    <div>
      <mat-form-field class="taille-form" floatLabel="always" appearance="outline" class="score-review">
        <mat-label>{{translate.instant('testReview.responseAnnotation.manuelCorrectionMsg') + maxScore}}):
        </mat-label>
        <textarea
          id="reviewScore"
          style="overflow-y: hidden"
          matTextareaAutosize
          matInput type="number"
          formControlName="score"
          placeholder="note calculée">
          </textarea>
        <mat-icon matSuffix>thumbs_up_down</mat-icon>
      </mat-form-field>
      <mat-icon matTooltip="{{translate.instant('testReview.responseAnnotation.manualCorrectionMsgForPenalty')}}">warning_amber</mat-icon>
    </div>
    <div>
      <mat-form-field floatLabel="always" appearance="outline" class="comments-review">
        <mat-label>
          {{translate.instant('testReview.responseAnnotation.comment')}}
        </mat-label>
        <textarea
          id="reviewComment"
          style="overflow-y: hidden"
          matTextareaAutosize
          matInput
          formControlName="comment"
          placeholder="{{translate.instant('testReview.responseAnnotation.withoutComment')}}">
          </textarea>
        <mat-icon matSuffix>comment</mat-icon>
      </mat-form-field>
    </div>
    <button type="submit" mat-raised-button color="primary" [disabled]="form.invalid">
      {{translate.instant('actions.update')}}
    </button>
    <!--    <button mat-button matStepperNext>Suivant</button>-->
  </form>
  <mat-spinner *ngIf="submitted" mode="indeterminate"></mat-spinner>
</div>
