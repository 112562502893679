import {Component, OnInit, Input, Inject} from '@angular/core';
import { CandidatProposition } from '../../models/candidatProposition';
import { CandidatQuestion } from '../../models/candidatQuestion';
import { CandidatReponse } from '../../models/candidatReponse';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

export interface QCMProposition {
  proposition: CandidatProposition;
  selected: boolean;
}

@Component({
  selector: 'app-repondre-question-choix-multiple',
  templateUrl: './repondre-question-choix-multiple.component.html',
  styleUrls: ['./repondre-question-choix-multiple.component.css']
})
export class RepondreQuestionChoixMultipleComponent implements OnInit {

  @Input() question: CandidatQuestion;
  @Input() reponse?: CandidatReponse;
  @Input() formGroup?: FormGroup;

  qcmPropositions: QCMProposition[] = [];
  isReady = false;

  constructor(@Inject(FormBuilder) private formBuilder: FormBuilder) { }

  ngOnInit() {
    const sorter = (n1: QCMProposition, n2: QCMProposition) => { return n1.proposition.order - n2.proposition.order; }
    this.qcmPropositions = this.question.propositions.map(p => { return { proposition: p, selected: false } as QCMProposition; })
      .sort(sorter);
    if (!this.formGroup) {
      this.formGroup = this.formBuilder.group({});
    }
    this.formGroup.addControl('propositions', this.formBuilder.array([]));
    for (const prop of this.qcmPropositions) {
      this.propositionsFormArray.push(this.formBuilder.control(false));
    }
    if (!this.reponse) {
      this.reponse = new CandidatReponse();
      this.reponse.questionId = this.question.id;
    }
    this.isReady = true;
  }

  get propositionsFormArray(): FormArray {
    return this.formGroup.get('propositions') as FormArray;
  }

  toggleProposition(propositionIndex: number) {
    this.qcmPropositions[propositionIndex].selected = !this.qcmPropositions[propositionIndex].selected;
    this.updateReponse();
  }

  updateReponse() {
    this.reponse.propositions = this.qcmPropositions.filter(p => p.selected).map(p => p.proposition.id);
    // this.answerEmitter.emit(this.reponse);
  }

}
