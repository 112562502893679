import { delay, finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { QuestionService } from '../../../service/question.service';
import { QuestionCode } from '../../../models/question.code';
import 'brace';
import 'brace/theme/eclipse';

import 'brace/mode/java';
import 'brace/mode/javascript';
import 'brace/mode/php';
import 'brace/mode/csharp';
import 'brace/mode/python';
import 'brace/mode/mysql';
import 'brace/mode/c_cpp';

import 'brace/ext/language_tools';
import { removeDiacritics } from '../../../shared/ace-editor-stuff/remove-diacritrics';

import { JdoodleDataOutput } from '../../../models/jdoodle.data.output';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { ExecuteCodeDialogComponent } from '../../../component/execute-code-dialog/execute-code-dialog.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CategorieService } from '../../../service/categorie.service';
import { Categorie } from '../../../models/categorie';
import { ToastrService } from 'ngx-toastr';
import { NotyfToast } from '../../../shared/notify-toast/notify-toast.component';
import { cloneDeep } from 'lodash';
import { Constants } from '../../../shared/utilities/Constants';
import { Utils } from '../../../shared/utilities/Utils';
import { AppTheme } from '../../../shared/utilities/AppTheme';
import { TranslateService } from '@ngx-translate/core';
import { relaceImagesWithBlobByDataURL } from '../fixForBlobImage';

import { Location } from '@angular/common';

@Component({
  selector: 'app-question-code-dialog',
  templateUrl: './question-code-dialog.component.html',
  styleUrls: ['./question-code-dialog.component.css'],
})
export class QuestionCodeDialogComponent implements OnInit {
  form: FormGroup;
  public jdataOuput: JdoodleDataOutput;

  public options = {
    maxLines: Infinity,
    printMargin: false,
    enableBasicAutocompletion: true,
  };

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  questionCode: QuestionCode = new QuestionCode();
  idQuestion: number;

  submitted = false;
  executingCode = false;
  loadingInitData: boolean;
  isUpdate: boolean;

  constants = Constants;
  utils = Utils;
  appTheme = AppTheme;

  useMonacoEditor = true;

  constructor(
    @Inject(AuthService) private authService: AuthService,
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    @Inject(QuestionService) private questionService: QuestionService,
    @Inject(MatDialog) private dialog: MatDialog,
    @Inject(CategorieService) public categorieService: CategorieService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private location: Location
  ) {}

  async ngOnInit() {
    this.loadingInitData = true;
    this.idQuestion = this.activatedRoute.snapshot.params['id'];

    if (this.idQuestion) {
      this.isUpdate = true;
    }

    this.form = this.formBuilder.group({
      newTitre: ['', [Validators.required]],
      formCategories: [''],
      nouvellesCategories: [''],
      technologie: ['', [Validators.required]],
      enonce: ['', [Validators.required]],
      codePrerempli: [''],
      codeComplet: [''],
      newResultat: ['', [Validators.required]],
      duree: ['', [Validators.required]],
      difficulte: ['', [Validators.required]],
    });

    if (this.isUpdate) {
      await this.categorieService
        .resetDataPourQuestion(this.idQuestion)
        .toPromise()
        .then();

      await this.questionService
        .getQuestionCode(this.idQuestion)
        .toPromise()
        .then(
          (data) => {
            this.questionCode = data;
          },
          (err) => {
            this.toastr.error(this.translate.instant('toastr.error.error'));
          }
        );
    }

    this.loadingInitData = false;
  }

  onSubmit() {
    this.submitted = true;

    // récupération des catégories sélectionnées et des nouvelles créées
    this.questionCode.questionCategories =
      this.form.value.formCategories.concat(
        this.form.value.nouvellesCategories
      );
    this.questionCode.codeComplet = removeDiacritics(
      this.questionCode.codeComplet
    );

    (async () => {
      await relaceImagesWithBlobByDataURL(this.questionCode);
      this.questionService
        .updateQuestionCode(this.questionCode)
        .pipe(
          finalize(() => {
            this.submitted = false;
          })
        )
        .subscribe(
          (data) => {
            const translateVar = this.translate.instant(
              'question.questionDialogs.code.submitSuccess'
            );
            this.sendNotifSuccess(
              translateVar.message +
                (this.isUpdate ? translateVar.updated : translateVar.added)
            );
            this.location.back();
          },
          (err) => {
            if (err.status === 412) {
              // precondition failed
              this.toastr.error(
                this.translate.instant('toastr.error.optimisticLocking')
              );
            } else {
              const translateVar = this.translate.instant(
                'question.questionDialogs.code.submitError'
              );
              this.toastr.error(
                this.isUpdate ? translateVar.updateError : translateVar.addError
              );
            }
          }
        );
    })();
  }

  private sendNotifSuccess(message: string): void {
    const options = cloneDeep(this.toastr.toastrConfig);
    options.toastComponent = NotyfToast;
    options.toastClass = 'notyf confirm';
    this.toastr.success(message, null, options);
  }

  async onExecute() {
    this.executingCode = true;
    const formattedCode = removeDiacritics(this.questionCode.codeComplet);
    await this.questionService
      .executeCode(formattedCode, this.form.value)
      .pipe(delay(1000))
      .toPromise()
      .then((jdataOutput) => {
        this.jdataOuput = jdataOutput;

        this.dialog.open(ExecuteCodeDialogComponent, {
          width: '500px',
          data: { resultat: this.jdataOuput.output },
        });
      })
      .catch((error) => {
        this.toastr.error(
          this.translate.instant('question.questionDialogs.code.codeError')
        );
      });
    this.executingCode = false;
  }

  addCategorie(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      const cat = new Categorie();
      cat.nom = value.trim();
      this.categorieService.nouvellesCategories.push(cat);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeCategorie(categorie: Categorie): void {
    let index = -1;
    for (
      let i = 0;
      i < this.categorieService.nouvellesCategories.length;
      i += 1
    ) {
      if (
        this.categorieService.nouvellesCategories[i]['nom'] === categorie.nom
      ) {
        index = i;
      }
    }

    if (index >= 0) {
      this.categorieService.nouvellesCategories.splice(index, 1);
    }
  }

  onTextChanged(value) {
    this.questionCode.codeComplet = value;
  }

  btnClick = function () {
    this.location.back();
  };
}
