<div class="content" fxLayoutAlign="center none">

  <app-api-card
    data-intro="Bienvenue!"
    fxFlex
    [title]="translate.instant('home.sweetHome.title')"
    subTitle="APTEA"
    imgUrl="assets/image/foo.png"
    content="{{this.userService.currentUser? '': ''}}"
    apiText="{{this.userService.currentUser? translate.instant('home.sweetHome.mySpace'):
    translate.instant('actions.connexion')}}"
    url='/login'>
  </app-api-card>

</div>
