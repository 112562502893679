import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {UserService} from '../../service';
import * as introJs from 'intro.js/intro';
import {TranslateService} from '@ngx-translate/core';
import {AppRoles} from '../../shared/utilities/AppRoles';

/// Accueil
@Component({
  selector: 'app-sweet-home',
  templateUrl: './sweet-home.component.html',
  styleUrls: ['./sweet-home.component.scss']
})
export class SweetHomeComponent implements AfterViewInit {

  public introJS: any = introJs();
  private translateVars;

  constructor(@Inject(UserService) public userService: UserService,
              public translate: TranslateService) {
  }

  showCandidateConnexionWarning() {

    function delay(n) {
      n = n || 2000;
      return new Promise<void>(done => {
        setTimeout(() => {
          done();
        }, n);
      });
    }

    const customIntroJS = {
      steps: [{
        element: '#step1',
        intro: this.translateVars.introCandidate.step1,
        position: 'bottom'
      },
        {
          element: '#step2',
          intro: this.translateVars.introCandidate.step2,
          position: 'top'
        }],
      showProgress: true,
      prevLabel: '<strong>' + this.translateVars.introCandidate.previous + '</strong>',
      nextLabel: '<strong>' + this.translateVars.introCandidate.next + '</strong>',
      skipLabel: '<strong>' + this.translateVars.introCandidate.skip + '</strong>',
      doneLabel: '<strong>' + this.translateVars.introCandidate.letsGo + '</strong>'
    };
    this.introJS.setOptions(customIntroJS);
    this.introJS.onbeforechange(
      function () {
        if (this._currentStep === 1) {
          (async function () {
            await delay(1000);
            document.getElementById('step2').classList.add('frozen');
            await delay(2000);
            document.getElementById('step2').classList.remove('frozen');
          })();
        }
      }
    );

    setTimeout(() => {
      this.introJS.start();
    }, 1000);
  }

  showApteaMemberTip() {

    const customIntroJS = {
      steps: [{
        element: '#step1',
        intro: this.translateVars.introApteaMember.step1,
        position: 'bottom'
      }],
      showProgress: false,
      doneLabel: '<strong>' + this.translateVars.introApteaMember.thanks + '</strong>'
    };
    this.introJS.setOptions(customIntroJS);
    this.introJS.oncomplete(function () {
      localStorage.setItem('doneTour', 'neverAgain');
    });
    this.introJS.onexit(function () {
      localStorage.setItem('doneTour', 'neverAgain');
    });
    setTimeout(() => {
      this.introJS.start();
    }, 1000);
  }

  setWelcomeMessage() {

    const currentUser = this.userService.currentUser;
    const isThereCurrentUser = !!currentUser;

    if (!isThereCurrentUser) {
      return this.showApteaMemberTip();
    }
    if (currentUser.profile === AppRoles.CANDIDAT) {
      this.showCandidateConnexionWarning();
    }
  }

  ngAfterViewInit() {
    const doneTour = localStorage.getItem('doneTour') === 'neverAgain';
    if (doneTour) {
      return;
    }

    this.translate.get('home.sweetHome').subscribe(value => {
      this.translateVars = value;

      this.setWelcomeMessage();
    });
  }

}
