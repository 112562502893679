<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="content" fxLayout="row" fxLayoutAlign="center">
    <mat-card>
      <mat-card-title>
        <h2 align="center">
          {{(isUpdate ? translate.instant('question.questionDialogs.open.title.update') :
          translate.instant('question.questionDialogs.open.title.add')) +
        translate.instant('question.questionDialogs.open.title.message')}}
        </h2>
      </mat-card-title>
      <br/>

      <app-data-loading-spinner [loadingData]="loadingInitData"></app-data-loading-spinner>

      <mat-card-content [hidden]="loadingInitData">
        <div class="row">
          <div class="col-lg-6">
            <div align="center">
              <mat-form-field class="taille-form">
                <input matInput [(ngModel)]="questionLibre.titre" formControlName="newTitre" matTextareaAutosize
                       matAutosizeMinRows=1 matAutosizeMAxRows=6
                       placeholder="{{translate.instant('fields.questionTitle')}}">
                <mat-icon matSuffix>title</mat-icon>
              </mat-form-field>
            </div>

            <mat-card-title>
              <h5>{{translate.instant('fields.categories')}}</h5>
            </mat-card-title>
            <div align="center">
              <div class="taille-form">
                <ng-multiselect-dropdown
                  formControlName="formCategories"
                  [placeholder]="translate.instant('actions.choseCategories')"
                  [settings]="categorieService.dropdownCategoriesSettings"
                  [data]="categorieService.allCategories"
                  [(ngModel)]="categorieService.categoriesActuelle">
                </ng-multiselect-dropdown>
              </div>
            </div>
            <div align="center">
              <mat-form-field class="taille-form">
                <mat-chip-list #cat>
                  <mat-chip *ngFor="let qc of this.categorieService.nouvellesCategories" [selectable]="selectable"
                            [removable]="removable" (removed)="removeCategorie(qc)">
                    {{qc.nom}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
                <input type="hidden" [(ngModel)]="categorieService.nouvellesCategories" name="nouvellesCategories"
                       formControlName="nouvellesCategories">
                <input placeholder="{{translate.instant('actions.addCategory')}}"
                       [matChipInputFor]="cat"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="addOnBlur"
                       (matChipInputTokenEnd)="addCategorie($event)">
              </mat-form-field>
            </div>
          </div>

          <div class="col-lg-6">
            <div align="center">
              <mat-form-field class="taille-form">
                <input type="number" [min]="1" appPositiveNumber matInput [(ngModel)]="questionLibre.duration"
                       formControlName="duration" matTextareaAutosize matAutosizeMinRows=1 matAutosizeMAxRows=6
                       placeholder="{{translate.instant('fields.durationMsg')}}"/>
                <mat-icon matSuffix>timer</mat-icon>
              </mat-form-field>
            </div>

            <div align="center">
              <mat-form-field class="taille-form">
                <mat-select [(ngModel)]="questionLibre.difficulty" formControlName="difficulte"
                            placeholder="{{translate.instant('fields.difficultyMsg')}}">
                  <mat-option value="{{ questionLibre.difficulty }}"></mat-option>
                  <mat-option [value]=1>1</mat-option>
                  <mat-option [value]=2>2</mat-option>
                  <mat-option [value]=3>3</mat-option>
                  <mat-option [value]=4>4</mat-option>
                  <mat-option [value]=5>5</mat-option>
                </mat-select>
                <mat-icon matSuffix>school</mat-icon>
              </mat-form-field>
            </div>

            <div align="center">
              <mat-form-field class="taille-form">
                <mat-chip-list #response>
                  <mat-chip *ngFor="let response of this.responseLabels" [selectable]="selectable"
                            [removable]="removable" (removed)="removeResponse(response)">
                    {{response}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
                <input type="hidden" [(ngModel)]="questionLibre.resultat" name="newResponse"
                       formControlName="newResponse">
                <input placeholder="{{translate.instant('question.questionDialogs.open.fields.response')}}"
                       [matChipInputFor]="response"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="addOnBlur"
                       (matChipInputTokenEnd)="addResponse($event)">
                <mat-icon matSuffix>question_answer</mat-icon>
              </mat-form-field>
            </div>
          </div>

          <div align="center" class="col-lg-12">
            <mat-form-field class="taille-form">

              <label>{{translate.instant('fields.question')}}</label>
              <textarea class="d-none" matInput [(ngModel)]="questionLibre.enonce"
                        formControlName="newQuestion" matTextareaAutosize></textarea>

              <ejs-richtexteditor [(value)]='questionLibre.enonce'
                                  [toolbarSettings]='constants.RICH_TEXT_EDITOR_TOOLS'
                                  [quickToolbarSettings]='constants.RICH_TEXT_EDITOR_QUICK_TOOLS'>
              </ejs-richtexteditor>
            </mat-form-field>
          </div>

          <div align="center" class="col-lg-12">
            <mat-form-field class="taille-form">
              <textarea matInput formControlName="referenceAnswer"
                        matTextareaAutosize matAutosizeMinRows=4
                        placeholder="{{translate.instant('fields.waitingResponse')}}"></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>

      <mat-action-list>
        <div class="row d-flex flex-row-reverse px-5">
          <div fxLayout="row">
            <button *ngIf="questionLibre" type="submit" mat-raised-button color="primary"
                    [disabled]="form.invalid || submitted" class="min-width-100-px"
                    [ngClass]="{'min-width-120-px': submitted}">
            <span *ngIf="submitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
            </span>
              {{submitted ? translate.instant('actions.loading') :
              (isUpdate ? translate.instant('actions.update') : translate.instant('actions.add'))}}
            </button>

            <div class="divider"></div>

            <button type="button" mat-raised-button color="warn" class="btn btn-primary-outline pull-right" (click)="btnClick();">
              {{translate.instant('actions.cancel')}} 
            </button>
          </div>
        </div>
      </mat-action-list>
    </mat-card>
  </div>
</form>
