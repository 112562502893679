<div class="content login-container loginContainer container" fxLayout="row" fxLayoutAlign="center">

  <mat-card fxFlex>

    <mat-card-subtitle>
      <h2>Code Level</h2>
    </mat-card-subtitle>

    <mat-card-title>
      <h2>{{translate.instant('login.title')}}</h2>
    </mat-card-title>

    <mat-card-content>
      <form *ngIf="!submitted" [formGroup]="form" (ngSubmit)="onSubmit()" #loginForm="ngForm">
        <mat-form-field>
          <input id="username" matInput formControlName="username" required
                 [placeholder]="translate.instant('login.user')">
        </mat-form-field>
        <mat-form-field>
          <input id="password" matInput formControlName="password" required [type]="hide ? 'password' : 'text'"
                 [placeholder]="translate.instant('login.password')">
          <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <button type="submit" [disabled]="!loginForm.form.valid || authenticating" mat-raised-button
                color="primary">
          <span *ngIf="authenticating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{authenticating ? translate.instant('actions.loading') : translate.instant('actions.login')}}
        </button>
      </form>
      <br>

      <mat-spinner *ngIf="submitted" mode="indeterminate"></mat-spinner>
      <br>
      <hr>


    </mat-card-content>

  </mat-card>

</div>
