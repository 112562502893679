import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-data-loading-spinner',
  templateUrl: './data-loading-spinner.component.html',
  styleUrls: ['./data-loading-spinner.component.scss']
})
export class DataLoadingSpinnerComponent implements OnInit {

  @Input() loadingData;

  constructor(public translate: TranslateService) {
  }

  ngOnInit(): void {
  }

}
