<div [formGroup]="formGroup">
  <div *ngIf="isReady" formArrayName="propositions">
    <ul>
      <li *ngFor="let prop of qcmPropositions">
        <mat-checkbox  color="primary">
          <span [innerHTML]="prop.proposition.description"></span>
        </mat-checkbox>
      </li>
    </ul>
  </div>
</div>
