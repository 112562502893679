<div [class]="getContainerClass()">
  <div class="notyf__wrapper">
    <div class="notyf__icon">
      <i [class]="getMessageClass()"></i>
    </div>
    <div class="notyf__message">{{ title }} {{ message }}</div>
  </div>
  <div
    [class]="getRippleClass()"
  ></div>
</div>
