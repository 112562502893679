import {ConfigService} from './config.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Categorie} from '../models/categorie';
import {Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class CategorieService {

  // les variables suivantes sont utilisées dans tous les components où une liste de catégories est sélectionnable
  // (création/édition de questions et de formulaires...)
  public allCategories: Array<Categorie> = []; // toutes les catégories de la BDD
  public categoriesActuelle: Array<Categorie> = []; // catégorie actuellement sélectionnées localement pour ce questionnaire
  // catégories créées dans cette interface (à ajouter en BDD si le formulaire est validé)
  public nouvellesCategories: Array<Categorie> = [];

  private _dropdownCategoriesSettings = undefined;

  constructor(@Inject(HttpClient) private httpClient: HttpClient,
              @Inject(ConfigService) private config: ConfigService,
              private toastr: ToastrService,
              public translate: TranslateService) {

  }

  get dropdownCategoriesSettings(): {} {
    const value = this.translate.instant('fields.dropDown');
    this._dropdownCategoriesSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'nom',
      selectAllText: value.all,
      unSelectAllText: value.any,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    return this._dropdownCategoriesSettings;
  }

  // ces fonctions de reset adaptent les données du service à la page actuelle.
  // Elles doivent être appelées au chargement d'une page d'édition de questionnaire ou question
  resetDataPourQuestionnaire(id) {
    this.resetData(true);

    this.getCategoriesByQuestionnaire(id).toPromise().then(categories => {
      this.categoriesActuelle = categories;
    });
  }

  resetDataPourQuestion(id): Observable<Categorie[]> {
    this.resetData(false);

    const obs: Observable<Categorie[]> = this.getCategoriesByQuestion(id);

    obs.toPromise().then(categories => {
      this.categoriesActuelle = categories;
    });

    return obs;
  }

  /* set validOnly to limit to categories containing at least one question that have been validated at least once before */
  async resetData(validOnly?: boolean) {
    this.categoriesActuelle = [];
    this.nouvellesCategories = [];
    this.allCategories = [];

    await this.getAllCategories(validOnly).toPromise().then(categoriesList => {
      this.allCategories = categoriesList;
    }, error1 => {
      this.toastr.error(this.translate.instant('toastr.error.getCategoriesError'));
    });
  }

  getCategoriesByQuestion(id): Observable<Categorie[]> {
    return this.httpClient.get<Categorie[]>(this.config.questions_url + '/' + id + '/categories')
  }

  getCategoriesByQuestionnaire(id): Observable<Categorie[]> {
    return this.httpClient.get<Categorie[]>(this.config.questionnaires_url + '/' + id + '/categories')
  }

  /* set validOnly to limit to categories containing at least one question that have been validated at least once before */
  getAllCategories(validOnly?: boolean): Observable<Categorie[]> {
    const options = {
      params: new HttpParams().append('validOnly', validOnly ? 'true' : 'false')
    };
    return this.httpClient.get<Categorie[]>(this.config.categories_url, options);
  }
}
