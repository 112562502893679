import { Questionnaire } from './questionnaire';
import { TestCandidatEntry } from './testCandidatEntry';

/**
 * Cette classe représente un testcandidat qui a été généré à partir d'une invitation.
 * selon le contexte, la propriété entries regroupant les données relatives à chaque
 * question (question avec solution attendue, réponse du candidat, revue) peut être absent.
 */
export class TestCandidat {
  id: number;
  dateDebutTest: Date;
  dateFinTest: Date;
  questionnaire: Questionnaire;
  entries: TestCandidatEntry[];
  note: number;
  noteSansPen: number;
  score: number;
  scoreSansPen: number;
  maxScore: number;
  simulation: boolean;
}
