import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService , UserService} from '../service';
import { Observable } from 'rxjs';
import {MembreAptea} from '../models/membreAptea';
import {AppRoles} from '../shared/utilities/AppRoles';


/**
 * Ce guard est conçu pour fonctionner avec un paramètre contenant l'identifiant du rôle qui a les droits d'accès
 * De base, sans ce paramètre, personne ne peut accéder à la page
 * exemple dans le cas où seul les rh et les administateurs  de questionnaire pourront accéder :
    canActivate: [AuthGuard],
    data: {roles: ['ROLE_ADMIN', 'ROLE_RH']},
 */
@Injectable()
export class AuthGuard implements CanActivate {

  pageAuthorizedRoles: Array<string>;

  constructor(@Inject(Router) private router: Router,
    @Inject(UserService) private userService: UserService,
    @Inject(AuthService) private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  Observable<boolean> | Promise<boolean> | boolean {

    // on récupère le paramètre 'roles' pour déterminer qui a le droit d'accéder à la page et autoriser ou pas en conséquence
    this.pageAuthorizedRoles = route.data['roles'] as Array<string>;
    return this.isMembrePage(state.url);
  }

  isMembrePage(returnUrl: string): Promise<boolean> {
    return new Promise(
      (resolve, reject) => {
        if (this.userService.currentUser) {
          const authenticatedMember = this.userService.currentUser;
          for (let i = 0; i < this.pageAuthorizedRoles.length; i++) {
            const role = this.pageAuthorizedRoles[i];
            if (authenticatedMember.profile === role) {
                resolve(true);
                return;
            }
          }

          // si le rôle n'a pas le droit d'accès on le redirige vers sa page de base :
          this.redirectToPage(authenticatedMember, resolve);
        } else {
          this.router.navigate(['login'], { queryParams: { returnUrl: returnUrl }});
          resolve(false);
        }
      }
    );
  }

  private redirectToPage(authenticatedMember: MembreAptea, resolve: (value?: boolean | PromiseLike<boolean>) => void) {
    let redirectUrl = '/login';
    if (authenticatedMember) {
      switch (authenticatedMember.profile) {
        case AppRoles.ADMIN:
        case AppRoles.RH:
          redirectUrl = '/candidats';
          break;
        case AppRoles.TOUT_APTEA:
          redirectUrl = '/home';
          break;
        case AppRoles.CANDIDAT:
          redirectUrl = '/home-candidat';
          break;
        case AppRoles.REDACTEUR:
          redirectUrl = '/questions';
          break;
        default:
          redirectUrl = '/403';
          break;
      }
    }
    this.router.navigate([redirectUrl]);
    resolve(false);
  }
}
