import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

export interface ConfirmActionDialogData {
  dialogTitle: string;
  dialogMessage: string;
  actionConfirmed: boolean;
  cancelButtonLabel?: string;
  disableConfirmButton?: boolean;
}

@Component({
  selector: 'app-confirm-action-dialog',
  templateUrl: './confirm-action-dialog.component.html',
  styleUrls: ['./confirm-action-dialog.component.scss']
})
export class ConfirmActionDialogComponent implements OnInit {

  constructor(@Inject(MatDialogRef) private confirmActionDialogRef: MatDialogRef<ConfirmActionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmActionDialogData,
              public translate: TranslateService) {
    confirmActionDialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  async confirmAction() {
    this.confirmActionDialogRef.close({actionConfirmed: true});
  }
}
