<div style="padding: 15px; margin: 5px; border-style: dashed;border-color: lightgrey; border-radius: 1em">

  <div class="reponse-container" style="padding: 15px; margin: 5px" *ngIf="entry.reponse">
    <div>
      <label>{{translate.instant('testReview.openQuestion.fields.response')}}</label>
      <p *ngIf="highlightedReponseText && showHighlighted; else originalReponseText" class="user-comment"
         [innerHTML]="highlightedReponseText"></p>
      <ng-template #originalReponseText><p class="user-comment"><span
        *ngIf="entry.reponse.text">{{entry.reponse.text}}</span></p></ng-template>
    </div>
    <button type="button" mat-icon-button [class]="highlightClass()" (click)="onHighlightClick()">
      <mat-icon [matTooltip]="highlightTooltip()" matTooltipPosition="above">find_in_page</mat-icon>
    </button>
  </div>
  <div *ngIf="keywords" style="margin-bottom: 20px">
    <label for="keywords">{{translate.instant('testReview.openQuestion.fields.foundKeyWords')}}</label>
    <mat-chip-list id="keywords" aria-label="keywords">
      <mat-chip *ngFor="let keyword of keywords" class="keyword">{{keyword}}</mat-chip>
    </mat-chip-list>
  </div>
  <div>
    <label>{{translate.instant('testReview.openQuestion.fields.waitingResponse')}}</label>
    <p>{{asQuestionLibre(entry.question).referenceAnswer}}</p>
  </div>
</div>
