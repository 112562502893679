<div class="source-code-from">
  <div *ngIf="isReady">
    <mat-slide-toggle class="float-right" color="primary" (change)="switchEditor()">
      {{translate.instant('fields.aceEditor')}}
    </mat-slide-toggle>
    <div>
      <ace-editor
        *ngIf="!useMonacoEditor"
        #aceEditor
        [(text)]="reponse.sourceCode"
        [mode]="utils.getQuestionCodeChosenLanguage(question.technologie, false, true)"
        theme="eclipse"
        class="ace-editor"
        id="ace-editor">
      </ace-editor>

      <ngx-monaco-editor
        *ngIf="useMonacoEditor"
        (init)="editorInit($event)"
        [options]="{theme: appTheme.globalTheme === appTheme.light? 'vs' : 'vs-dark', contextmenu: false,
              language: utils.getQuestionCodeChosenLanguage(question.technologie, false, false)}"
        [(ngModel)]="reponse.sourceCode"
        formControlName="sourceCodeMonaco"
        id="monaco-editor">
      </ngx-monaco-editor>
    </div>
    <br/>

    <div class="d-flex justify-content-center">
      <button type="button" (click)="onExecute()" mat-raised-button color="primary" [disabled]="codeExecuting">
        <span *ngIf="codeExecuting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
        </span>

        <mat-icon *ngIf="!codeExecuting">play_circle_outline</mat-icon>

        {{codeExecuting ? translate.instant('actions.executing') : translate.instant('actions.execute')}}
      </button>
    </div>
    <br/>

    <div *ngIf="jdataOutput?.output">
      <div class="d-flex justify-content-center">
        {{translate.instant('fields.programmeOutput')}}
      </div>
      <div class="d-flex justify-content-center">
        <label class="w-100">
          <textarea class="w-100 min-height-100px" placeholder="Sortie du programme" [value]="jdataOutput.output"
                    readonly></textarea>
        </label>
      </div>
    </div>

  </div>
</div>
