import {Component, Inject, OnInit} from '@angular/core';
import {AuthService, ConfigService, MembreService} from '../../service';
import {Router} from '@angular/router';
import {MembreAptea} from '../../models/membreAptea';
import {ToastrService} from 'ngx-toastr';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  authenticatedMember: MembreAptea;
  rows: MembreAptea[];
  columnMode = ColumnMode;

  constructor(
    @Inject(AuthService) private authService: AuthService,
    @Inject(Router) private router: Router,
    @Inject(MembreService) private membreService: MembreService,
    private toastr: ToastrService,
    public translate: TranslateService) {
  }

  ngOnInit() {
    this.detectCurrentMember();
    this.listOfMembreAptea();
  }

  detectCurrentMember() {
    this.authService.getCurrentMembreAptea().subscribe(authenticatedMember => {
      this.authenticatedMember = authenticatedMember;
    }, error => {
      this.toastr.error(this.translate.instant('toastr.error.connexionError'));
      this.router.navigate(['/401']);
    });
  }

  listOfMembreAptea() {
    this.membreService.listMembreAptea().subscribe(questions => {
      this.rows = <MembreAptea[]>questions;
    }, error => {
      // this.router.navigate(['/404']);
    });
  }

  editMembre(membre: MembreAptea) {
  }

  deleteMembre(membre: MembreAptea, rows) {
  }
}
