import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent {

  constructor(public translate: TranslateService) {
  }

}
