<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  {{data.dialogTitle}}
  <span class="close margin-left-20px" aria-label="Close" mat-dialog-close>
    <span aria-hidden="true">&times;</span>
  </span>
</h2>

<mat-card mat-dialog-content>
  <mat-card-content>
    {{data.dialogMessage}}
  </mat-card-content>
</mat-card>

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="confirmAction()" *ngIf="!data.disableConfirmButton">
    {{translate.instant('actions.confirm')}}
  </button>
  &nbsp;

  <button mat-raised-button mat-dialog-close
          color="warn">
    {{data.cancelButtonLabel ? data.cancelButtonLabel : translate.instant('actions.cancel')}}
  </button>
</mat-dialog-actions>
