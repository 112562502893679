import {
  transition,
  trigger,
  query,
  style,
  animate, animateChild, group, state
} from '@angular/animations';

const mySlideCardEffect = [
  style({position: 'relative'}),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%'
    })
  ]),
  query(':enter', [
    style({left: '-100%'})
  ]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate('300ms ease-out', style({left: '100%'}))
    ]),
    query(':enter', [
      animate('300ms ease-out', style({left: '0%'}))
    ])
  ]),
  query(':enter', animateChild()),
];
const myTransitionEffect = [
  query(':enter, :leave', [
    style({
      position: 'absolute',
      width: '91%',
      opacity: 0,
    }),
  ]),
  // Animate the new page in
  query(':enter', [
    animate('600ms ease', style(
      {opacity: 1})
    ),
  ])
];

export const routeTransitionAnimations =
  trigger('myAnimation', [
    transition('HomePage  <=> LoginPage', mySlideCardEffect),
    transition('QuestionnairePage  <=> QuestionPage', myTransitionEffect),
    transition('QuestionnairePage  <=> InvitationPage', myTransitionEffect),
    transition('QuestionPage  <=> InvitationPage', myTransitionEffect)
  ]);
