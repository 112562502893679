import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-fake',
  templateUrl: './fake.component.html',
  styleUrls: ['./fake.component.css']
})
export class FakeComponent implements OnInit {


  constructor() {
  }

  ngOnInit(): void {
  }

  onSubmit() {
  }

  timerCompleted(event: Event) {
    alert('top tip top fini coco!');
  }
}
