import {Component, OnInit, Input} from '@angular/core';
import {TestCandidatEntry} from 'app/models/testCandidatEntry';
import {QuestionCode} from 'app/models/question.code';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-review-question-code',
  templateUrl: './review-question-code.component.html',
  styleUrls: ['./review-question-code.component.css']
})
export class ReviewQuestionCodeComponent implements OnInit {

  @Input() testCandidatId: number;
  @Input() entry: TestCandidatEntry;

  expectedResult: string;

  constructor(public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.expectedResult = (this.entry.question as QuestionCode).resultat;
  }
}
