import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {ErreursAcces, InvitationService} from '../../service/invitation.service';
import {InvitationRoutes} from 'app/constants/InvitationRoutes';

import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

/**
 * Cette page est la page de "référence" du candidat par laquelle il arrive et est identifié
 * On y affiche son nom, sauf si il ne l'a pas entré dans ce cas on lui demande
 * si il a terminé son questionnaire on le remercie sinon on lui affiche un lien vers le questionnaire
 */
@Component({
  selector: 'app-home-invitation',
  templateUrl: './home-invitation.component.html',
  styleUrls: ['./home-invitation.component.css']
})
export class HomeInvitationComponent implements OnInit {

  messageErreur = '';
  token = '';
  form: FormGroup;

  private mode: string;

  constructor(
    @Inject(Router) private router: Router,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(InvitationService) public invitationService: InvitationService,
    public translate: TranslateService) {
    this.mode = this.router.url.startsWith('/' + InvitationRoutes.simulation.home) ? 'simulation' : 'candidat';
  }

  ngOnInit() {


    this.form = this.formBuilder.group({
      checkRgpd: [false, [Validators.requiredTrue]],
    });


    if (this.invitationService.isInvitationEnTest()) {

      const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'preserve',
        preserveFragment: true
      };
      this.router.navigate(['/' + InvitationRoutes[this.mode].repondre], navigationExtras);
    } else {
      this.activatedRoute.queryParams.subscribe(params => {

        this.token = params ['token'] as string;
        const paramErreur = params['erreur'] as number;

        if (paramErreur !== undefined) {
          const erreur: ErreursAcces = paramErreur;
          this.messageErreur = this.invitationService.getMessageErreurStr(erreur);
        }
      });
    }
  }

  repondreTestRoute(): string {
    return '/' + InvitationRoutes[this.mode].repondre;
  }

}
