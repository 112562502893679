import {Component, OnInit, Input, Inject} from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Proposition } from 'app/models/proposition';
import { Question } from 'app/models/question';
import { QuestionChoixMultiple } from 'app/models/question.choix.multiple';

export interface QCMProposition {
  proposition: Proposition;
  selected: boolean;
}

@Component({
  selector: 'app-preview-question-choix-multiple',
  templateUrl: './preview-question-choix-multiple.component.html',
  styleUrls: ['./preview-question-choix-multiple.component.css']
})
export class PreviewQuestionChoixMultipleComponent implements OnInit {

  @Input() question: QuestionChoixMultiple;
  @Input() formGroup?: FormGroup;

  qcmPropositions: QCMProposition[] = [];
  isReady = false;

  constructor(@Inject(FormBuilder) private formBuilder: FormBuilder) { }

  ngOnInit() {
    const sorter = (n1: QCMProposition, n2: QCMProposition) => { return n1.proposition.order - n2.proposition.order; }
    this.qcmPropositions = this.question.propositions.map(p => { return { proposition: p, selected: false } as QCMProposition; })
      .sort(sorter);
    if (!this.formGroup) {
      this.formGroup = this.formBuilder.group({});
    }
    console.log(this.qcmPropositions.length);
    this.formGroup.addControl('propositions', this.formBuilder.array([]));
    for (const prop of this.qcmPropositions) {
      this.propositionsFormArray.push(this.formBuilder.control(false));
    }
    this.isReady = true;
  }

  get propositionsFormArray(): FormArray {
    return this.formGroup.get('propositions') as FormArray;
  }


}
