<h5>
  <div *ngIf="isChecked()==true && noteSansPen!==null" >
    <span class="badge badge-pill"
        [ngClass]="{'badge-success': noteSansPen>=75, 'badge-warning': noteSansPen<75 && noteSansPen>=50, 'badge-danger': noteSansPen<50}">
        {{noteSansPen | number:'0.0-2'}} %
    </span>
  </div>
  <div *ngIf="isChecked()==true && noteSansPen===null" >
    <span class="badge badge-pill"
        [ngClass]="{'badge-success': score>=75, 'badge-warning': score<75 && score>=50, 'badge-danger': score<50}">
        {{score | number:'0.0-2'}} %
    </span>
  </div>
  <div *ngIf="isChecked()==false">
    <span class="badge badge-pill"
        [ngClass]="{'badge-success': score>=75, 'badge-warning': score<75 && score>=50, 'badge-danger': score<50}">
        {{score | number:'0.0-2'}} %
    </span>
  </div>
</h5>
