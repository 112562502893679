<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  {{ translate.instant("invitations.newInvitations.title") }}
  <span class="close margin-left-20px" aria-label="Close" mat-dialog-close>
    <span aria-hidden="true">&times;</span>
  </span>
</h2>

<div mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div
    class="content"
    fxLayout="row"
    fxLayoutAlign="center"
    (focus)="blurEvent()"
    (blur)="blurEvent()"
  >
    <mat-card fxFlex>
      <mat-error>
        <p *ngIf="messageErreur != ''">
          {{ messageErreur }}
          <mat-divider></mat-divider>
        </p>
      </mat-error>

      <mat-card-content>
        <app-data-loading-spinner
          [loadingData]="loadingInitData"
        ></app-data-loading-spinner>

        <div *ngIf="!loadingInitData">
          <form [formGroup]="form" #inviteForm="ngForm">
            <ng-multiselect-dropdown
              formControlName="questionnaire"
              [placeholder]="
                translate.instant(
                  'invitations.newInvitations.choseQuestionnaire'
                )
              "
              [settings]="dropdownQuestionnairesSettings"
              [data]="allQuestionnaires"
            >
            </ng-multiselect-dropdown
            ><!--[(ngModel)]="testcandidat.questionnaire"-->
            <br />

            <mat-form-field>
              <input
                id="email"
                matInput
                [(ngModel)]="candidat.email"
                (blur)="blurEvent()"
                (focus)="blurEvent()"
                formControlName="email"
                required
                [placeholder]="translate.instant('fields.email')"
              />
              <mat-icon matSuffix *ngIf="result">
                <i
                  class="fa fa-exclamation-circle"
                  style="color: red"
                  aria-hidden="true"
                  [matTooltip]="
                    translate.instant('invitations.newInvitations.emailExists')
                  "
                >
                </i>
              </mat-icon>
            </mat-form-field>

            <mat-form-field>
              <input
                id="firstname"
                style="overflow-y: hidden"
                matInput
                type="string"
                formControlName="firstname"
                required
                [placeholder]="translate.instant('fields.firstName')"
                (blur)="blurEvent()"
                (focus)="blurEvent()"
              />
              <mat-icon matSuffix>face</mat-icon>
            </mat-form-field>

            <mat-form-field>
              <input
                id="name"
                style="overflow-y: hidden"
                matInput
                type="string"
                formControlName="name"
                required
                [placeholder]="translate.instant('fields.name')"
              />
              <mat-icon matSuffix>face</mat-icon>
            </mat-form-field>

            <mat-form-field>
              <input
                id="xp"
                style="overflow-y: hidden"
                matInput
                type="number"
                appPositiveNumber
                min="0"
                formControlName="xp"
                [placeholder]="translate.instant('fields.experienceYears')"
              />
              <mat-icon matSuffix>school</mat-icon>
            </mat-form-field>
            <mat-form-field>
              <input
                id="invitationDate"
                style="overflow-y: hidden"
                matInput
                type="datetime-local"
                appPositiveNumber
                formControlName="invitationDate"
                [placeholder]="translate.instant('invitations.invitationDate')"
              />
            </mat-form-field>
            <button
              [disabled]="!inviteForm.form.valid || submitted"
              mat-raised-button
              color="primary"
              (click)="onSubmit()"
            >
              <span
                *ngIf="submitted"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              {{
                submitted
                  ? translate.instant("actions.loading")
                  : translate.instant("invitations.newInvitations.sendTest")
              }}
            </button>
            <br />
            <button
              [disabled]="submitted"
              mat-raised-button
              mat-dialog-close
              color="warn"
            >
              {{ translate.instant("actions.cancel") }}
            </button>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
