import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-gateaway-timeout',
  templateUrl: './gateaway-timeout.component.html',
  styleUrls: ['./gateaway-timeout.component.css']
})
export class GateawayTimeoutComponent implements OnInit {

  constructor(public translate: TranslateService) {
  }

  ngOnInit() {
  }

}
