<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  {{(data.questionnaireId ? translate.instant('actions.modification') : translate.instant('actions.creation')) +
translate.instant('questionnaire.newQuestionnaire.title')}}
  <span class="close margin-left-20px" aria-label="Close" mat-dialog-close>
    <span aria-hidden="true">&times;</span>
  </span>
</h2>

<app-data-loading-spinner [loadingData]="loadingInitData"></app-data-loading-spinner>

<div *ngIf="!loadingInitData">
  <form mat-dialog-content [formGroup]="form" #questionnaireForm="ngForm">
    <div class="content" fxLayout="row" fxLayoutAlign="center">
      <mat-card>
        <mat-card-content>
          <mat-form-field>
            <input matInput [(ngModel)]="questionnaire.nom" formControlName="nom"
                   placeholder="{{translate.instant('questionnaire.newQuestionnaire.questionnaireName')}}">
          </mat-form-field>

          <mat-card-title>
            <h5>{{translate.instant('fields.categories')}}</h5>
          </mat-card-title>

          <div>
            <ng-multiselect-dropdown
              formControlName="formCategories"
              [placeholder]="translate.instant('actions.choseCategories')"
              [settings]="categorieService.dropdownCategoriesSettings"
              [data]="categorieService.allCategories"
              [(ngModel)]="categorieService.categoriesActuelle"
              (onSelect)="onCategoriesSelect($event)"
              (onSelectAll)="onCategoriesSelect($event)"
              (onDeSelect)="onCategoriesSelect($event)"
              (onDeSelectAll)="onCategoriesDeSelectAll($event)">
            </ng-multiselect-dropdown>
            <div *ngIf="hasEmptyCategories()">
              <mat-icon color="warn" class="align-middle">warning</mat-icon>
              <span>
                {{translate.instant('questionnaire.newQuestionnaire.categoriesWarning') + emptyCategoriesAsString()}}
              </span>
            </div>
          </div>
          <br/>

          <mat-card-title>
            <h5>{{translate.instant('component.header.navBarItems.questions')}}</h5>
          </mat-card-title>

          <div>
            <ag-grid-angular
              #agGrid style="width: 100%; height: 25vh;"
              [ngClass]="{'ag-theme-alpine-dark': appTheme.globalTheme === appTheme.dark,
                'ag-theme-balham': appTheme.globalTheme === appTheme.light || appTheme.globalTheme === appTheme.mix}"
              [columnDefs]="columnDefs"
              [rowData]="rowData"
              [pagination]="pagination"
              [defaultColDef]="defaultColDef"
              (gridReady)="onGridReady($event)">
            </ag-grid-angular>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </form>

  <mat-dialog-actions align="end">
    <div fxLayout="row">
      <button [disabled]="loadingInitData || !questionnaireForm.form.valid || submitted" mat-raised-button
              color="primary"
              (click)="onSubmit()">
                <span *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
        {{submitted ? translate.instant('actions.loading') : translate.instant('actions.validate')}}
      </button>
      <div class="divider"></div>
      <button [disabled]="submitted" mat-raised-button mat-dialog-close color="warn">
        {{translate.instant('actions.cancel')}}
      </button>
    </div>
  </mat-dialog-actions>
</div>
