import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {timer} from 'rxjs';
import {map} from 'rxjs/operators';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'chrono',
  templateUrl: './chrono.component.html',
  styleUrls: ['./chrono.component.scss']
})
export class ChronoComponent implements OnInit {
  @Input() value: number;
  @Input() baseValue: number;
  // tslint:disable-next-line:no-output-rename
  @Output('onComplete') timerOver: EventEmitter<any> = new EventEmitter<any>();
  timerValue;
  areTenSecsRemainings = false;

  constructor() {
  }

  almostDone(): string {
    if (this.timerValue.count < 3) {
      return 'blink'
    }
  }

  ngOnInit() {
    const source$ = timer(0, 1000).pipe(
      map(x => this.value - x)
    );

    source$.subscribe(seconds => {
      const absSeconds = seconds < 0 ? -seconds : seconds;
      // tslint:disable-next-line:radix
      let mins = parseInt('' + absSeconds / 60);
      const secs = absSeconds % 60;
      // tslint:disable-next-line:radix
      const hrs = parseInt('' + mins / 60);
      mins = mins % 60;
      if (seconds < 11) {
        this.areTenSecsRemainings = true
      }
      const scoreMultiplier = this.baseValue && seconds < 0
        ? seconds <= -this.baseValue ? 0 : (this.baseValue + seconds) / this.baseValue
        : null;
      const res = {
        'hours': hrs,
        'minutes': mins,
        'seconds': secs,
        'count': seconds,
        'scoreMultiplier': scoreMultiplier
      };
      this.timerValue = res;
    }, () => this.timerOver.emit('TIMER ERROR'), () => this.timerOver.emit('TIMER OVER'))
  }
}
