<a routerLink="/new-question">
  <button matTooltip="{{translate.instant('question.newQuestionMsg')}}" mat-mini-fab matTooltipPosition="right"
          id="newQuestion" autofocus
          mat-raised-button
          color="primary">
    <i class="fas fa-plus spinMatFab"></i>
  </button>
  <br/>
</a><br/>

<div class="ngx-datatable-and-mat-paginator">
  <ngx-datatable class="homeDataTable striped ngx-datatable-with-mat-paginator"
                 [ngClass]="{'dark': appTheme.globalTheme === appTheme.dark,
                 'material': appTheme.globalTheme === appTheme.light || appTheme.globalTheme === appTheme.mix}"
                 [columnMode]="columnMode.force" [headerHeight]="50" [footerHeight]="0"
                 [scrollbarH]="true" [rowHeight]="60" *ngIf="showTable"
                 [loadingIndicator]="loadingCurrentUser || loadingListOfQuestion"
                 [rowClass]="getRowClass" [rows]='rows' (sort)="onSort($event)">

    <ngx-datatable-column [minWidth]="50" 
    [maxWidth]="60" 
    [sortable]="true" 
    [draggable]="true"
    [canAutoResize]="true" name="id">
      <ng-template ngx-datatable-header-template>Ref</ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>{{row.id}}</ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [sortable]="false">
      <ng-template ngx-datatable-header-template>
        <mat-form-field class="datatable-filter-field">
          <mat-label>
            {{translate.instant('question.fields.title')}}
          </mat-label>
          <input matInput type="text" [(ngModel)]="form.titleFilter" (ngModelChange)="applyFilters()" autocomplete="off" class="filter">
          <button mat-button *ngIf="form.titleFilter" matSuffix mat-icon-button aria-label="Clear"
                  (click)="form.titleFilter='';applyFilters();">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <span class="material-icons datatable-filter-field-icon">filter_alt</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>{{row.titre}}</ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [sortable]="false">
      <ng-template ngx-datatable-header-template>
        <span class="cursor-pointer">{{translate.instant('question.fields.content')}}</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span [theme]="'light'" [max-width]="800" [tooltip]="row.enonce" placement="bottom"
              [innerHTML]="utils.getTextFirstLine(row.enonce)"></span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [maxWidth]="60" [sortable]="true" name="duration">
      <ng-template ngx-datatable-header-template>
        <mat-icon matTooltip="{{translate.instant('question.fields.duration')}}" matSuffix>timer</mat-icon>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>{{row.duration}}</ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [maxWidth]="60" [sortable]="true" name="difficulty">
      <ng-template ngx-datatable-header-template>
        <mat-icon matTooltip="{{translate.instant('question.fields.difficulty')}}" matSuffix>school</mat-icon>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>{{row.difficulty}}</ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [minWidth]="230" [sortable]="false" headerClass="ngx-datatable-column-with-mat-select">
      <ng-template ngx-datatable-header-template>
        <mat-form-field class="mat-select-for-ngx-datatable">
          <mat-label>{{translate.instant('question.fields.type')}}</mat-label>
          <mat-select [(ngModel)]="form.typeFilter" name="type"
            (ngModelChange)="applyFilters();" class= "filter1">
            <mat-option *ngFor="let type of questionTypes" [value]="type.value">
              {{type.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button *ngIf="form.typeFilter" class="mat-select-clear-button-for-ngx-datatable"
                matSuffix mat-icon-button aria-label="Clear" (click)="form.typeFilter='';  applyFilters();">
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>

      <ng-template let-row="row" ngx-datatable-cell-template>{{row.type}}</ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [maxWidth]="30" [sortable]="false">
      <ng-template ngx-datatable-header-template>
        <mat-icon matTooltip="{{translate.instant('question.fields.validate')}}" matSuffix>done_all</mat-icon>
      </ng-template>
      <ng-template let-row="row"
                   ngx-datatable-cell-template>
        {{row.validated ? "V" : row.currentRevision ? "X" : translate.instant('question.fields.never')}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [minWidth]="230"
    [sortable]="false"
    [canAutoResize]="true" headerClass="ngx-datatable-column-with-mat-select">
      <ng-template ngx-datatable-header-template>
        <mat-form-field class="mat-select-for-ngx-datatable">
          <mat-label>{{translate.instant('question.fields.category')}}</mat-label>
          <mat-select [(ngModel)]="form.categoryFilter" name="type" 
          (ngModelChange)="applyFilters();" class= "filter2">
            <mat-option *ngFor="let category of categoriesList" [value]="category.id">
              {{category.nom}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button *ngIf="form.categoryFilter" class="mat-select-clear-button-for-ngx-datatable"
                matSuffix mat-icon-button aria-label="Clear" (click)="form.categoryFilter=''; applyFilters();">
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
      
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="d-flex flex-row">
          <h5 *ngFor="let categorie of row.questionCategories" class="ml-1">
          <span class="badge badge-pill badge-info">
            {{categorie.nom}}
          </span>
          </h5>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [maxWidth]="70" [resizeable]="true"
    [sortable]="true"
    [draggable]="true"
    [canAutoResize]="true" prop="passes" name="passes">
      <ng-template ngx-datatable-header-template>
        <mat-icon matTooltip="{{translate.instant('question.numPasses')}}" matSuffix>format_list_numbered_rtl</mat-icon>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>{{row.passes}}</ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [maxWidth]="80" [resizeable]="true"
    [sortable]="true"
    [draggable]="true"
    [canAutoResize]="true" name="percentage_correct" prop="percentage_correct">
      <ng-template ngx-datatable-header-template>
        <mat-icon matTooltip="{{translate.instant('question.percentCorrect')}}" matSuffix>percent</mat-icon>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>{{(row.percentage_correct*100).toFixed(2)}}%</ng-template>
    </ngx-datatable-column>


    <!-- Cell actions -->
    <ngx-datatable-column cellClass='datatable-body-cell-action d-flex justify-content-end'
                          [sortable]="false" [minWidth]="200" [maxWidth]="200">
      <ng-template let-question="row" ngx-datatable-cell-template>

        <!-- <button mat-icon-button class="mat-primary" (click)=" openQuestionPreview(question)">
          <mat-icon matTooltip="Preview de la question">
            remove_red_eye
          </mat-icon>
        </button> -->

        <button mat-icon-button class="mat-primary" (click)="validateQuestion(question)" [disabled]="!isAdminOrRh">
          <mat-icon [matTooltip]="getValidateQuestionTooltip(question)"
                    [matTooltipClass]="!isAdminOrRh? 'mat-tooltip-font-15-px mat-tooltip-danger': ''"
                    matTooltipPosition="above">
            {{question.validated ? 'close' : 'done_outline'}}
          </mat-icon>
        </button>
        
        <button mat-icon-button class="mat-primary" (click)="editQuestion(question)"
                [disabled]="userCannotUpdateQuestion(question)">
          <mat-icon [matTooltip]="getEditQuestionTooltip(question)"
                    [matTooltipClass]="userCannotUpdateQuestion(question)?
                    'mat-tooltip-font-15-px mat-tooltip-warning': ''"
                    matTooltipPosition="above">edit
          </mat-icon>
        </button>

        <button mat-icon-button class="mat-primary" (click)="deleteQuestion(question)" [disabled]="!isAdminOrRh">
          <mat-icon [matTooltip]="getDeleteQuestionTooltip()"
                    [matTooltipClass]="!isAdminOrRh? 'mat-tooltip-font-15-px mat-tooltip-danger': ''"
                    matTooltipPosition="above">delete
          </mat-icon>
        </button>

        <button mat-icon-button 
        class="mat-primary"
        (click)="openQuestionStats(question)"
            [disabled]="!isAdminOrRh">
        <mat-icon
        matTooltip="{{ translate.instant('question.statistics') }}"
        matTooltipPosition="above">bar_chart
        </mat-icon>
      </button>

      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

  <mat-paginator class="mat-paginator-for-ngx-datatable" 
  [length]="totalElements" 
  [pageSize]="form.pageSize"
  [pageSizeOptions]="[5, 10, 25, 100]"
  [pageIndex] = 1
  (page)="setPage($event)" [showFirstLastButtons]="true">
  </mat-paginator>

</div>
