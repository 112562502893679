import {of as observableOf} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {ConfigService} from './config.service';
import {MembreAptea} from '../models/membreAptea';
import {InvitationRoutes} from '../constants/InvitationRoutes';

@Injectable()
export class UserService {

  private _currentUser: MembreAptea;

  constructor(
    @Inject(ApiService) private apiService: ApiService,
    @Inject(ConfigService) private config: ConfigService
  ) {
  }

  get currentUser(): MembreAptea {
    return this._currentUser;
  }

  set currentUser(value: MembreAptea) {
    this._currentUser = value;
  }

  initUser() {
    if (!window.location.href.includes(InvitationRoutes.candidat.home) &&
      !window.location.href.includes(InvitationRoutes.candidat.repondre) &&
      !window.location.href.includes(InvitationRoutes.candidat.fin)) {
      if (!this.currentUser) {
        return this.apiService.get(this.config.current_member_url).toPromise()
          .then(user => {
            this._currentUser = user;
          })
          .catch(() => null);
      }
    }

    return observableOf(this.currentUser);
  }
}
