import {mergeMap, delay} from 'rxjs/operators';
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../service';
import {Router} from '@angular/router';
import {DisplayMessage} from '../shared/models/display-message';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;

  isRedacteur = false;
  isRh = false;
  isAdmin = false;
  /**
   * Boolean used in telling the UI
   * that the form has been submitted
   * and is awaiting a response
   */
  submitted = false;

  /**
   * Diagnostic message from received
   * form request error
   */
  notification: DisplayMessage;

  constructor(
    @Inject(AuthService) private authService: AuthService,
    @Inject(Router) private router: Router,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    public translate: TranslateService) {
  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      oldPassword: ['',
        Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(64)])],
      newPassword: ['',
        Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(32)])]
    });

  }

  onSubmit() {
    /**
     * Innocent until proven guilty
     */
    this.notification = undefined;
    this.submitted = true;

    this.authService.changePassowrd(this.form.value).pipe(
      // show me the animation
      delay(1000),
      mergeMap(() => this.authService.logout()))
      .subscribe(() => {
        this.router.navigate(['/login',
          {msgType: 'success', msgBody: this.translate.instant('changePassword.successMsg')}]);
      }, error => {
        this.submitted = false;
        this.notification = {msgType: 'error', msgBody: this.translate.instant('changePassword.errorMsg')};
      });
  }
}
