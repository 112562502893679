import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ReponseReview} from 'app/models/reponseReview';
import {TestCandidatService} from 'app/service/testcandidat.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-reponse-annotation',
  templateUrl: './reponse-annotation.component.html',
  styleUrls: ['./reponse-annotation.component.css']
})
export class ReponseAnnotationComponent implements OnInit {

  @Input() testCandidatId: number;
  @Input() entryId: number;
  @Input() maxScore: number;
  @Input() review: ReponseReview;

  @Output() newReview: EventEmitter<ReponseReview> = new EventEmitter<ReponseReview>();

  form: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
              private testCandidatService: TestCandidatService,
              private toastr: ToastrService,
              public translate: TranslateService) {
  }

  ngOnInit(): void {
    // we don't modify the review input here. we only send a new review object if the user submits the form
    const ctrlScoreReview = this.review && this.review.score != null ? this.review.score : '';
    const ctrlCommentReview = this.review && this.review.comment != null ? this.review.comment : '';
    this.form = this.formBuilder.group({
      score: this.formBuilder.control(ctrlScoreReview, [Validators.max(this.maxScore), Validators.pattern(/^\s*[+-]?\d+(\.\d+)?\s*$/)]),
      comment: this.formBuilder.control(ctrlCommentReview, [Validators.maxLength(1000)])
    });
  }

  onSubmit() {
    const formValue = this.form.value;
    const score: number = parseFloat(formValue.score);
    const comment: string = (formValue.comment as string).trim();

    const newReview = new ReponseReview();
    if (isNaN(score)) {
      newReview.score = null;
    } else {
      newReview.score = score;
    }

    if (comment === null || comment === undefined || comment === '') {
      newReview.comment = null;
    } else {
      newReview.comment = comment;
    }

    this.submitted = true;
    this.testCandidatService.putReponseReview(this.testCandidatId, this.entryId, newReview).subscribe(data => {
      this.newReview.emit(newReview);
      this.submitted = false;
    }, err => {
      this.toastr.error(this.translate.instant('testReview.responseAnnotation.updateFailed'));
      this.submitted = false;
    });
  }
}
