<div class="d-flex flex-row" *ngIf="invitationService.isInvitationTestFinished(invitation); else testNotFinished">
  <colored-score [score]="invitation.testcandidat.note" [noteSansPen]="invitation.testcandidat.noteSansPen"   ></colored-score>
  <span matTooltip="{{translate.instant('btnScore.reviewMsg')}}" matTooltipPosition="above"
        class="material-icons cursor-pointer test-review-icon"
        (click)="reviewTest()">
    remove_red_eye
  </span>
</div>

<ng-template #testNotFinished>
  <span class="material-icons cursor-pointer" *ngIf="invitation.testcandidat.simulation; else notSimulation"
        (click)="continueSimulation()" matTooltip="{{translate.instant('btnScore.continueMsg')}}" matTooltipPosition="above">
    play_circle_outline
  </span>
  <ng-template #notSimulation>-</ng-template>
</ng-template>
