
import {map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { MembreAptea } from '../models/membreAptea';
import {ToastrService} from 'ngx-toastr';
import { Candidat } from 'app/models/candidat';
import { CandidatQuestion } from 'app/models/candidatQuestion';

@Injectable()
export class SearchService {

  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(ApiService) private apiService: ApiService,
    @Inject(ConfigService) private config: ConfigService,
    private toastr: ToastrService
  ) { }

  getSearch(): Observable<MembreAptea[]> {
    return this.http.get<MembreAptea[]>(this.config.search_result_url).pipe(map(membres => {
      return membres;
    }))
  }
  bool : boolean;
  getEmails(mail): boolean {
    this.apiService.get(this.config.searchEmails + mail)
    .subscribe(res=>{
      return this.bool = res;
    });
    return this.bool;
  }
}
