import { Categorie } from './categorie';

export class Questionnaire {
  id: number;
  version: number;
  nom: string;
  difficulty: number;
  duration: number;
  maxScore: number;
  questionnaireCategories: Categorie[];
}
