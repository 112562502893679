<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  Question Preview: {{question.titre}}
  <button mat-button
                class="close margin-left-20px"
                matSuffix mat-icon-button aria-label="close dialog" (click)="close();">
          <mat-icon aria-hidden="true">X</mat-icon>
  </button>
</h2>

<div NgFor="let question of questions; let i = index" mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane">

  <div class="row">
    <mat-card class="col-sm-12 col-md-6">
      <mat-card-content>
        
        <div>
          <span [innerHTML]="question.enonce"></span>
        </div>
        <br/>

        <app-preview-question-choix-multiple ngIf="question.type=='choix multiple'"
          [question]="question" [formGroup]="question"></app-preview-question-choix-multiple>

        <app-preview-question-ouverte ngIf="question.type=='libre'"
          [question]="question"></app-preview-question-ouverte>

        <app-preview-question-code ngIf="question.type=='code'"
          [question]="question"></app-preview-question-code>

      </mat-card-content>
    </mat-card>
  </div>
  <!-- [formGroup]="questionsFormArray.at(i)" -->
</div>
