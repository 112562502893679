import {RouterModule} from '@angular/router';
import {MembreService} from './service';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule, APP_INITIALIZER} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatStepperModule} from '@angular/material/stepper';
// import { HttpModule } from '@angular/http';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AceEditorModule} from 'ng2-ace-editor';
import {MatChipsModule} from '@angular/material/chips';

// material
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMenuModule} from '@angular/material/menu';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AppComponent} from './app.component';
import {routes} from './app-routing.module';
import {HomeComponent} from './home';
import {LoginComponent} from './login';
import {AuthGuard} from './guard';
import {TokenGuard} from './guard';
import {NotFoundComponent} from './pages/not-found';
import {AccountMenuComponent} from './component/header/account-menu/account-menu.component';
import {
  HeaderComponent,
  ApiCardComponent,
  FooterComponent,
  GithubComponent
} from './component';

import {
  ApiService,
  AuthService,
  UserService,
  SearchService,
  ConfigService
} from './service';
import {ChangePasswordComponent} from './change-password';
import {ForbiddenComponent} from './pages/forbidden';
import {AdminComponent} from './admin';
import {SearchResultComponent} from './search-result';
import {NewQuestionComponent} from './questions/new-question';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {QuestionOuverteDialogComponent} from './questions/question-dialogs/question-ouverte-dialog/question-ouverte-dialog.component';
import {QuestionChoixMultipleDialogComponent} from './questions/question-dialogs/question-choix-multiple-dialog/question-choix-multiple-dialog.component';
import {QuestionCodeDialogComponent} from './questions/question-dialogs/question-code-dialog/question-code-dialog.component';
import {QuestionTexteDialogComponent} from './questions/question-dialogs/question-texte-dialog/question-texte-dialog.component';
import {BoutonToolComponent} from './component/bouton-tool/bouton-tool.component';
import {QuestionService} from './service/question.service';
import {SweetHomeComponent} from './home/sweet-home/sweet-home.component';
import {LoginInvitationComponent} from './login/login-invitation/login-invitation.component';
import {HomeInvitationComponent} from './home/home-invitation/home-invitation.component';
import {ExecuteCodeDialogComponent} from './component/execute-code-dialog/execute-code-dialog.component';
import {GateawayTimeoutComponent} from './pages/gateaway-timeout/gateaway-timeout.component';
import {UnauthorizedConnectionComponent} from './pages/unauthorized-connection/unauthorized-connection.component';
import {AdminDashboardComponent} from './admin/admin-dashboard/admin-dashboard.component';
import {NewQuestionnaireComponent} from './questionnaire/new-questionnaire';
import {QuestionnaireService} from './service/questionnaire.service';
import {QuestionnaireComponent} from './questionnaire';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {CategorieService} from './service/categorie.service';
import {QuestionsComponent} from './questions/questions.component';
import {InvitationsComponent} from './invitations/invitations.component';
import {NouveauInvitationComponent} from './invitations/nouveau-invitation/nouveau-invitation.component';
import {InvitationService} from './service/invitation.service';
import {BuildService} from './service/build.service';
import {RepondreTestComponent} from './repondre-test/repondre-test.component';
import {FinTestComponent} from './repondre-test/fin-test/fin-test.component';
import {RichTextEditorAllModule} from '@syncfusion/ej2-angular-richtexteditor';
import {FakeComponent} from './component/fake/fake.component';
import {RepondreQuestionChoixMultipleComponent} from './repondre-test/repondre-question-choix-multiple/repondre-question-choix-multiple.component';
import {RepondreQuestionCodeComponent} from './repondre-test/repondre-question-code/repondre-question-code.component';
import {RepondreQuestionOuverteComponent} from './repondre-test/repondre-question-ouverte/repondre-question-ouverte.component';
import {QueriesInterceptor} from './shared/utilities/QueriesInterceptor';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ToastrModule} from 'ngx-toastr';
import {DataLoadingSpinnerComponent} from './component/loading-spinner/data-loading-spinner/data-loading-spinner.component';
import {ConfirmActionDialogComponent} from './component/confirm-action-dialog/confirm-action-dialog.component';
import {ReviewTestComponent} from './review-test/review-test.component';
import {ReviewQuestionChoixMultipleComponent} from './review-test/review-question-choix-multiple/review-question-choix-multiple.component';
import {ReviewQuestionOuverteComponent} from './review-test/review-question-ouverte/review-question-ouverte.component';
import {ReviewQuestionCodeComponent} from './review-test/review-question-code/review-question-code.component';
import {ReponseAnnotationComponent} from './review-test/reponse-annotation/reponse-annotation.component';
import {TestCandidatService} from './service/testcandidat.service';
import {ColoredScoreComponent} from './shared/colored-score/colored-score.component';
import {BlockCopyPasteDirective} from './shared/block-copy-paste/block-copy-paste.directive';
import {NotyfToast} from './shared/notify-toast/notify-toast.component';
import {ChronoComponent} from './shared/chrono/chrono.component';
import {FlipComponent} from './shared/flip/flip.component';
import {IconComponent} from './shared/icon/icon.component';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {OnlyPositiveNumberDirective} from './shared/only-positive-number/only-positive-number.directive';
import {QuestionnaireStatsDialogComponent} from './component/questionnaire-stats-dialog/questionnaire-stats-dialog.component';
import {QuestionStatsDialogComponent} from './component/question-stats-dialog/question-stats-dialog.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {TooltipModule} from 'ng2-tooltip-directive';
import {UrlInvitationComponentComponent} from './ngx-datatable-shared-components/url-invitation-component/url-invitation-component.component';
import {ButtonPdfComponentComponent} from './ngx-datatable-shared-components/button-pdf-component/button-pdf-component.component';
import {ButtonScoreComponentComponent} from './ngx-datatable-shared-components/button-score-component/button-score-component.component';
import {AgGridModule} from 'ag-grid-angular';
import {MonacoEditorModule} from '@materia-ui/ngx-monaco-editor';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppLanguage} from './shared/utilities/AppLanguage';
import {CustomMatPaginatorIntl} from './shared/utilities/CustomMatPaginatorIntl';
import {QuestionPreviewDialogComponent} from './component/question-preview-dialog/question-preview-dialog.component';
import {PreviewQuestionChoixMultipleComponent} from './component/question-preview-dialog/preview-question-choix-multiple/preview-question-choix-multiple.component';
import {PreviewQuestionCodeComponent} from './component/question-preview-dialog/preview-question-code/preview-question-code.component';
import {PreviewQuestionOuverteComponent} from './component/question-preview-dialog/preview-question-ouverte/preview-question-ouverte.component';
import {MajorMinorUpdateDialogComponent} from './component/major-minor-update-action-dialog/major-minor-update-action-dialog.component';

export function initUserFactory(userService: UserService) {
  return () => userService.initUser();
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ApiCardComponent,
    HomeComponent,
    GithubComponent,
    LoginComponent,
    NotFoundComponent,
    AccountMenuComponent,
    ChangePasswordComponent,
    ForbiddenComponent,
    AdminComponent,
    QuestionnaireComponent,
    SearchResultComponent,
    NewQuestionComponent,
    NewQuestionnaireComponent,
    QuestionOuverteDialogComponent,
    QuestionChoixMultipleDialogComponent,
    QuestionCodeDialogComponent,
    QuestionTexteDialogComponent,
    BoutonToolComponent,
    SweetHomeComponent,
    LoginInvitationComponent,
    HomeInvitationComponent,
    ExecuteCodeDialogComponent,
    GateawayTimeoutComponent,
    UnauthorizedConnectionComponent,
    AdminDashboardComponent,
    QuestionsComponent,
    InvitationsComponent,
    NouveauInvitationComponent,
    RepondreTestComponent,
    FinTestComponent,
    RepondreQuestionChoixMultipleComponent,
    RepondreQuestionCodeComponent,
    RepondreQuestionOuverteComponent,
    FakeComponent,
    DataLoadingSpinnerComponent,
    ConfirmActionDialogComponent,
    ReviewTestComponent,
    ReviewQuestionChoixMultipleComponent,
    ReviewQuestionOuverteComponent,
    ReviewQuestionCodeComponent,
    ReponseAnnotationComponent,
    ColoredScoreComponent,
    BlockCopyPasteDirective,
    NotyfToast,
    ChronoComponent,
    FlipComponent,
    IconComponent,
    OnlyPositiveNumberDirective,
    QuestionnaireStatsDialogComponent,
    QuestionStatsDialogComponent,
    UrlInvitationComponentComponent,
    ButtonPdfComponentComponent,
    ButtonScoreComponentComponent,
    QuestionPreviewDialogComponent,
    PreviewQuestionChoixMultipleComponent,
    PreviewQuestionCodeComponent,
    PreviewQuestionOuverteComponent,
    MajorMinorUpdateDialogComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    // HttpModule,
    HttpClientModule,
    RouterModule.forRoot(routes, {enableTracing: false, useHash: true}),
    MatMenuModule,
    MatTooltipModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatToolbarModule,
    MatCardModule,
    MatSelectModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatGridListModule,
    MatCheckboxModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxDatatableModule,
    MatStepperModule,
    AceEditorModule,
    MatDialogModule,
    RichTextEditorAllModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatRippleModule,
    DragDropModule,
    ToastrModule.forRoot(),
    MatPaginatorModule,
    NgxChartsModule,
    TooltipModule,
    AgGridModule,
    MonacoEditorModule,
    MatSlideToggleModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AuthGuard,
    TokenGuard,
    SearchService,
    AuthService,
    ApiService,
    UserService,
    MembreService,
    LoginComponent,
    ConfigService,
    MatIconRegistry,
    QuestionService,
    {
      'provide': APP_INITIALIZER,
      'useFactory': initUserFactory,
      'deps': [UserService],
      'multi': true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: QueriesInterceptor,
      multi: true
    },
    QuestionnaireService,
    CategorieService,
    InvitationService,
    BuildService,
    TestCandidatService,
    AppLanguage,
    {provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl}
  ],
  bootstrap: [AppComponent],
  entryComponents: [ExecuteCodeDialogComponent]
})
export class AppModule {
}
