import {Component, OnInit, ViewChild, Input, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {AceEditorComponent} from 'ng2-ace-editor';
import {CandidatQuestion} from '../../models/candidatQuestion';
import {CandidatReponse} from '../../models/candidatReponse';
import {FormGroup, FormBuilder} from '@angular/forms';
import {JdoodleDataOutput} from '../../models/jdoodle.data.output';
import {QuestionService} from '../../service/question.service';
import {InvitationService} from '../../service/invitation.service';
import {delay, finalize} from 'rxjs/operators';
import {removeDiacritics} from 'app/shared/ace-editor-stuff/remove-diacritrics';
import {Utils} from '../../shared/utilities/Utils';
import {AppTheme} from '../../shared/utilities/AppTheme';
import {MonacoStandaloneCodeEditor} from '@materia-ui/ngx-monaco-editor';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-repondre-question-code',
  templateUrl: './repondre-question-code.component.html',
  styleUrls: ['./repondre-question-code.component.css']
})
export class RepondreQuestionCodeComponent implements OnInit, AfterViewInit {

  @ViewChild('aceEditor') aceEditor: AceEditorComponent;

  @Input() question: CandidatQuestion;
  @Input() reponse?: CandidatReponse;
  @Input() formGroup?: FormGroup;
  @Input() token: string;

  utils = Utils;
  appTheme = AppTheme;

  useMonacoEditor = true;
  initAceEditor = false;

  isReady = false;
  jdataOutput: JdoodleDataOutput;
  codeExecuting: boolean;

  constructor(private formBuilder: FormBuilder,
              private questionService: QuestionService,
              private invitationService: InvitationService,
              private changeDetector: ChangeDetectorRef,
              public translate: TranslateService) {
  }

  ngOnInit() {
    if (!this.formGroup) {
      this.formGroup = this.formBuilder.group({});
    }

    this.formGroup = this.formBuilder.group({
      sourceCodeMonaco: ['']
    });

    if (!this.reponse) {
      this.reponse = new CandidatReponse();
      this.reponse.questionId = this.question.id;
    }
    if (!this.reponse.sourceCode) {
      this.reponse.sourceCode = this.question.codePrerempli;
    }
    this.isReady = true;
  }

  ngAfterViewInit(): void {
    this.checkEditors();
  }

  editorInit(editor: MonacoStandaloneCodeEditor) {
    // tslint:disable-next-line:no-bitwise
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_C, () => null);
    // tslint:disable-next-line:no-bitwise
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_V, () => null);
    // tslint:disable-next-line:no-bitwise
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_X, () => null);
    // tslint:disable-next-line:no-bitwise
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KEY_V, () => null);
    // tslint:disable-next-line:no-bitwise
    editor.addCommand(monaco.KeyMod.Shift | monaco.KeyCode.Delete, () => null);
  }

  onTextChanged(value) {
    this.reponse.sourceCode = value;
  }

  onExecute() {
    this.codeExecuting = true;
    const formattedCode = removeDiacritics(this.reponse.sourceCode);
    this.invitationService.testExecuteCode(formattedCode, this.question.technologie, this.token).pipe(
      delay(1000),
      finalize(() => {
        this.codeExecuting = false;
      })
    ).subscribe(jdataOutput => {
      this.jdataOutput = jdataOutput;
    }, error => {
      this.jdataOutput = new JdoodleDataOutput();
      this.jdataOutput.output = this.translate.instant('question.questionDialogs.code.codeError');
    });
  }

  switchEditor() {
    this.useMonacoEditor = !this.useMonacoEditor;
    this.changeDetector.detectChanges();

    this.checkEditors();
  }

  checkEditors() {
    if (this.useMonacoEditor) {
      this.disableContextMenu('monaco-editor');
    } else {
      this.disableContextMenu('ace-editor');

      const aceEditor = this.aceEditor.getEditor();
      this.disableAceEditorCopyPasteCut(aceEditor);
      this.disableAceEditorDragAndDrop(aceEditor);
    }
  }

  disableContextMenu(htmlElementId: string) {
    // Disable mouse right click menu
    document.getElementById(htmlElementId).addEventListener('contextmenu', function (e) {
      e.preventDefault();
      return false;
    }, false);
  }

  disableAceEditorCopyPasteCut(aceEditor) {
    aceEditor.commands.addCommand({
      name: 'breakTheEditor',
      bindKey: 'ctrl-c|ctrl-v|ctrl-x|ctrl-shift-v|shift-del|cmd-c|cmd-v|cmd-x',
      exec: function () {
      }
    });
  }

  disableAceEditorDragAndDrop(aceEditor) {
    ['dragenter', 'dragover', 'dragend', 'dragstart', 'dragleave', 'drop'].forEach(function (eventName) {
      aceEditor.container.addEventListener(eventName, function (e) {
        e.stopPropagation()
      }, true)
    });
    aceEditor.setOption('dragEnabled', false)
  }
}
