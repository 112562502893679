<div
  class="cursor-pointer"
  (click)="goToPDFBuilder()"
  *ngIf="
    invitationService.isInvitationTestFinished(invitation);
    else invitationNotFinished
  "
>
  <i class="far fa-2x fa-file-pdf"></i>
</div>
<ng-template #invitationNotFinished>-</ng-template>
