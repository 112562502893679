
<mat-tab-group dynamicHeight class="tab-reverse">

  <mat-tab></mat-tab>

  <!-- Question Choix multiple -->
  <mat-tab>
    <ng-template mat-tab-label>
      {{translate.instant('question.questionTypes.multipleChoices')}}
    </ng-template>
    <app-question-choix-multiple-dialog></app-question-choix-multiple-dialog>
  </mat-tab>

  <!-- Question Ouverte -->
  <mat-tab>
    <ng-template mat-tab-label>
      {{translate.instant('question.questionTypes.open')}}
    </ng-template>
    <app-question-ouverte-dialog></app-question-ouverte-dialog>
  </mat-tab>

  <!-- Question Code -->
  <mat-tab>
    <ng-template mat-tab-label>
      {{translate.instant('question.questionTypes.code')}}
    </ng-template>
    <app-question-code-dialog></app-question-code-dialog>
  </mat-tab>

  <!-- Question Texte -->
  <mat-tab>
    <ng-template mat-tab-label>
      {{translate.instant('question.questionTypes.text')}}
    </ng-template>
    <app-question-texte-dialog></app-question-texte-dialog>
  </mat-tab>
</mat-tab-group>

