

<div class="row d-flex flex-row px-5">
  
  <button
  matTooltip="{{ translate.instant('questionnaire.addQuestionnaire') }}"
  id="newQuestionnaire"
  color="primary"
  matTooltipPosition="right"
  mat-icon-button
  mat-raised-button
  (click)="(flip = !flip) && newQuestionnaire()"
>
  <wm-flip [flipped]="flip">
    <wm-icon front icon="fas:fa-plus"></wm-icon>
    <wm-icon back icon="fas:fa-check"></wm-icon>
  </wm-flip>
</button>
<div class="divider"> </div>
    <button  type="button" mat-raised-button color="primary" class="btn btn-primary-outline pull-right btntmp" 
            matTooltip="Importer un fichier de questionnaires exportés">
      <label class= "label">
        <span class="mdi mdi-file-export"> </span>
        Import
        <input hidden type="file" (change)="onFileChange($event)" multiple="false" accept=".xml">
      </label>
    </button>
    <div class="divider"> </div>
    <a [href]="questionnairesXmlFileUrl" download="questionnaires.xml">
      <button  type="button" mat-raised-button color="accent" class="btn btn-primary-outline pull-right btntmp"
              matTooltip="Exporter tous les questionnaires dans un fichier"
              [disabled]="!questionnairesXmlCanBeDownloaded ? true : null">
        <span  class="mdi mdi-file-export"> </span>
        Export
      </button>
    </a>
  
</div>
<br />
<br />

<div class="ngx-datatable-and-mat-paginator">
  <ngx-datatable
    class="homeDataTable striped ngx-datatable-with-mat-paginator"
    [ngClass]="{
      dark: appTheme.globalTheme === appTheme.dark,
      material:
        appTheme.globalTheme === appTheme.light ||
        appTheme.globalTheme === appTheme.mix
    }"
    [columnMode]="columnMode.force"
    [headerHeight]="50"
    [footerHeight]="0"
    [scrollbarH]="true"
    [rowHeight]="60"
    [limit]="10"
    [rows]="rows"
    [loadingIndicator]="loadingCurrentUser || loadingListOfQuestionnaire"
    [rowClass]="getRowClass"
    (sort)="onSort($event)"
  >
    <ngx-datatable-column
      [minWidth]="40"
      [maxWidth]="60"
      [sortable]="true"
      name="id"
    >
      <ng-template ngx-datatable-header-template>Ref</ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>{{
        row.id
      }}</ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [minWidth]="100" [sortable]="false">
      <ng-template ngx-datatable-header-template>
        <mat-form-field class="datatable-filter-field">
          <mat-label>{{ translate.instant("fields.name") }}</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="nameFilter"
            (keyup)="filterByName()"
          />
          <button
            mat-button
            *ngIf="nameFilter"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="nameFilter = ''; filterByName()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <span class="material-icons datatable-filter-field-icon"
          >filter_alt</span
        >
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>{{
        row.nom
      }}</ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="100"
      [maxWidth]="100"
      [sortable]="false"
      name="nbreQuestions"
    >
      <ng-template ngx-datatable-header-template>
        <mat-icon
          matTooltip="{{ translate.instant('questionnaire.nbreQuestions') }}"
          matSuffix
          >format_list_numbered_rtl</mat-icon
        >
      </ng-template>

      <ng-template let-row="row" ngx-datatable-cell-template>
        <span *ngIf="row.maxScore%row.difficulty != 0 && row.maxScore%row.difficulty<1 ">{{
          (row.maxScore - (row.maxScore%row.difficulty))/row.difficulty
        }}</span>
        <span *ngIf="row.maxScore%row.difficulty != 0 && row.maxScore%row.difficulty>1 || row.maxScore%row.difficulty==1 ">{{
          (row.maxScore - (row.maxScore%row.difficulty))/row.difficulty +1
        }}</span>
        <span *ngIf="row.maxScore%row.difficulty == 0">{{
          (row.maxScore / row.difficulty)
        }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="100"
      [maxWidth]="100"
      [sortable]="true"
      name="duration"
    >
      <ng-template ngx-datatable-header-template>
        <mat-icon
          matTooltip="{{ translate.instant('fields.duration') }}"
          matSuffix
          >timer</mat-icon
        >
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span *ngIf="row.duration != null">{{
          row.duration * 1000 | date: "H:mm''ss\"":"+0000"
        }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="100"
      [maxWidth]="100"
      [sortable]="true"
      name="difficulty"
    >
      <ng-template ngx-datatable-header-template>
        <mat-icon
          matTooltip="{{ translate.instant('questionnaire.difficultyAvg') }}"
          matSuffix
          >stars</mat-icon
        >
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>{{
        row.difficulty
      }}</ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="100"
      [maxWidth]="100"
      [sortable]="true"
      name="maxScore"
    >
      <ng-template ngx-datatable-header-template>
        <mat-icon
          matTooltip="{{ translate.instant('questionnaire.difficultySum') }}"
          matSuffix
          >school</mat-icon
        >
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>{{
        row.maxScore
      }}</ng-template>
    </ngx-datatable-column>

    <!-- Cell actions -->
    <ngx-datatable-column [minWidth]="60" [maxWidth]="60" [sortable]="false">
      <ng-template let-questionnaire="row" ngx-datatable-cell-template>
        <button
          mat-icon-button
          class="mat-primary"
          (click)="openQuestionnaireStats(questionnaire)"
        >
          <mat-icon
            matTooltip="{{ translate.instant('questionnaire.statistics') }}"
            matTooltipPosition="above"
            >bar_chart
          </mat-icon>
        </button>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="60"
      [maxWidth]="60"
      cellClass="datatable-body-cell-action"
      [sortable]="false"
    >
      <ng-template let-questionnaire="row" ngx-datatable-cell-template>
        <button
          mat-icon-button
          class="mat-primary"
          (click)="editQuestionnaire(questionnaire)"
        >
          <mat-icon
            matTooltip="{{ translate.instant('actions.edit') }}"
            matTooltipPosition="above"
            >edit</mat-icon
          >
        </button>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="60"
      [maxWidth]="60"
      cellClass="datatable-body-cell-action"
      [sortable]="false"
    >
      <ng-template let-questionnaire="row" ngx-datatable-cell-template>
        <button
          mat-icon-button
          class="mat-primary"
          (click)="simulateQuestionnaire(questionnaire)"
        >
          <mat-icon
            matTooltip="{{
              translate.instant('questionnaire.createSimulation')
            }}"
            matTooltipPosition="above"
            >play_circle_outline
          </mat-icon>
        </button>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="60"
      [maxWidth]="60"
      cellClass="datatable-body-cell-action"
      [sortable]="false"
    >
      <ng-template let-questionnaire="row" ngx-datatable-cell-template>
        <button
          mat-icon-button
          class="mat-primary"
          (click)="deleteQuestionnaire(questionnaire)"
        >
          <mat-icon
            matTooltip="{{ translate.instant('actions.delete') }}"
            matTooltipPosition="above"
            >delete</mat-icon
          >
        </button>
      </ng-template>
    </ngx-datatable-column>
    <!-- End Cell actions -->
  </ngx-datatable>

  <mat-paginator
    class="mat-paginator-for-ngx-datatable"
    [length]="totalElements"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="setPage($event)"
    [showFirstLastButtons]="true"
  >
  </mat-paginator>
</div>
